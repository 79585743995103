import { redo, undo } from '../actions/historyActions'
import { modifyLogger } from '../actions/loggerActions'
import {
  clearMarked,
  copySelectedWorklogs,
  deleteSelection,
  moveCursor,
  moveDisplayedDateAndCursor,
  openDialog,
  setCursorAndIssueInput,
  setDisplayedDateAndCursor,
  setFocus,
  setJiraIssueForSelection,
  setJiraIssueFromSpeedDialForSelection,
  switchMode,
  toggleMarkedPoint,
  toggleShortcuts,
  toggleIsFreeTimeForSelection,
  toggleWorklogsDetail,
  unsyncPointAtCursor,
} from '../actions/trackerActions'
import { DialogVariant, Focus, Input, Mode } from '../consts'
import { State } from '../store/state'
import { Dispatch } from '../types/reduxTypes'
import { blurElement } from '../utils'

import { issueInputDisabledSelector } from './issuesSelectors'
import { selectionSelector } from './selectionSelector'

export const getMetaKeyPath = (
  ctrlKey: boolean,
  metaKey: boolean,
  shiftKey: boolean,
  altKey: boolean
) => {
  if ((ctrlKey || metaKey) && shiftKey) return 'withCtrlShiftKey'
  if ((ctrlKey || metaKey) && altKey) return 'withCtrlAltKey'
  if (ctrlKey || metaKey) return 'withCtrlKey'
  if (altKey) return 'withAltKey'
  if (shiftKey) return 'withShiftKey'
  return 'plainKey'
}

interface GetSpeedDialHandlerArgs {
  createHandlerFunction: (dispatch: Dispatch, index: string) => void
  createIsDisabled?: (index: string) => (state: State) => boolean
}

const getSpeedDialHandlers = ({
  createHandlerFunction,
  createIsDisabled,
}: GetSpeedDialHandlerArgs) =>
  [...Array(10).keys()]
    .map((i) => i.toString())
    .reduce<Record<string, KeyBindingItem>>(
      (acc, i) => ({
        ...acc,
        [i]: {
          handler: (dispatch) => createHandlerFunction(dispatch, i),
          isDisabled: createIsDisabled?.(i),
        },
      }),
      {}
    )

interface KeyBindingItem {
  description?: string
  handler?: (dispatch: Dispatch, getState: () => State) => void
  executableFromInput?: boolean
  category?: string
  isDisabled?: (state: State) => boolean
}

type KeyBindings = {
  [mode in Mode]?: {
    [modifier in ReturnType<typeof getMetaKeyPath> | 'internalInputs']?: {
      [key: string]: KeyBindingItem
    }
  }
}

const toggleShortcutsPanel: KeyBindingItem = {
  description: 'Show/Hide shortcuts panel',
  handler: (dispatch) => dispatch(toggleShortcuts()),
  executableFromInput: true,
}

const editDescriptionHandler = (dispatch: Dispatch, getState: () => State) => {
  const { points, cursor } = getState()
  if (!points[cursor]) return

  if (!points[cursor].synced) {
    dispatch(modifyLogger(Input.LOCAL_DESCRIPTION, points[cursor].description))
    dispatch(switchMode(Mode.EDIT_DESCRIPTION))
  }
}

export const SET_SPEED_DIAL = 'Set speed dial option'

const _toggleShortcuts: KeyBindingItem = { ...toggleShortcutsPanel }

const logDescription: KeyBindingItem = {
  description: 'Log new description',
  category: 'Logging',
  handler: (dispatch) => {
    //TODO: change cursor as well
    dispatch(setFocus({ focusType: Focus.DESCRIPTION }))
  },
}

const _moveCursorToTheStartOfDay: KeyBindingItem = {
  description: 'Move cursor to the start of the day',
  category: 'Navigation',
  handler: (dispatch, getState) =>
    dispatch(setDisplayedDateAndCursor(getState().displayedDate)),
}

const _moveCursorToTheEndOfDay: KeyBindingItem = {
  description: 'Move cursor to the end of the day',
  category: 'Navigation',
  handler: (dispatch, getState) =>
    dispatch(setDisplayedDateAndCursor(getState().displayedDate, true)),
}

const addWorklog: KeyBindingItem = {
  description: 'Add new worklog',
  category: 'Logging',
}

const editDescription: KeyBindingItem = { handler: editDescriptionHandler }

const editTime: KeyBindingItem = {
  description: 'Edit time',
  category: 'Editing',
  handler: (dispatch, getState) => {
    const { points, cursor } = getState()
    if (!points[cursor]) return

    // only allow time selection if there is one time selected
    if (selectionSelector(getState()).length === 1 && !points[cursor].synced) {
      dispatch(switchMode(Mode.EDIT_TIME))
    }
  },
}

const setIssue: KeyBindingItem = {
  description: 'Set JIRA issue',
  category: 'Issues',
  handler: (dispatch, getState) =>
    !issueInputDisabledSelector(getState()) &&
    dispatch(setFocus({ focusType: Focus.ISSUE })),
}

const removeIssue: KeyBindingItem = {
  description: 'Remove JIRA issue',
  category: 'Issues',
  handler: (dispatch) => dispatch(setJiraIssueForSelection(null)),
}

const setFreeTime: KeyBindingItem = {
  description: 'Mark as personal time',
  category: 'Issues',
  handler: (dispatch) => {
    dispatch(toggleIsFreeTimeForSelection())
    dispatch(modifyLogger(Input.ISSUE, ''))
  },
}

const setIssueBySpeedDial: KeyBindingItem = {
  description: 'Assign issue from speed dial',
  category: 'Issues',
}

const speedDialHandler = {
  createHandlerFunction: (dispatch, ind) =>
    dispatch(setJiraIssueFromSpeedDialForSelection(ind)),
}

const moveCursorUp: KeyBindingItem = {
  description: 'Move cursor up',
  handler: (dispatch) => dispatch(moveCursor(-1)),
}

const moveCursorDown: KeyBindingItem = {
  description: 'Move cursor down',
  handler: (dispatch) => dispatch(moveCursor(1)),
}

const goToPreviousDay: KeyBindingItem = {
  description: 'Move cursor to the previous day',
  handler: (dispatch) => dispatch(moveDisplayedDateAndCursor(-1)),
}

const goToNextDay: KeyBindingItem = {
  description: 'Move cursor to the next day',
  handler: (dispatch) => dispatch(moveDisplayedDateAndCursor(1)),
}

const markPoint: KeyBindingItem = {
  description: 'Mark point',
  category: 'Editing',
  handler: (dispatch, getState) => {
    const { points, cursor } = getState()
    if (points[cursor] && !points[cursor].synced) {
      dispatch(toggleMarkedPoint(cursor))
    }
  },
}

const deleteWorklogs: KeyBindingItem = {
  description: 'Delete selection',
  handler: (dispatch) => dispatch(deleteSelection()),
}

const moveToToday: KeyBindingItem = {
  description: 'Move to today',
  handler: (dispatch) =>
    dispatch(setDisplayedDateAndCursor(new Date().toISOString())),
}

const moveToStart: KeyBindingItem = {
  description: 'Move to first worklog',
  handler: (dispatch) => dispatch(setCursorAndIssueInput(0)),
}

const insertWorklog: KeyBindingItem = {
  description: 'Insert worklog',
  category: 'Extras',
  handler: (dispatch, getState) => {
    const { points, cursor } = getState()
    return dispatch(
      setFocus({
        focusType: Focus.DESCRIPTION,
        payload: { insertPoint: points[cursor] },
      })
    )
  },
}

const copyWorklogs: KeyBindingItem = {
  description: 'Copy worklog',
  category: 'Extras',
  handler: (dispatch) => dispatch(copySelectedWorklogs()),
}

const clearFocusAndMarks: KeyBindingItem = {
  description: 'Clear focus and marks',
  category: 'Extras',
  handler: (dispatch) => {
    dispatch(modifyLogger(Input.DESCRIPTION, ''))
    blurElement()
    dispatch(clearMarked())
  },
  executableFromInput: true,
}

const addWorklogAndContinue: KeyBindingItem = {
  description: 'Add new worklog and keep logging',
  category: 'Logging',
}

const setSpeedDial: KeyBindingItem = {
  description: SET_SPEED_DIAL,
  category: 'Issues',
}

const createSpeedDial = {
  createHandlerFunction: (dispatch, ind) =>
    dispatch(setFocus({ focusType: Focus.ISSUE, payload: { index: ind } })),
}

const submitWorklogs: KeyBindingItem = {
  description: 'Submit worklogs',
  category: 'Navigation',
  handler: (dispatch) => {
    // TS-fix: fix consts typings
    dispatch(switchMode(Mode.SAVING))
  },
}

const _undo: KeyBindingItem = {
  description: 'Undo',
  handler: (dispatch) => dispatch(undo()),
  executableFromInput: true,
}

const _redo: KeyBindingItem = {
  description: 'Redo',
  handler: (dispatch) => dispatch(redo()),
  executableFromInput: true,
}

const joinIntervals: KeyBindingItem = {
  description: 'Join selected intervals',
  category: 'Extras',
  handler: (dispatch) => {
    // TS-fix: fix consts typings
    dispatch(openDialog(DialogVariant.JOIN_INTERVALS))
    dispatch(setFocus({ focusType: Focus.JOIN_DIALOG }))
  },
}

const getPreviousDescription: KeyBindingItem = {
  description: 'Browse work-logs history in description input (back)',
  category: 'Input',
}

const getNextDescription: KeyBindingItem = {
  description: 'Browse work-logs history in description input (forth)',
  category: 'Input',
}

const add15Min: KeyBindingItem = {
  description: 'Add 15 min. in time input',
  category: 'Input',
}

const sub15Min: KeyBindingItem = {
  description: 'Subtract 15 min. in time input',
  category: 'Input',
}

const unsyncPointAtCursorFromJira: KeyBindingItem = {
  description: 'Unsync point at cursor',
  category: 'Extras',
  handler: (dispatch) => dispatch(unsyncPointAtCursor()),
}

const loggingModeKeyBindings = {
  plainKey: {
    f1: _toggleShortcuts,
    l: logDescription,
    enter: addWorklog,
    d: {
      ...editDescription,
      description: 'Edit description',
      category: 'Editing',
    },
    f2: editDescription,
    t: editTime,
    s: setIssue,
    n: removeIssue,
    p: setFreeTime,
    '[0-9]': setIssueBySpeedDial,
    ...getSpeedDialHandlers(speedDialHandler),
    arrowup: { ...moveCursorUp, category: 'Navigation' },
    k: moveCursorUp,
    arrowdown: { ...moveCursorDown, category: 'Navigation' },
    j: moveCursorDown,
    arrowleft: { ...goToPreviousDay, category: 'Navigation' },
    arrowright: { ...goToNextDay, category: 'Navigation' },
    ' ': markPoint,
    delete: deleteWorklogs,
    backspace: deleteWorklogs,
    end: moveToToday,
    home: moveToStart,
    i: insertWorklog,
    r: copyWorklogs,
    escape: clearFocusAndMarks,
    u: unsyncPointAtCursorFromJira,
  },
  withCtrlKey: {
    enter: addWorklogAndContinue,
    '[0-9]': setSpeedDial,
    ...getSpeedDialHandlers(createSpeedDial),
    arrowright: { ...moveToToday, category: 'Navigation' },
    s: submitWorklogs,
    z: _undo,
    y: _redo,
    j: joinIntervals,
    arrowup: _moveCursorToTheStartOfDay,
    arrowdown: _moveCursorToTheEndOfDay,
  },
  withShiftKey: {
    '?': { ..._toggleShortcuts, executableFromInput: false },
    j: goToPreviousDay,
    k: goToNextDay,
  },
  internalInputs: {
    upDescription: getPreviousDescription,
    downDescription: getNextDescription,
    upTime: add15Min,
    downTime: sub15Min,
  },
}

const exitSavingMode: KeyBindingItem = {
  description: 'Exit saving mode',
  category: 'Navigation',
  handler: (dispatch) => dispatch(switchMode(Mode.LOGGING)),
  executableFromInput: true,
}

const showDetails: KeyBindingItem = {
  description: 'Expand/collapse all worklogs details',
  category: 'Extras',
  handler: (dispatch) => dispatch(toggleWorklogsDetail()),
  executableFromInput: true,
}

const savingModeKeyBindings = {
  plainKey: {
    escape: exitSavingMode,
    f1: _toggleShortcuts,
  },
  withCtrlKey: {
    e: showDetails,
  },
}

// TODO: use isDisabled to disable these controls when user shouldn't be able to press them
export const keyBindings: KeyBindings = {
  [Mode.LOGGING]: loggingModeKeyBindings,
  [Mode.SAVING]: savingModeKeyBindings,
}
