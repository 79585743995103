import {
  setHours,
  setMinutes,
  subMinutes,
  subHours,
  addMinutes,
  addHours,
  isBefore,
  parseISO,
} from 'date-fns'

import { PointInput, State } from '../store/state'
import { roundPointTime } from '../utils'

import { allIssuesObjSelector } from './issuesSelectors'

interface SingleLogWildcard<T extends { raw: string }> {
  name: string
  regexp: RegExp
  parseCaptureGroups: (groups: string[]) => T
  getPointsToAdd: (
    getState: () => State,
    pointInput: PointInput,
    parsedArguments: T
  ) => { pointInput: PointInput; warning?: string }
}

type StartTimeWildcard = SingleLogWildcard<{
  raw: string
  parsedHours: number
  parsedMinutes: number
}>

type IssueWildcard = SingleLogWildcard<{
  raw: string
  potentialIssueKey: string
}>

interface MultiLogWildcard<T extends { raw: string }> {
  name: string
  regexp: RegExp
  parseCaptureGroups: (groups: string[]) => T
  getPointsToAdd: (
    getState: () => State,
    pointInput: PointInput,
    parsedArguments: T
  ) => PointInput[]
}

type DurationWildcard = MultiLogWildcard<{
  raw: string
  direction: string
  parsedHours: number
  parsedMinutes: number
}>

const SINGLE_LOG_WILDCARDS = [
  {
    name: 'START_TIME',
    regexp: /\b@?([0-9]|0[0-9]|1[0-9]|2[0-3])[:.]?([0-5][0-9])\b/,
    parseCaptureGroups: ([raw, hours, minutes]: string[]) => ({
      raw,
      parsedHours: Number(hours),
      parsedMinutes: Number(minutes),
    }),
    getPointsToAdd: (
      _getState,
      pointInput,
      { raw, parsedHours, parsedMinutes }
    ) => {
      const newTimestamp = setMinutes(
        setHours(parseISO(pointInput.timestamp), parsedHours),
        parsedMinutes
      ).toISOString()

      return roundPointTime(newTimestamp) === newTimestamp
        ? {
            pointInput: {
              ...pointInput,
              timestamp: newTimestamp,
              description: pointInput.description.replace(raw, ''),
            },
          }
        : {
            pointInput,
            warning: 'Only rounded timestamp can be used as a wildcard',
          }
    },
  } as StartTimeWildcard,
  {
    name: 'ISSUE',
    regexp: /\b([A-Za-z][A-Z_0-9a-z]+-[0-9]+)\b/,
    parseCaptureGroups: ([raw, potentialIssueKey]) => ({
      raw,
      potentialIssueKey: potentialIssueKey.toUpperCase(),
    }),
    getPointsToAdd: (getState, pointInput, { raw, potentialIssueKey }) => {
      const issuesObj = allIssuesObjSelector(getState())
      const issueKey = issuesObj[potentialIssueKey]?.key

      return issueKey
        ? {
            pointInput: {
              ...pointInput,
              description: pointInput.description.replace(raw, ''),
              issue: issueKey,
            },
          }
        : { pointInput, warning: `Issue ${potentialIssueKey} was not found` }
    },
  } as IssueWildcard,
]

// Currently as the only wildcard, this receives 1 PointInput and returns 2 new ones (enhanced + end of the log).
const DURATION_WILDCARD: DurationWildcard = {
  name: 'DURATION',
  regexp: /\B([-+])([0-9]+h)?([0-9]+m)?\b/,
  parseCaptureGroups: ([raw, direction, hours, minutes]) => ({
    raw,
    direction,
    parsedHours: hours ? Number(hours.substring(0, hours.length - 1)) : 0,
    parsedMinutes: minutes
      ? Number(minutes.substring(0, minutes.length - 1))
      : 0,
  }),
  getPointsToAdd: (
    getState,
    rawPointInput,
    { raw, direction, parsedHours, parsedMinutes }
  ) => {
    const pointInput = {
      ...rawPointInput,
      description: rawPointInput.description.replace(raw, ''),
    }
    const proposedPointInputs =
      direction === '-'
        ? [
            {
              ...pointInput,
              timestamp: subMinutes(
                subHours(parseISO(pointInput.timestamp), parsedHours),
                parsedMinutes
              ).toISOString(),
            },
            {
              ...pointInput,
              description: `end of ${pointInput.description}`,
              issue: null,
            },
          ]
        : [
            pointInput,
            {
              ...pointInput,
              description: `end of ${pointInput.description}`,
              issue: null,
              timestamp: addMinutes(
                addHours(parseISO(pointInput.timestamp), parsedHours),
                parsedMinutes
              ).toISOString(),
            },
          ]

    // validations
    const { points } = getState()
    const isRounded = proposedPointInputs.every(
      (p) => roundPointTime(p.timestamp) === p.timestamp
    )
    const isPointBetween = points.find(
      (p) =>
        // assuming proposedPointInputs[0].timestamp < proposedPointInputs[1].timestamp
        isBefore(
          parseISO(proposedPointInputs[0].timestamp),
          parseISO(p.timestamp)
        ) &&
        !isBefore(
          parseISO(proposedPointInputs[1].timestamp),
          parseISO(p.timestamp)
        )
    )

    const isValidTimestamp =
      (parsedHours > 0 || parsedMinutes > 0) && isRounded && !isPointBetween
    if (!isValidTimestamp) {
      throw new Error(
        'Worklog with specified duration could not be created. '.concat(
          isPointBetween ? 'It intersects with another worklog. ' : '',
          !isRounded ? 'Duration needs to be rounded.' : ''
        )
      )
    }

    return proposedPointInputs
  },
}

/**
 * Compares entered description against wildcards and performs its effects.
 * If a match is found, log is enhanced (time/date/issue is set, etc). List of PointInputs to be added to the points array is returned.
 * If a match is found, but doesn't meet validations a list of warnings is returned
 * If no match is found, the list contains the initially entered point only.
 *
 * Wildcards are split to two categories - single-log ones (enhance and return single worklog) and a multi one (currently only duration wildcard, it returns 2 logs).
 * NOTICE: Returned PointInputs are only suggestions, they are subject to standard further validation (touches synced, etc).
 */
export const matchWildcards = (
  getState: () => State,
  enteredPointInput: PointInput
): { pointInputs: PointInput[]; warnings?: string[] } => {
  const processedWildcards = SINGLE_LOG_WILDCARDS.reduce(
    (acc, wildcard) => {
      const match = wildcard.regexp.exec(enteredPointInput.description)
      if (match) {
        const { pointInput, warning } = wildcard.getPointsToAdd(
          getState,
          acc.pointInput,
          wildcard.parseCaptureGroups(match) as any // TODO: remove any
        )
        const warnings = warning ? [...acc.warnings, warning] : acc.warnings
        return { pointInput, warnings }
      }
      return acc
    },
    { pointInput: enteredPointInput, warnings: [] as string[] }
  )

  // lastly, match duration wildcard (separately because of different interface - possibly returning 2 PointInputs)
  const match = DURATION_WILDCARD.regexp.exec(enteredPointInput.description)
  return {
    pointInputs: match
      ? DURATION_WILDCARD.getPointsToAdd(
          getState,
          processedWildcards.pointInput,
          DURATION_WILDCARD.parseCaptureGroups(match)
        )
      : [processedWildcards.pointInput],
    warnings: processedWildcards.warnings,
  }
}
