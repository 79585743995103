import React from 'react'

import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'

import { setSettings } from '../../actions/trackerActions'

import { CustomTextField, useTextFieldStyles } from './styles'

const ftePercentageFormat = (input: string) => {
  const numberInput = parseInt(input, 10)
  if (numberInput > 100) return '100%'
  return `${numberInput}%`
}

export const FtePercentageInput = ({ value }) => {
  const classes = useTextFieldStyles()
  const dispatch = useDispatch()
  return (
    <div className={classes.inputWrapper}>
      <NumberFormat
        variant="outlined"
        label="Working hours"
        customInput={CustomTextField}
        format={ftePercentageFormat}
        value={value}
        onBlur={(e) =>
          dispatch(
            setSettings({
              ftePercentage:
                e.target.value === '' ? 100 : parseInt(e.target.value, 10),
            })
          )
        }
      />
    </div>
  )
}
