import React, { useCallback } from 'react'

import { makeStyles, Box, Button, Tooltip } from '@material-ui/core'
import { isToday, parseISO } from 'date-fns'
import { useDispatch, batch } from 'react-redux'

import { createPoint } from '../../actions/editPointsActions'
import { modifyLogger, setLogger } from '../../actions/loggerActions'
import { setFocus, setCursor, showCursor } from '../../actions/trackerActions'
import { Input, Focus, ComponentHeights } from '../../consts'
import { useSectionFocus } from '../../hooks/useSectionFocus'
import { useSelector } from '../../hooks/useSelector'
import { Dispatch } from '../../types/reduxTypes'
import { roundPointTime, blurElement } from '../../utils'

import { LoggerDateInput } from './LoggerDateInput'
import { LoggerInput } from './LoggerInput'
import { LoggerTimeInput } from './LoggerTimeInput'

const useStyles = makeStyles((theme) => ({
  logger: {
    borderTop: `1px solid ${theme.palette.border.main}`,
    padding: theme.spacing(0, 3),
    height: `${ComponentHeights.LOGGER}`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    minWidth: 140,
    height: 44,
    textTransform: 'none',
    marginLeft: theme.spacing(3),
  },
}))

const resetInput = (dispatch: Dispatch, isToday: boolean) => {
  if (isToday)
    dispatch(
      setLogger({
        description: '',
        timestamp: new Date().toISOString(),
      })
    )
  else {
    dispatch(modifyLogger(Input.DESCRIPTION, ''))
  }
}

export const Logger = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  const {
    value: timestampInputValue,
    isModified: isTimestampModified,
  } = useSelector((state) => state.logger.inputs[Input.TIMESTAMP])
  const descriptionInputValue = useSelector(
    (state) => state.logger.inputs[Input.DESCRIPTION].value
  )

  const handleSubmit = useCallback(
    (focusLogger?: boolean, timestampOverride?: Date) => {
      const isModified = isTimestampModified || Boolean(timestampOverride)
      const timestamp = roundPointTime(
        timestampOverride?.toISOString() || timestampInputValue
      )

      const roundedNow = roundPointTime(new Date().toISOString())
      batch(() => {
        dispatch(
          createPoint(
            descriptionInputValue,
            isModified ? timestamp : roundedNow
          )
        )

        if (focusLogger) {
          resetInput(dispatch, isToday(parseISO(timestamp)))
          dispatch(setFocus({ focusType: Focus.DESCRIPTION }))
          dispatch(setCursor(-1))
        } else {
          blurElement()
        }
      })
    },
    [descriptionInputValue, timestampInputValue, isTimestampModified, dispatch]
  )

  const onStartLogging = useCallback(() => {
    dispatch(setCursor(-1))
  }, [dispatch])

  const onStopLogging = useCallback(() => {
    dispatch(showCursor())
    resetInput(dispatch, isToday(parseISO(timestampInputValue)))
  }, [dispatch, timestampInputValue])

  const { setIsFocused } = useSectionFocus(onStartLogging, onStopLogging)

  return (
    <section
      className={classes.logger}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    >
      <LoggerInput
        handleSubmit={handleSubmit}
        value={descriptionInputValue}
        focusedAs={Focus.DESCRIPTION}
      />
      <Box display="flex" className="tutorial-dateAndTime">
        <LoggerTimeInput
          handleSubmit={handleSubmit}
          value={timestampInputValue}
        />
        <LoggerDateInput
          handleSubmit={handleSubmit}
          value={timestampInputValue}
        />
      </Box>
      <Tooltip title="Add worklog" placement="top">
        <Button
          variant="contained"
          color="primary"
          disableElevation
          className={classes.btn}
          onClick={() => handleSubmit()}
        >
          Add
        </Button>
      </Tooltip>
    </section>
  )
}
