import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { Worklog } from '../../actions/submittableWorkLogsSelector'
import { toggleFreeTime } from '../../actions/trackerActions'
import { DateTimeFormat } from '../../consts'

const useStyles = makeStyles((theme) => ({
  item: {
    paddingBottom: theme.spacing(1.5),
    '& + &': {
      borderTop: `1px solid ${theme.palette.border.main}`,
      paddingTop: theme.spacing(1.5),
    },
  },
  date: {
    display: 'block',
    marginBottom: theme.spacing(1) / 2,
  },
  worklogData: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    maxWidth: 450,
    lineHeight: '22px',
  },
  issue: {
    marginLeft: theme.spacing(3),
  },
  underline: {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '13px',
    lineHeight: '16px',
    marginLeft: theme.spacing(3),
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
}))

interface Props {
  worklog: Worklog
}

export const ReviewWorklog = ({ worklog }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const startDate = moment(worklog.dateStarted)
  const endDate = moment(worklog.dateStarted).add(
    worklog.timeSpentSeconds,
    'seconds'
  )

  return (
    <div className={classes.item}>
      <Typography
        variant="caption"
        color="textSecondary"
        className={classes.date}
      >
        {startDate.format(DateTimeFormat.DATE)}
        {endDate.isSame(startDate, 'day')
          ? ' '
          : ` - ${endDate.format(DateTimeFormat.DATE)}`}
        {!endDate.isSame(startDate, 'day') && <br />}
        {startDate.format(DateTimeFormat.TIME)}
        {' - '}
        {endDate.format(DateTimeFormat.TIME)}
      </Typography>
      <div className={classes.worklogData}>
        <div className={classes.description}>{worklog.comment}</div>
        <div className={classes.rightSide}>
          <div>{worklog.durationLabel}</div>
          {worklog.issue && (
            <div className={classes.issue}>{worklog.issue}</div>
          )}
          <Typography
            color="primary"
            align="right"
            variant="caption"
            className={classes.underline}
            onClick={() => dispatch(toggleFreeTime([worklog.id]))}
          >
            Free time
          </Typography>
        </div>
      </div>
    </div>
  )
}
