// TODO: move to selectors
import { parseISO } from 'date-fns'
import { createSelector } from 'reselect'

import {
  isForbiddenToBeSubmitted,
  isPrevPeriodWorklogDate,
} from '../closedPeriodCheck'
import { State, Issue } from '../store/state'
import { secondsBetweenDates, formatTime } from '../utils'

export type Worklog = {
  id: number
  synced?: boolean
  comment: string
  // TODO: remove null (it's optional already), see Point
  issue?: string | null
  dateStarted: string
  timeSpentSeconds: number
  freeTime: boolean
  durationLabel: string
  jiraId: string | undefined | null
}

// TS https://github.com/microsoft/TypeScript/issues/16069
type SubmittableWorklog = Worklog & {
  synced: false
  issue: Issue
  freeTime: false
}

const pointsSelector = (state: State) => state.points

const workLogsSelector = createSelector([pointsSelector], (points): Worklog[] =>
  points.map((point, index) => {
    const timeSpentSeconds =
      index < points.length - 1
        ? secondsBetweenDates(points[index + 1].timestamp, point.timestamp)
        : 0
    return {
      id: index,
      synced: point.synced,
      comment: point.description,
      issue: point.issue,
      dateStarted: point.timestamp,
      freeTime: point.freeTime,
      timeSpentSeconds,
      durationLabel: formatTime(timeSpentSeconds),
      jiraId: point.jiraId,
    }
  })
)

const submittablePredicate = (worklog: Worklog) =>
  !worklog.synced &&
  worklog.issue &&
  !worklog.freeTime &&
  worklog.timeSpentSeconds > 0

export const submittableWorkLogsSelector = createSelector(
  [workLogsSelector],
  (worklogs) => worklogs.filter(submittablePredicate) as SubmittableWorklog[]
)

export const worklogsForbiddenToBeSubmittedSelector = createSelector(
  [workLogsSelector],
  (worklogs) =>
    worklogs.filter(
      (worklog) =>
        submittablePredicate(worklog) &&
        isForbiddenToBeSubmitted(parseISO(worklog.dateStarted))
    ) as SubmittableWorklog[]
)

export const prevPeriodsWorklogsSelector = createSelector(
  [workLogsSelector],
  (worklogs) =>
    worklogs.filter(
      (worklog) =>
        submittablePredicate(worklog) &&
        isPrevPeriodWorklogDate(parseISO(worklog.dateStarted))
    ) as SubmittableWorklog[]
)

export const unassignedWorkLogsSelector = createSelector(
  [workLogsSelector],
  (worklogs) =>
    worklogs.filter(
      (worklog) =>
        !worklog.synced &&
        !worklog.issue &&
        !worklog.freeTime &&
        worklog.comment !== '' &&
        worklog.timeSpentSeconds > 0
      // TS https://github.com/microsoft/TypeScript/issues/16069
    ) as (Worklog & { synced: false; issue: null; freeTime: false })[]
)

export const freeTimeLogsSelector = createSelector(
  [workLogsSelector],
  (worklogs) =>
    worklogs.filter(
      (worklog) =>
        !worklog.synced && worklog.freeTime && worklog.timeSpentSeconds > 0
    )
)
