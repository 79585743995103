import { useState, useEffect } from 'react'

import { useDispatch, useStore } from 'react-redux'

import { clearFocus, setFocus } from '../actions/trackerActions'
import { Focus } from '../consts'
import { useSelector } from '../hooks/useSelector'
import { FocusState } from '../store/state'

export const useFocusListener = <
  PreciseFocusState extends FocusState = FocusState
>(
  focusType: Focus
) => {
  const store = useStore()
  const focus = useSelector((state) => state.focus)
  const dispatch = useDispatch()

  const [ref, setRef] = useState<HTMLInputElement | null>(null)

  useEffect(() => {
    setTimeout(() => {
      // we can't use focus from selector, because this will be executed asynchronously and the
      // value might have changed.
      if (ref && store.getState().focus?.focusType === focusType) {
        ref.focus()
      }
    }, 0)
  })

  return {
    // Focus can be triggered by another component or via user interaction (e.g. clicking the field)
    // This method will only dispatch if there is no focus at the moment.
    ensureFocus: () => {
      if (!focus) dispatch(setFocus({ focusType }))
    },
    handleRef: (newRef: HTMLInputElement | null) => newRef && setRef(newRef),
    handleBlur: (e) => {
      if (document.activeElement === ref) {
        e.stopPropagation()
        return
      }
      dispatch(clearFocus())
    },
    focus: focus as PreciseFocusState,
    hasFocus: focus?.focusType === focusType,
  }
}
