import * as React from 'react'

import { Switch, Route, BrowserRouter } from 'react-router-dom'

import App from './App'
import Login from './Login'

export const Navigation = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <App />
      </Route>
      <Route path="/:tenantId">
        <Login />
      </Route>
    </Switch>
  </BrowserRouter>
)
