import React, { useRef } from 'react'

import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { editPointForSelection, switchMode } from '../../actions/trackerActions'
import { ContentEditable } from '../../components/ContentEditable'
import { Mode, DateTimeFormat } from '../../consts'
import { roundPointTime } from '../../utils'
import { DetailedEvent } from '../eventsSelectors'

import { EditableTimeInput } from './EditableTimeInput'

interface Props {
  event: DetailedEvent
  classes: Record<'timeWrapper' | 'time' | 'keyTime', string>
}

export const TimeLabel = ({ event, classes }: Props) => {
  return (
    <div className={classes.timeWrapper} data-cy="worklog-start-time">
      <Tooltip title={event.timeGrayedOut ? 'Not editable' : ''}>
        <span className={classes.time}>
          {moment(event.start).format(DateTimeFormat.TIME)}
        </span>
      </Tooltip>
      {event.showShortcuts && (
        <kbd className={classes.keyTime} data-cy="worklog-edit-time-shortcut">
          T
        </kbd>
      )}
    </div>
  )
}

export const EditableTime = ({ event, classes }: Props) => {
  const timeRef = useRef<HTMLElement>()
  const dispatch = useDispatch()

  const { start, editingTime } = event
  //TODO: get rid of moment js https://vacuum.atlassian.net/browse/FINE-80 (and replace with date-fns where needed)
  const eventStart = start.toISOString()

  return (
    <ContentEditable<string>
      isMainEditComponent={true}
      labelComponent={<TimeLabel classes={classes} event={event} />}
      isEditing={editingTime}
      setEditing={(editing) =>
        dispatch(switchMode(editing ? Mode.EDIT_TIME : Mode.LOGGING))
      }
      onTab={() => dispatch(switchMode(Mode.EDIT_DESCRIPTION))}
      editRef={timeRef}
      editComponent={(value, setValue) => (
        <EditableTimeInput
          value={value}
          setValue={setValue}
          inputRef={timeRef}
          timestamp={eventStart}
        />
      )}
      value={eventStart}
      changeValue={(newTimestamp) =>
        dispatch(
          editPointForSelection({
            timestamp: roundPointTime(newTimestamp),
          })
        )
      }
    />
  )
}
