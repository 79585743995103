import { differenceBy } from 'lodash'

import { UpsertPointsDocument } from '../generated/graphqlSdk'
import { State } from '../store/state'
import { mapPoints } from '../tracker/utils'
import { Action, Thunk } from '../types/reduxTypes'

import { updateDbSyncStatus } from './utilActions'

export const addToHistory = (stateToCapture: Object): Action => ({
  type: 'Add to history',
  doNotLog: true,
  payload: stateToCapture,
  reducer: (state: State) => {
    const { history } = state
    return {
      ...state,
      history: {
        states: [
          ...history.states.filter(
            (state: Object, i: number) => i <= history.index
          ),
          stateToCapture,
        ],
        index: history.index + 1,
      },
    }
  },
})

const _moveHistory = (direction: number): Action => ({
  type: direction === -1 ? 'Undo' : 'Redo',
  payload: direction,
  reducer: (state: State) => {
    const { history } = state
    const prevState = history.states[history.index + direction]
    return {
      ...state,
      ...prevState,
      history: {
        ...history,
        index: history.index + direction,
      },
    }
  },
})

const moveHistory = (direction: number): Thunk<void> => async (
  dispatch,
  getState,
  { logger, client }
) => {
  const { index, states } = getState().history
  if (index + direction < 0 || index + direction >= states.length) {
    return
  }

  logger.log(
    `Thunk - ${direction === -1 ? 'Undo' : 'Redo'} & Sync points in DB`
  )

  const prevPoints = getState().points
  dispatch(_moveHistory(direction))

  try {
    const unsyncedPoints = getState().points.filter((p) => !p.synced)
    const pointIdsToRemove = differenceBy(
      prevPoints,
      getState().points,
      'id'
    ).map((p) => p.id)
    dispatch(updateDbSyncStatus({ loading: true }))
    await client.mutate({
      mutation: UpsertPointsDocument,
      variables: { points: mapPoints(unsyncedPoints), pointIdsToRemove },
    })
    dispatch(updateDbSyncStatus({ loading: false, success: true }))
  } catch (e) {
    dispatch(
      updateDbSyncStatus({
        loading: false,
        success: false,
        error: e.message,
      })
    )
  }
}

export const undo = () => moveHistory(-1)
export const redo = () => moveHistory(1)
