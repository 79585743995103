import React from 'react'

import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
  Tooltip,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import InfoIcon from '@material-ui/icons/Info'
import { useDispatch } from 'react-redux'

import { setMinimalDate, setSettings } from '../../actions/trackerActions'
import {
  useGetSettingsQuery,
  useInsertSettingsMutation,
  GetSettingsDocument,
} from '../../generated/graphqlSdk'
import { useSelector } from '../../hooks/useSelector'

import { DaysToShowInput } from './DaysToShowInput'
import { FtePercentageInput } from './FtePercentageInput'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    paddingBottom: theme.spacing(2),
  },
  formItem: {
    margin: theme.spacing(1, 0, 0),
  },
  formSection: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

interface Props {
  isOpened: any
  onClose: any
}

export const SettingsDialog = ({ isOpened, onClose }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const accountId = useSelector((state) => state.user?.accountId) || ''

  const {
    expandWorklogs,
    showTimeline,
    ftePercentage,
    daysToShow,
  } = useSelector((state) => state.settings)

  // TODO: settings should be loaded by App.tsx and not this dialog
  useGetSettingsQuery({
    variables: { accountId },
    onCompleted: (data) => {
      const settings = data.settings_by_pk
      if (!settings) return
      dispatch(
        setSettings({
          expandWorklogs: settings.expand_worklogs,
          showTimeline: settings.show_timeline,
          ftePercentage: settings.fte_percentage,
          daysToShow: settings.days_to_show,
        })
      )
      dispatch(setMinimalDate())
    },
  })
  const [updateSettings] = useInsertSettingsMutation()
  const onSave = () => {
    updateSettings({
      variables: {
        expandWorklogs,
        showTimeline,
        ftePercentage: ftePercentage ? ftePercentage : 100,
        daysToShow,
      },
      refetchQueries: [
        { query: GetSettingsDocument, variables: { accountId } },
      ],
    })
    dispatch(setMinimalDate())
    onClose()
  }

  return (
    <Dialog
      maxWidth="sm"
      open={isOpened}
      onClose={onSave}
      PaperProps={{
        style: {
          minWidth: '520px',
          padding: 0,
        },
      }}
    >
      <DialogTitle style={{ paddingBottom: 0 }}>
        User settings
        <IconButton
          aria-label="close settings"
          className={classes.closeButton}
          onClick={onSave}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.subtitle}>
          Customize your FineTracker experience.
        </DialogContentText>

        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault()
            onSave()
          }}
          noValidate
        >
          <Typography className={classes.formSection}>
            Save mode settings
          </Typography>
          <FormControlLabel
            className={classes.formItem}
            control={
              <Switch
                color="primary"
                checked={expandWorklogs}
                onChange={(e) => {
                  dispatch(setSettings({ expandWorklogs: e.target.checked }))
                }}
              />
            }
            label="Always expand worklogs in submit mode"
          />
          <Typography className={classes.formSection}>
            Worklogs settings
          </Typography>
          <FormControlLabel
            className={classes.formItem}
            control={
              <Switch
                color="primary"
                checked={showTimeline}
                onChange={(e) =>
                  dispatch(setSettings({ showTimeline: e.target.checked }))
                }
              />
            }
            label="Show timeline"
          />
          <FormControlLabel
            className={classes.formItem}
            control={<DaysToShowInput value={daysToShow} />}
            label={
              <span style={{ display: 'flex' }}>
                Number of days to show worklogs for
                <Tooltip
                  style={{ marginLeft: 4, marginTop: -2 }}
                  title="Smaller number results in better performance of fine tracker. The points will be filtered on the client side after server response - meaning, we will not delete them."
                >
                  <InfoIcon color="primary" />
                </Tooltip>
              </span>
            }
          />
          <FormControlLabel
            className={classes.formItem}
            control={<FtePercentageInput value={ftePercentage} />}
            label="Set working time"
          />
        </form>
      </DialogContent>
    </Dialog>
  )
}
