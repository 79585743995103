import React, { useState, useEffect } from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { ErrorOutline, Warning } from '@material-ui/icons'
import { map } from 'lodash'
import { useDispatch } from 'react-redux'

import {
  unassignedWorkLogsSelector,
  prevPeriodsWorklogsSelector,
} from '../../actions/submittableWorkLogsSelector'
import {
  toggleWorklogsDetail,
  toggleFreeTime,
} from '../../actions/trackerActions'
import { ComponentHeights } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import * as styles from '../WorkLogs/styles'

import Issue from './Issue'
import { ReviewWorklog } from './ReviewWorklog'
import {
  totalLoggedTimeSelector,
  loggedTimeByProjectSelector,
  totalNumberOfWorkLogsSelector,
  expandWorklogsSelector,
} from './saveWorklogsSelectors'

const useStyles = makeStyles((theme) => ({
  header: {
    minHeight: '76px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  body: {
    padding: theme.spacing(1.5, 3),
    overflowY: 'auto',
    boxSizing: 'border-box',
    maxHeight: `calc(100% - ${ComponentHeights.LOGGED_SUMMARY_HEADER} - ${ComponentHeights.LOGGED_SUMMARY_FOOTER})`,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  underline: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  pointsByIssue: {
    paddingTop: theme.spacing(2.5),
  },
  unassignedPoints: {
    paddingTop: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  previousMonthSection: {
    marginBottom: theme.spacing(3),
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  project: {
    '& + &': {
      paddingTop: theme.spacing(3.5),
      borderTop: `1px solid ${theme.palette.border.main}`,
    },
  },
  projectKey: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  duration: { ...styles.duration },
  sectionHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
  },
  flexTitle: {
    display: 'flex',
  },
  icon: {
    width: 16,
    height: 16,
    marginRight: theme.spacing(1),
  },
}))

const LoggedTimeSummary = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [numberOfVisibleWorklogs, setNumberOfVisibleWorklogs] = useState(0)
  const expandWorklogs = useSelector(expandWorklogsSelector)
  const timeByProjects = useSelector(loggedTimeByProjectSelector)
  const totalTime = useSelector(totalLoggedTimeSelector)
  const numberOfWorkLogs = useSelector(totalNumberOfWorkLogsSelector)
  const unassignedWorklogs = useSelector(unassignedWorkLogsSelector)
  const unassignedIds = unassignedWorklogs.map((worklog) => worklog.id)
  const prevPeriodsWorklogs = useSelector(prevPeriodsWorklogsSelector)
  const prevPeriodsIds = prevPeriodsWorklogs.map((worklog) => worklog.id)

  // If one expands all logs using key shortcut, then they close them
  // one by one using mouse - if they used the shortcut again, it would
  // have no effect, what feels unnatural / buggy
  // So we keep track of number of visible worklogs, and handle the key
  // changes in useEffect
  const handleVisibilityChange = (visible: boolean) => {
    const currentVisibleWorklogs = numberOfVisibleWorklogs + (visible ? +1 : -1)
    setNumberOfVisibleWorklogs(currentVisibleWorklogs)

    // This is not in separate useEffect to avoid rerender loop
    if (currentVisibleWorklogs === 0 && expandWorklogs) {
      dispatch(toggleWorklogsDetail(false))
    } else if (currentVisibleWorklogs === numberOfWorkLogs && !expandWorklogs) {
      dispatch(toggleWorklogsDetail(true))
    }
  }

  useEffect(() => {
    setNumberOfVisibleWorklogs(expandWorklogs ? numberOfWorkLogs : 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandWorklogs])

  return (
    <>
      <div className={classes.header}>
        <strong>Hours to log</strong>
        <strong className={classes.duration}>{totalTime}</strong>
      </div>
      <div className={classes.body}>
        {prevPeriodsWorklogs.length > 0 && (
          <div
            data-cy="previous-period-worklogs"
            className={classes.previousMonthSection}
          >
            <div className={classes.sectionHeader}>
              <div className={classes.flexTitle}>
                <Warning className={classes.icon} />
                <strong>Worklogs from previous month(s)</strong>
              </div>
              <Typography
                color="primary"
                align="right"
                variant="caption"
                className={classes.underline}
                data-cy="previous-mark-free"
                onClick={() => dispatch(toggleFreeTime(prevPeriodsIds))}
              >
                Mark all as free time
              </Typography>
            </div>
            {prevPeriodsWorklogs.map((worklog) => (
              <ReviewWorklog worklog={worklog} key={worklog.id} />
            ))}
          </div>
        )}

        {!!numberOfWorkLogs && (
          <div className={classes.wrapper}>
            <Typography
              color="primary"
              align="right"
              variant="caption"
              className={classes.underline}
              onClick={() => dispatch(toggleWorklogsDetail())}
            >
              {expandWorklogs ? 'Hide' : 'Expand'} all
            </Typography>
          </div>
        )}
        <div className={classes.pointsByIssue}>
          {map(timeByProjects, (project) => (
            <div className={classes.project} key={project.projectKey}>
              <div className={classes.projectKey}>
                <strong>{project.projectKey}</strong>
                <strong className={classes.duration}>
                  {project.projectTime}
                </strong>
              </div>
              {map(project.projectIssues, (issue) => (
                <Issue
                  issue={issue}
                  key={issue.projectIssueKey}
                  handleVisibilityChange={handleVisibilityChange}
                />
              ))}
            </div>
          ))}
        </div>
        {unassignedWorklogs.length > 0 && (
          <div className={classes.unassignedPoints}>
            <div className={classes.sectionHeader}>
              <div className={classes.flexTitle}>
                <ErrorOutline className={classes.icon} />
                <strong>No issue assigned</strong>
              </div>
              <Typography
                color="primary"
                align="right"
                variant="caption"
                className={classes.underline}
                onClick={() => dispatch(toggleFreeTime(unassignedIds))}
              >
                Mark all as free time
              </Typography>
            </div>
            {unassignedWorklogs.map((worklog) => (
              <ReviewWorklog worklog={worklog} key={worklog.id} />
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default LoggedTimeSummary
