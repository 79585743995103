import {
  addMinutes,
  isSameDay,
  isAfter,
  parseISO,
  setHours,
  setMinutes,
  startOfDay,
  isToday,
} from 'date-fns'
import { findLast } from 'lodash'
import moment from 'moment'

import { Point } from './store/state'

export const roundNumber = (
  time: number,
  method: string,
  rounding: number
): number => Math[method](time / rounding) * rounding

export const SECONDS_IN_WORKING_DAY = 28800

const roundTime = (time: string, method: string, rounding: number): string =>
  moment(Math[method](+moment(time) / rounding) * rounding).toISOString()

// remove smaller units than minutes and then round to 15 minutes
export const roundPointTime = (time: string): string => {
  const time2345 = setMinutes(setHours(new Date(), 23), 45)
  if (isAfter(parseISO(time), time2345)) {
    return time2345.toISOString()
  }
  return roundTime(roundTime(time, 'floor', 60 * 1000), 'round', 15 * 60 * 1000)
}

export const filterObject = (obj: Object, objFilter: Object): Object =>
  Object.keys(obj).reduce((accum: Object, attr: string) => {
    if (typeof objFilter[attr] === 'object') {
      return { ...accum, [attr]: filterObject(obj[attr], objFilter[attr]) }
    } else if (objFilter[attr]) {
      return { ...accum, [attr]: obj[attr] }
    }
    return accum
  }, {})

export const blurElement = () => {
  const focusedElement = document.activeElement as HTMLElement
  focusedElement && focusedElement.blur()
}

export const touchesSyncedInterval = (points: Point[]) => (index: number) =>
  index > 0 && points[index - 1].synced && !points[index - 1].freeTime

export const touchesSyncedFreeTime = (points: Point[]) => (index: number) =>
  index > 0 && points[index - 1].synced && points[index - 1].freeTime

export const formatTime = (seconds: number): string => {
  const method = seconds > 0 ? 'floor' : 'ceil'
  const hours = Math.abs(Math[method](seconds / 3600))
  const minutes = Math.abs(Math[method]((seconds % 3600) / 60))

  const sign = seconds < 0 ? '-' : ''
  const hoursLabel = hours ? `${hours}h ` : ''
  const minutesLabel =
    hours || minutes ? `${minutes.toString().padStart(2, '0')}min` : ''
  return `${sign}${hoursLabel}${minutesLabel}`
}

type TimeStamp = Date | string

/**
 * Seconds and milliseconds are removed, so we can get more natural
 * looking minutes when converting to HH:mm for display
 */
export const secondsBetweenDates = (
  date1: TimeStamp,
  date2: TimeStamp
): number =>
  moment
    .duration(
      moment(date1)
        .seconds(0)
        .milliseconds(0)
        .diff(moment(date2).seconds(0).millisecond(0))
    )
    .asSeconds()

export const getNewWorklogTimestamp = (points: Point[], date: Date) => {
  const now = new Date()
  if (isToday(date)) {
    return now.toISOString()
  }

  const point = findLast(points, (point) =>
    isSameDay(parseISO(point.timestamp), date)
  )
  if (point !== undefined) {
    return addMinutes(parseISO(point.timestamp), 15).toISOString()
  }
  return setHours(startOfDay(date), 9).toISOString()
}
