import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  timestamptz: any
  uuid: any
}

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts'
  account_id: Scalars['String']
  avatar_url: Scalars['String']
  created_at: Scalars['timestamptz']
  name: Scalars['String']
  /** An object relationship */
  tenant?: Maybe<Tenants>
  tenant_id?: Maybe<Scalars['String']>
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate'
  aggregate?: Maybe<Accounts_Aggregate_Fields>
  nodes: Array<Accounts>
}

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Accounts_Max_Fields>
  min?: Maybe<Accounts_Min_Fields>
}

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Accounts_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Accounts_Max_Order_By>
  min?: Maybe<Accounts_Min_Order_By>
}

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>
  on_conflict?: Maybe<Accounts_On_Conflict>
}

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Accounts_Bool_Exp>>>
  _not?: Maybe<Accounts_Bool_Exp>
  _or?: Maybe<Array<Maybe<Accounts_Bool_Exp>>>
  account_id?: Maybe<String_Comparison_Exp>
  avatar_url?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  tenant?: Maybe<Tenants_Bool_Exp>
  tenant_id?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
}

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  avatar_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  tenant?: Maybe<Tenants_Obj_Rel_Insert_Input>
  tenant_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields'
  account_id?: Maybe<Scalars['String']>
  avatar_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  account_id?: Maybe<Order_By>
  avatar_url?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields'
  account_id?: Maybe<Scalars['String']>
  avatar_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  account_id?: Maybe<Order_By>
  avatar_url?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Accounts>
}

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input
  on_conflict?: Maybe<Accounts_On_Conflict>
}

/** on conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint
  update_columns: Array<Accounts_Update_Column>
  where?: Maybe<Accounts_Bool_Exp>
}

/** ordering options when selecting data from "accounts" */
export type Accounts_Order_By = {
  account_id?: Maybe<Order_By>
  avatar_url?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant?: Maybe<Tenants_Order_By>
  tenant_id?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: "accounts" */
export type Accounts_Pk_Columns_Input = {
  account_id: Scalars['String']
}

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  avatar_url?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>
  _gt?: Maybe<Scalars['Boolean']>
  _gte?: Maybe<Scalars['Boolean']>
  _in?: Maybe<Array<Scalars['Boolean']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Boolean']>
  _lte?: Maybe<Scalars['Boolean']>
  _neq?: Maybe<Scalars['Boolean']>
  _nin?: Maybe<Array<Scalars['Boolean']>>
}

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>
  _gt?: Maybe<Scalars['Int']>
  _gte?: Maybe<Scalars['Int']>
  _in?: Maybe<Array<Scalars['Int']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['Int']>
  _lte?: Maybe<Scalars['Int']>
  _neq?: Maybe<Scalars['Int']>
  _nin?: Maybe<Array<Scalars['Int']>>
}

/** columns and relationships of "issues" */
export type Issues = {
  __typename?: 'issues'
  account_id: Scalars['String']
  created_at: Scalars['timestamptz']
  issue_id: Scalars['String']
  title: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "issues" */
export type Issues_Aggregate = {
  __typename?: 'issues_aggregate'
  aggregate?: Maybe<Issues_Aggregate_Fields>
  nodes: Array<Issues>
}

/** aggregate fields of "issues" */
export type Issues_Aggregate_Fields = {
  __typename?: 'issues_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Issues_Max_Fields>
  min?: Maybe<Issues_Min_Fields>
}

/** aggregate fields of "issues" */
export type Issues_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Issues_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "issues" */
export type Issues_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Issues_Max_Order_By>
  min?: Maybe<Issues_Min_Order_By>
}

/** input type for inserting array relation for remote table "issues" */
export type Issues_Arr_Rel_Insert_Input = {
  data: Array<Issues_Insert_Input>
  on_conflict?: Maybe<Issues_On_Conflict>
}

/** Boolean expression to filter rows from the table "issues". All fields are combined with a logical 'AND'. */
export type Issues_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Issues_Bool_Exp>>>
  _not?: Maybe<Issues_Bool_Exp>
  _or?: Maybe<Array<Maybe<Issues_Bool_Exp>>>
  account_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  issue_id?: Maybe<String_Comparison_Exp>
  title?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "issues" */
export enum Issues_Constraint {
  /** unique or primary key constraint */
  IssuesPkey = 'issues_pkey',
}

/** input type for inserting data into table "issues" */
export type Issues_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Issues_Max_Fields = {
  __typename?: 'issues_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "issues" */
export type Issues_Max_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Issues_Min_Fields = {
  __typename?: 'issues_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "issues" */
export type Issues_Min_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "issues" */
export type Issues_Mutation_Response = {
  __typename?: 'issues_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Issues>
}

/** input type for inserting object relation for remote table "issues" */
export type Issues_Obj_Rel_Insert_Input = {
  data: Issues_Insert_Input
  on_conflict?: Maybe<Issues_On_Conflict>
}

/** on conflict condition type for table "issues" */
export type Issues_On_Conflict = {
  constraint: Issues_Constraint
  update_columns: Array<Issues_Update_Column>
  where?: Maybe<Issues_Bool_Exp>
}

/** ordering options when selecting data from "issues" */
export type Issues_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  title?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: "issues" */
export type Issues_Pk_Columns_Input = {
  account_id: Scalars['String']
  issue_id: Scalars['String']
}

/** select columns of table "issues" */
export enum Issues_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "issues" */
export type Issues_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "issues" */
export enum Issues_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root'
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>
  /** delete data from the table: "issues" */
  delete_issues?: Maybe<Issues_Mutation_Response>
  /** delete single row from the table: "issues" */
  delete_issues_by_pk?: Maybe<Issues>
  /** delete data from the table: "points" */
  delete_points?: Maybe<Points_Mutation_Response>
  /** delete single row from the table: "points" */
  delete_points_by_pk?: Maybe<Points>
  /** delete data from the table: "sessions" */
  delete_sessions?: Maybe<Sessions_Mutation_Response>
  /** delete single row from the table: "sessions" */
  delete_sessions_by_pk?: Maybe<Sessions>
  /** delete data from the table: "settings" */
  delete_settings?: Maybe<Settings_Mutation_Response>
  /** delete single row from the table: "settings" */
  delete_settings_by_pk?: Maybe<Settings>
  /** delete data from the table: "speed_dial" */
  delete_speed_dial?: Maybe<Speed_Dial_Mutation_Response>
  /** delete single row from the table: "speed_dial" */
  delete_speed_dial_by_pk?: Maybe<Speed_Dial>
  /** delete data from the table: "tenants" */
  delete_tenants?: Maybe<Tenants_Mutation_Response>
  /** delete single row from the table: "tenants" */
  delete_tenants_by_pk?: Maybe<Tenants>
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>
  /** insert data into the table: "issues" */
  insert_issues?: Maybe<Issues_Mutation_Response>
  /** insert a single row into the table: "issues" */
  insert_issues_one?: Maybe<Issues>
  /** insert data into the table: "points" */
  insert_points?: Maybe<Points_Mutation_Response>
  /** insert a single row into the table: "points" */
  insert_points_one?: Maybe<Points>
  /** insert data into the table: "sessions" */
  insert_sessions?: Maybe<Sessions_Mutation_Response>
  /** insert a single row into the table: "sessions" */
  insert_sessions_one?: Maybe<Sessions>
  /** insert data into the table: "settings" */
  insert_settings?: Maybe<Settings_Mutation_Response>
  /** insert a single row into the table: "settings" */
  insert_settings_one?: Maybe<Settings>
  /** insert data into the table: "speed_dial" */
  insert_speed_dial?: Maybe<Speed_Dial_Mutation_Response>
  /** insert a single row into the table: "speed_dial" */
  insert_speed_dial_one?: Maybe<Speed_Dial>
  /** insert data into the table: "tenants" */
  insert_tenants?: Maybe<Tenants_Mutation_Response>
  /** insert a single row into the table: "tenants" */
  insert_tenants_one?: Maybe<Tenants>
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>
  /** update data of the table: "issues" */
  update_issues?: Maybe<Issues_Mutation_Response>
  /** update single row of the table: "issues" */
  update_issues_by_pk?: Maybe<Issues>
  /** update data of the table: "points" */
  update_points?: Maybe<Points_Mutation_Response>
  /** update single row of the table: "points" */
  update_points_by_pk?: Maybe<Points>
  /** update data of the table: "sessions" */
  update_sessions?: Maybe<Sessions_Mutation_Response>
  /** update single row of the table: "sessions" */
  update_sessions_by_pk?: Maybe<Sessions>
  /** update data of the table: "settings" */
  update_settings?: Maybe<Settings_Mutation_Response>
  /** update single row of the table: "settings" */
  update_settings_by_pk?: Maybe<Settings>
  /** update data of the table: "speed_dial" */
  update_speed_dial?: Maybe<Speed_Dial_Mutation_Response>
  /** update single row of the table: "speed_dial" */
  update_speed_dial_by_pk?: Maybe<Speed_Dial>
  /** update data of the table: "tenants" */
  update_tenants?: Maybe<Tenants_Mutation_Response>
  /** update single row of the table: "tenants" */
  update_tenants_by_pk?: Maybe<Tenants>
}

/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  account_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_IssuesArgs = {
  where: Issues_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Issues_By_PkArgs = {
  account_id: Scalars['String']
  issue_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_PointsArgs = {
  where: Points_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Points_By_PkArgs = {
  id: Scalars['uuid']
}

/** mutation root */
export type Mutation_RootDelete_SessionsArgs = {
  where: Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Sessions_By_PkArgs = {
  jira_access_token: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_SettingsArgs = {
  where: Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Settings_By_PkArgs = {
  account_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_Speed_DialArgs = {
  where: Speed_Dial_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Speed_Dial_By_PkArgs = {
  account_id: Scalars['String']
  key: Scalars['String']
}

/** mutation root */
export type Mutation_RootDelete_TenantsArgs = {
  where: Tenants_Bool_Exp
}

/** mutation root */
export type Mutation_RootDelete_Tenants_By_PkArgs = {
  tenant_id: Scalars['String']
}

/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>
  on_conflict?: Maybe<Accounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input
  on_conflict?: Maybe<Accounts_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_IssuesArgs = {
  objects: Array<Issues_Insert_Input>
  on_conflict?: Maybe<Issues_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Issues_OneArgs = {
  object: Issues_Insert_Input
  on_conflict?: Maybe<Issues_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_PointsArgs = {
  objects: Array<Points_Insert_Input>
  on_conflict?: Maybe<Points_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Points_OneArgs = {
  object: Points_Insert_Input
  on_conflict?: Maybe<Points_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SessionsArgs = {
  objects: Array<Sessions_Insert_Input>
  on_conflict?: Maybe<Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Sessions_OneArgs = {
  object: Sessions_Insert_Input
  on_conflict?: Maybe<Sessions_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_SettingsArgs = {
  objects: Array<Settings_Insert_Input>
  on_conflict?: Maybe<Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Settings_OneArgs = {
  object: Settings_Insert_Input
  on_conflict?: Maybe<Settings_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Speed_DialArgs = {
  objects: Array<Speed_Dial_Insert_Input>
  on_conflict?: Maybe<Speed_Dial_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Speed_Dial_OneArgs = {
  object: Speed_Dial_Insert_Input
  on_conflict?: Maybe<Speed_Dial_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_TenantsArgs = {
  objects: Array<Tenants_Insert_Input>
  on_conflict?: Maybe<Tenants_On_Conflict>
}

/** mutation root */
export type Mutation_RootInsert_Tenants_OneArgs = {
  object: Tenants_Insert_Input
  on_conflict?: Maybe<Tenants_On_Conflict>
}

/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _set?: Maybe<Accounts_Set_Input>
  where: Accounts_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _set?: Maybe<Accounts_Set_Input>
  pk_columns: Accounts_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_IssuesArgs = {
  _set?: Maybe<Issues_Set_Input>
  where: Issues_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Issues_By_PkArgs = {
  _set?: Maybe<Issues_Set_Input>
  pk_columns: Issues_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_PointsArgs = {
  _set?: Maybe<Points_Set_Input>
  where: Points_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Points_By_PkArgs = {
  _set?: Maybe<Points_Set_Input>
  pk_columns: Points_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_SessionsArgs = {
  _set?: Maybe<Sessions_Set_Input>
  where: Sessions_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Sessions_By_PkArgs = {
  _set?: Maybe<Sessions_Set_Input>
  pk_columns: Sessions_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_SettingsArgs = {
  _inc?: Maybe<Settings_Inc_Input>
  _set?: Maybe<Settings_Set_Input>
  where: Settings_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Settings_By_PkArgs = {
  _inc?: Maybe<Settings_Inc_Input>
  _set?: Maybe<Settings_Set_Input>
  pk_columns: Settings_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_Speed_DialArgs = {
  _set?: Maybe<Speed_Dial_Set_Input>
  where: Speed_Dial_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Speed_Dial_By_PkArgs = {
  _set?: Maybe<Speed_Dial_Set_Input>
  pk_columns: Speed_Dial_Pk_Columns_Input
}

/** mutation root */
export type Mutation_RootUpdate_TenantsArgs = {
  _set?: Maybe<Tenants_Set_Input>
  where: Tenants_Bool_Exp
}

/** mutation root */
export type Mutation_RootUpdate_Tenants_By_PkArgs = {
  _set?: Maybe<Tenants_Set_Input>
  pk_columns: Tenants_Pk_Columns_Input
}

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "points" */
export type Points = {
  __typename?: 'points'
  account_id: Scalars['String']
  created_at: Scalars['timestamptz']
  description: Scalars['String']
  free_time: Scalars['Boolean']
  id: Scalars['uuid']
  issue_id?: Maybe<Scalars['String']>
  jira_id?: Maybe<Scalars['String']>
  synced: Scalars['Boolean']
  timestamp: Scalars['timestamptz']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "points" */
export type Points_Aggregate = {
  __typename?: 'points_aggregate'
  aggregate?: Maybe<Points_Aggregate_Fields>
  nodes: Array<Points>
}

/** aggregate fields of "points" */
export type Points_Aggregate_Fields = {
  __typename?: 'points_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Points_Max_Fields>
  min?: Maybe<Points_Min_Fields>
}

/** aggregate fields of "points" */
export type Points_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Points_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "points" */
export type Points_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Points_Max_Order_By>
  min?: Maybe<Points_Min_Order_By>
}

/** input type for inserting array relation for remote table "points" */
export type Points_Arr_Rel_Insert_Input = {
  data: Array<Points_Insert_Input>
  on_conflict?: Maybe<Points_On_Conflict>
}

/** Boolean expression to filter rows from the table "points". All fields are combined with a logical 'AND'. */
export type Points_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Points_Bool_Exp>>>
  _not?: Maybe<Points_Bool_Exp>
  _or?: Maybe<Array<Maybe<Points_Bool_Exp>>>
  account_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  description?: Maybe<String_Comparison_Exp>
  free_time?: Maybe<Boolean_Comparison_Exp>
  id?: Maybe<Uuid_Comparison_Exp>
  issue_id?: Maybe<String_Comparison_Exp>
  jira_id?: Maybe<String_Comparison_Exp>
  synced?: Maybe<Boolean_Comparison_Exp>
  timestamp?: Maybe<Timestamptz_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "points" */
export enum Points_Constraint {
  /** unique or primary key constraint */
  PointsPkey = 'points_pkey',
}

/** input type for inserting data into table "points" */
export type Points_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  free_time?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  issue_id?: Maybe<Scalars['String']>
  jira_id?: Maybe<Scalars['String']>
  synced?: Maybe<Scalars['Boolean']>
  timestamp?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Points_Max_Fields = {
  __typename?: 'points_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  issue_id?: Maybe<Scalars['String']>
  jira_id?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "points" */
export type Points_Max_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  jira_id?: Maybe<Order_By>
  timestamp?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Points_Min_Fields = {
  __typename?: 'points_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['uuid']>
  issue_id?: Maybe<Scalars['String']>
  jira_id?: Maybe<Scalars['String']>
  timestamp?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "points" */
export type Points_Min_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  id?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  jira_id?: Maybe<Order_By>
  timestamp?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "points" */
export type Points_Mutation_Response = {
  __typename?: 'points_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Points>
}

/** input type for inserting object relation for remote table "points" */
export type Points_Obj_Rel_Insert_Input = {
  data: Points_Insert_Input
  on_conflict?: Maybe<Points_On_Conflict>
}

/** on conflict condition type for table "points" */
export type Points_On_Conflict = {
  constraint: Points_Constraint
  update_columns: Array<Points_Update_Column>
  where?: Maybe<Points_Bool_Exp>
}

/** ordering options when selecting data from "points" */
export type Points_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  description?: Maybe<Order_By>
  free_time?: Maybe<Order_By>
  id?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  jira_id?: Maybe<Order_By>
  synced?: Maybe<Order_By>
  timestamp?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: "points" */
export type Points_Pk_Columns_Input = {
  id: Scalars['uuid']
}

/** select columns of table "points" */
export enum Points_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FreeTime = 'free_time',
  /** column name */
  Id = 'id',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  JiraId = 'jira_id',
  /** column name */
  Synced = 'synced',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "points" */
export type Points_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  description?: Maybe<Scalars['String']>
  free_time?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['uuid']>
  issue_id?: Maybe<Scalars['String']>
  jira_id?: Maybe<Scalars['String']>
  synced?: Maybe<Scalars['Boolean']>
  timestamp?: Maybe<Scalars['timestamptz']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "points" */
export enum Points_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FreeTime = 'free_time',
  /** column name */
  Id = 'id',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  JiraId = 'jira_id',
  /** column name */
  Synced = 'synced',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root'
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>
  /** fetch data from the table: "issues" */
  issues: Array<Issues>
  /** fetch aggregated fields from the table: "issues" */
  issues_aggregate: Issues_Aggregate
  /** fetch data from the table: "issues" using primary key columns */
  issues_by_pk?: Maybe<Issues>
  /** fetch data from the table: "points" */
  points: Array<Points>
  /** fetch aggregated fields from the table: "points" */
  points_aggregate: Points_Aggregate
  /** fetch data from the table: "points" using primary key columns */
  points_by_pk?: Maybe<Points>
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>
  /** fetch data from the table: "settings" */
  settings: Array<Settings>
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>
  /** fetch data from the table: "speed_dial" */
  speed_dial: Array<Speed_Dial>
  /** fetch aggregated fields from the table: "speed_dial" */
  speed_dial_aggregate: Speed_Dial_Aggregate
  /** fetch data from the table: "speed_dial" using primary key columns */
  speed_dial_by_pk?: Maybe<Speed_Dial>
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>
  /** fetch aggregated fields from the table: "tenants" */
  tenants_aggregate: Tenants_Aggregate
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>
}

/** query root */
export type Query_RootAccountsArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Accounts_Order_By>>
  where?: Maybe<Accounts_Bool_Exp>
}

/** query root */
export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Accounts_Order_By>>
  where?: Maybe<Accounts_Bool_Exp>
}

/** query root */
export type Query_RootAccounts_By_PkArgs = {
  account_id: Scalars['String']
}

/** query root */
export type Query_RootIssuesArgs = {
  distinct_on?: Maybe<Array<Issues_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Issues_Order_By>>
  where?: Maybe<Issues_Bool_Exp>
}

/** query root */
export type Query_RootIssues_AggregateArgs = {
  distinct_on?: Maybe<Array<Issues_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Issues_Order_By>>
  where?: Maybe<Issues_Bool_Exp>
}

/** query root */
export type Query_RootIssues_By_PkArgs = {
  account_id: Scalars['String']
  issue_id: Scalars['String']
}

/** query root */
export type Query_RootPointsArgs = {
  distinct_on?: Maybe<Array<Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Points_Order_By>>
  where?: Maybe<Points_Bool_Exp>
}

/** query root */
export type Query_RootPoints_AggregateArgs = {
  distinct_on?: Maybe<Array<Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Points_Order_By>>
  where?: Maybe<Points_Bool_Exp>
}

/** query root */
export type Query_RootPoints_By_PkArgs = {
  id: Scalars['uuid']
}

/** query root */
export type Query_RootSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Sessions_Order_By>>
  where?: Maybe<Sessions_Bool_Exp>
}

/** query root */
export type Query_RootSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Sessions_Order_By>>
  where?: Maybe<Sessions_Bool_Exp>
}

/** query root */
export type Query_RootSessions_By_PkArgs = {
  jira_access_token: Scalars['String']
}

/** query root */
export type Query_RootSettingsArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Settings_Order_By>>
  where?: Maybe<Settings_Bool_Exp>
}

/** query root */
export type Query_RootSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Settings_Order_By>>
  where?: Maybe<Settings_Bool_Exp>
}

/** query root */
export type Query_RootSettings_By_PkArgs = {
  account_id: Scalars['String']
}

/** query root */
export type Query_RootSpeed_DialArgs = {
  distinct_on?: Maybe<Array<Speed_Dial_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Speed_Dial_Order_By>>
  where?: Maybe<Speed_Dial_Bool_Exp>
}

/** query root */
export type Query_RootSpeed_Dial_AggregateArgs = {
  distinct_on?: Maybe<Array<Speed_Dial_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Speed_Dial_Order_By>>
  where?: Maybe<Speed_Dial_Bool_Exp>
}

/** query root */
export type Query_RootSpeed_Dial_By_PkArgs = {
  account_id: Scalars['String']
  key: Scalars['String']
}

/** query root */
export type Query_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tenants_Order_By>>
  where?: Maybe<Tenants_Bool_Exp>
}

/** query root */
export type Query_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tenants_Order_By>>
  where?: Maybe<Tenants_Bool_Exp>
}

/** query root */
export type Query_RootTenants_By_PkArgs = {
  tenant_id: Scalars['String']
}

/** columns and relationships of "sessions" */
export type Sessions = {
  __typename?: 'sessions'
  /** An object relationship */
  account: Accounts
  account_id: Scalars['String']
  created_at: Scalars['timestamptz']
  jira_access_secret?: Maybe<Scalars['String']>
  jira_access_token: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "sessions" */
export type Sessions_Aggregate = {
  __typename?: 'sessions_aggregate'
  aggregate?: Maybe<Sessions_Aggregate_Fields>
  nodes: Array<Sessions>
}

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_Fields = {
  __typename?: 'sessions_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Sessions_Max_Fields>
  min?: Maybe<Sessions_Min_Fields>
}

/** aggregate fields of "sessions" */
export type Sessions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sessions_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "sessions" */
export type Sessions_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Sessions_Max_Order_By>
  min?: Maybe<Sessions_Min_Order_By>
}

/** input type for inserting array relation for remote table "sessions" */
export type Sessions_Arr_Rel_Insert_Input = {
  data: Array<Sessions_Insert_Input>
  on_conflict?: Maybe<Sessions_On_Conflict>
}

/** Boolean expression to filter rows from the table "sessions". All fields are combined with a logical 'AND'. */
export type Sessions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sessions_Bool_Exp>>>
  _not?: Maybe<Sessions_Bool_Exp>
  _or?: Maybe<Array<Maybe<Sessions_Bool_Exp>>>
  account?: Maybe<Accounts_Bool_Exp>
  account_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  jira_access_secret?: Maybe<String_Comparison_Exp>
  jira_access_token?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "sessions" */
export enum Sessions_Constraint {
  /** unique or primary key constraint */
  SessionsPkey = 'sessions_pkey',
}

/** input type for inserting data into table "sessions" */
export type Sessions_Insert_Input = {
  account?: Maybe<Accounts_Obj_Rel_Insert_Input>
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  jira_access_secret?: Maybe<Scalars['String']>
  jira_access_token?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Sessions_Max_Fields = {
  __typename?: 'sessions_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  jira_access_secret?: Maybe<Scalars['String']>
  jira_access_token?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "sessions" */
export type Sessions_Max_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  jira_access_secret?: Maybe<Order_By>
  jira_access_token?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Sessions_Min_Fields = {
  __typename?: 'sessions_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  jira_access_secret?: Maybe<Scalars['String']>
  jira_access_token?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "sessions" */
export type Sessions_Min_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  jira_access_secret?: Maybe<Order_By>
  jira_access_token?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "sessions" */
export type Sessions_Mutation_Response = {
  __typename?: 'sessions_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Sessions>
}

/** input type for inserting object relation for remote table "sessions" */
export type Sessions_Obj_Rel_Insert_Input = {
  data: Sessions_Insert_Input
  on_conflict?: Maybe<Sessions_On_Conflict>
}

/** on conflict condition type for table "sessions" */
export type Sessions_On_Conflict = {
  constraint: Sessions_Constraint
  update_columns: Array<Sessions_Update_Column>
  where?: Maybe<Sessions_Bool_Exp>
}

/** ordering options when selecting data from "sessions" */
export type Sessions_Order_By = {
  account?: Maybe<Accounts_Order_By>
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  jira_access_secret?: Maybe<Order_By>
  jira_access_token?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: "sessions" */
export type Sessions_Pk_Columns_Input = {
  jira_access_token: Scalars['String']
}

/** select columns of table "sessions" */
export enum Sessions_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  JiraAccessSecret = 'jira_access_secret',
  /** column name */
  JiraAccessToken = 'jira_access_token',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "sessions" */
export type Sessions_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  jira_access_secret?: Maybe<Scalars['String']>
  jira_access_token?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "sessions" */
export enum Sessions_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  JiraAccessSecret = 'jira_access_secret',
  /** column name */
  JiraAccessToken = 'jira_access_token',
  /** column name */
  UpdatedAt = 'updated_at',
}

/**
 * User settings to customize FineTracker experience
 *
 *
 * columns and relationships of "settings"
 */
export type Settings = {
  __typename?: 'settings'
  account_id: Scalars['String']
  days_to_show: Scalars['Int']
  expand_worklogs: Scalars['Boolean']
  fte_percentage: Scalars['Int']
  show_timeline: Scalars['Boolean']
}

/** aggregated selection of "settings" */
export type Settings_Aggregate = {
  __typename?: 'settings_aggregate'
  aggregate?: Maybe<Settings_Aggregate_Fields>
  nodes: Array<Settings>
}

/** aggregate fields of "settings" */
export type Settings_Aggregate_Fields = {
  __typename?: 'settings_aggregate_fields'
  avg?: Maybe<Settings_Avg_Fields>
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Settings_Max_Fields>
  min?: Maybe<Settings_Min_Fields>
  stddev?: Maybe<Settings_Stddev_Fields>
  stddev_pop?: Maybe<Settings_Stddev_Pop_Fields>
  stddev_samp?: Maybe<Settings_Stddev_Samp_Fields>
  sum?: Maybe<Settings_Sum_Fields>
  var_pop?: Maybe<Settings_Var_Pop_Fields>
  var_samp?: Maybe<Settings_Var_Samp_Fields>
  variance?: Maybe<Settings_Variance_Fields>
}

/** aggregate fields of "settings" */
export type Settings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Settings_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "settings" */
export type Settings_Aggregate_Order_By = {
  avg?: Maybe<Settings_Avg_Order_By>
  count?: Maybe<Order_By>
  max?: Maybe<Settings_Max_Order_By>
  min?: Maybe<Settings_Min_Order_By>
  stddev?: Maybe<Settings_Stddev_Order_By>
  stddev_pop?: Maybe<Settings_Stddev_Pop_Order_By>
  stddev_samp?: Maybe<Settings_Stddev_Samp_Order_By>
  sum?: Maybe<Settings_Sum_Order_By>
  var_pop?: Maybe<Settings_Var_Pop_Order_By>
  var_samp?: Maybe<Settings_Var_Samp_Order_By>
  variance?: Maybe<Settings_Variance_Order_By>
}

/** input type for inserting array relation for remote table "settings" */
export type Settings_Arr_Rel_Insert_Input = {
  data: Array<Settings_Insert_Input>
  on_conflict?: Maybe<Settings_On_Conflict>
}

/** aggregate avg on columns */
export type Settings_Avg_Fields = {
  __typename?: 'settings_avg_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by avg() on columns of table "settings" */
export type Settings_Avg_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** Boolean expression to filter rows from the table "settings". All fields are combined with a logical 'AND'. */
export type Settings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Settings_Bool_Exp>>>
  _not?: Maybe<Settings_Bool_Exp>
  _or?: Maybe<Array<Maybe<Settings_Bool_Exp>>>
  account_id?: Maybe<String_Comparison_Exp>
  days_to_show?: Maybe<Int_Comparison_Exp>
  expand_worklogs?: Maybe<Boolean_Comparison_Exp>
  fte_percentage?: Maybe<Int_Comparison_Exp>
  show_timeline?: Maybe<Boolean_Comparison_Exp>
}

/** unique or primary key constraints on table "settings" */
export enum Settings_Constraint {
  /** unique or primary key constraint */
  SettingsPkey = 'settings_pkey',
}

/** input type for incrementing integer column in table "settings" */
export type Settings_Inc_Input = {
  days_to_show?: Maybe<Scalars['Int']>
  fte_percentage?: Maybe<Scalars['Int']>
}

/** input type for inserting data into table "settings" */
export type Settings_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  days_to_show?: Maybe<Scalars['Int']>
  expand_worklogs?: Maybe<Scalars['Boolean']>
  fte_percentage?: Maybe<Scalars['Int']>
  show_timeline?: Maybe<Scalars['Boolean']>
}

/** aggregate max on columns */
export type Settings_Max_Fields = {
  __typename?: 'settings_max_fields'
  account_id?: Maybe<Scalars['String']>
  days_to_show?: Maybe<Scalars['Int']>
  fte_percentage?: Maybe<Scalars['Int']>
}

/** order by max() on columns of table "settings" */
export type Settings_Max_Order_By = {
  account_id?: Maybe<Order_By>
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Settings_Min_Fields = {
  __typename?: 'settings_min_fields'
  account_id?: Maybe<Scalars['String']>
  days_to_show?: Maybe<Scalars['Int']>
  fte_percentage?: Maybe<Scalars['Int']>
}

/** order by min() on columns of table "settings" */
export type Settings_Min_Order_By = {
  account_id?: Maybe<Order_By>
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** response of any mutation on the table "settings" */
export type Settings_Mutation_Response = {
  __typename?: 'settings_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Settings>
}

/** input type for inserting object relation for remote table "settings" */
export type Settings_Obj_Rel_Insert_Input = {
  data: Settings_Insert_Input
  on_conflict?: Maybe<Settings_On_Conflict>
}

/** on conflict condition type for table "settings" */
export type Settings_On_Conflict = {
  constraint: Settings_Constraint
  update_columns: Array<Settings_Update_Column>
  where?: Maybe<Settings_Bool_Exp>
}

/** ordering options when selecting data from "settings" */
export type Settings_Order_By = {
  account_id?: Maybe<Order_By>
  days_to_show?: Maybe<Order_By>
  expand_worklogs?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
  show_timeline?: Maybe<Order_By>
}

/** primary key columns input for table: "settings" */
export type Settings_Pk_Columns_Input = {
  account_id: Scalars['String']
}

/** select columns of table "settings" */
export enum Settings_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  DaysToShow = 'days_to_show',
  /** column name */
  ExpandWorklogs = 'expand_worklogs',
  /** column name */
  FtePercentage = 'fte_percentage',
  /** column name */
  ShowTimeline = 'show_timeline',
}

/** input type for updating data in table "settings" */
export type Settings_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  days_to_show?: Maybe<Scalars['Int']>
  expand_worklogs?: Maybe<Scalars['Boolean']>
  fte_percentage?: Maybe<Scalars['Int']>
  show_timeline?: Maybe<Scalars['Boolean']>
}

/** aggregate stddev on columns */
export type Settings_Stddev_Fields = {
  __typename?: 'settings_stddev_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by stddev() on columns of table "settings" */
export type Settings_Stddev_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate stddev_pop on columns */
export type Settings_Stddev_Pop_Fields = {
  __typename?: 'settings_stddev_pop_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by stddev_pop() on columns of table "settings" */
export type Settings_Stddev_Pop_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate stddev_samp on columns */
export type Settings_Stddev_Samp_Fields = {
  __typename?: 'settings_stddev_samp_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by stddev_samp() on columns of table "settings" */
export type Settings_Stddev_Samp_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate sum on columns */
export type Settings_Sum_Fields = {
  __typename?: 'settings_sum_fields'
  days_to_show?: Maybe<Scalars['Int']>
  fte_percentage?: Maybe<Scalars['Int']>
}

/** order by sum() on columns of table "settings" */
export type Settings_Sum_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** update columns of table "settings" */
export enum Settings_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  DaysToShow = 'days_to_show',
  /** column name */
  ExpandWorklogs = 'expand_worklogs',
  /** column name */
  FtePercentage = 'fte_percentage',
  /** column name */
  ShowTimeline = 'show_timeline',
}

/** aggregate var_pop on columns */
export type Settings_Var_Pop_Fields = {
  __typename?: 'settings_var_pop_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by var_pop() on columns of table "settings" */
export type Settings_Var_Pop_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate var_samp on columns */
export type Settings_Var_Samp_Fields = {
  __typename?: 'settings_var_samp_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by var_samp() on columns of table "settings" */
export type Settings_Var_Samp_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** aggregate variance on columns */
export type Settings_Variance_Fields = {
  __typename?: 'settings_variance_fields'
  days_to_show?: Maybe<Scalars['Float']>
  fte_percentage?: Maybe<Scalars['Float']>
}

/** order by variance() on columns of table "settings" */
export type Settings_Variance_Order_By = {
  days_to_show?: Maybe<Order_By>
  fte_percentage?: Maybe<Order_By>
}

/** columns and relationships of "speed_dial" */
export type Speed_Dial = {
  __typename?: 'speed_dial'
  account_id: Scalars['String']
  created_at: Scalars['timestamptz']
  /** An object relationship */
  issue?: Maybe<Issues>
  issue_id: Scalars['String']
  key: Scalars['String']
  updated_at: Scalars['timestamptz']
}

/** aggregated selection of "speed_dial" */
export type Speed_Dial_Aggregate = {
  __typename?: 'speed_dial_aggregate'
  aggregate?: Maybe<Speed_Dial_Aggregate_Fields>
  nodes: Array<Speed_Dial>
}

/** aggregate fields of "speed_dial" */
export type Speed_Dial_Aggregate_Fields = {
  __typename?: 'speed_dial_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Speed_Dial_Max_Fields>
  min?: Maybe<Speed_Dial_Min_Fields>
}

/** aggregate fields of "speed_dial" */
export type Speed_Dial_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Speed_Dial_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "speed_dial" */
export type Speed_Dial_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Speed_Dial_Max_Order_By>
  min?: Maybe<Speed_Dial_Min_Order_By>
}

/** input type for inserting array relation for remote table "speed_dial" */
export type Speed_Dial_Arr_Rel_Insert_Input = {
  data: Array<Speed_Dial_Insert_Input>
  on_conflict?: Maybe<Speed_Dial_On_Conflict>
}

/** Boolean expression to filter rows from the table "speed_dial". All fields are combined with a logical 'AND'. */
export type Speed_Dial_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Speed_Dial_Bool_Exp>>>
  _not?: Maybe<Speed_Dial_Bool_Exp>
  _or?: Maybe<Array<Maybe<Speed_Dial_Bool_Exp>>>
  account_id?: Maybe<String_Comparison_Exp>
  created_at?: Maybe<Timestamptz_Comparison_Exp>
  issue?: Maybe<Issues_Bool_Exp>
  issue_id?: Maybe<String_Comparison_Exp>
  key?: Maybe<String_Comparison_Exp>
  updated_at?: Maybe<Timestamptz_Comparison_Exp>
}

/** unique or primary key constraints on table "speed_dial" */
export enum Speed_Dial_Constraint {
  /** unique or primary key constraint */
  SpeedDialPkey = 'speed_dial_pkey',
}

/** input type for inserting data into table "speed_dial" */
export type Speed_Dial_Insert_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue?: Maybe<Issues_Obj_Rel_Insert_Input>
  issue_id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** aggregate max on columns */
export type Speed_Dial_Max_Fields = {
  __typename?: 'speed_dial_max_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by max() on columns of table "speed_dial" */
export type Speed_Dial_Max_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Speed_Dial_Min_Fields = {
  __typename?: 'speed_dial_min_fields'
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** order by min() on columns of table "speed_dial" */
export type Speed_Dial_Min_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue_id?: Maybe<Order_By>
  key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** response of any mutation on the table "speed_dial" */
export type Speed_Dial_Mutation_Response = {
  __typename?: 'speed_dial_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Speed_Dial>
}

/** input type for inserting object relation for remote table "speed_dial" */
export type Speed_Dial_Obj_Rel_Insert_Input = {
  data: Speed_Dial_Insert_Input
  on_conflict?: Maybe<Speed_Dial_On_Conflict>
}

/** on conflict condition type for table "speed_dial" */
export type Speed_Dial_On_Conflict = {
  constraint: Speed_Dial_Constraint
  update_columns: Array<Speed_Dial_Update_Column>
  where?: Maybe<Speed_Dial_Bool_Exp>
}

/** ordering options when selecting data from "speed_dial" */
export type Speed_Dial_Order_By = {
  account_id?: Maybe<Order_By>
  created_at?: Maybe<Order_By>
  issue?: Maybe<Issues_Order_By>
  issue_id?: Maybe<Order_By>
  key?: Maybe<Order_By>
  updated_at?: Maybe<Order_By>
}

/** primary key columns input for table: "speed_dial" */
export type Speed_Dial_Pk_Columns_Input = {
  account_id: Scalars['String']
  key: Scalars['String']
}

/** select columns of table "speed_dial" */
export enum Speed_Dial_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "speed_dial" */
export type Speed_Dial_Set_Input = {
  account_id?: Maybe<Scalars['String']>
  created_at?: Maybe<Scalars['timestamptz']>
  issue_id?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  updated_at?: Maybe<Scalars['timestamptz']>
}

/** update columns of table "speed_dial" */
export enum Speed_Dial_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IssueId = 'issue_id',
  /** column name */
  Key = 'key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>
  _gt?: Maybe<Scalars['String']>
  _gte?: Maybe<Scalars['String']>
  _ilike?: Maybe<Scalars['String']>
  _in?: Maybe<Array<Scalars['String']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _like?: Maybe<Scalars['String']>
  _lt?: Maybe<Scalars['String']>
  _lte?: Maybe<Scalars['String']>
  _neq?: Maybe<Scalars['String']>
  _nilike?: Maybe<Scalars['String']>
  _nin?: Maybe<Array<Scalars['String']>>
  _nlike?: Maybe<Scalars['String']>
  _nsimilar?: Maybe<Scalars['String']>
  _similar?: Maybe<Scalars['String']>
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root'
  /** fetch data from the table: "accounts" */
  accounts: Array<Accounts>
  /** fetch aggregated fields from the table: "accounts" */
  accounts_aggregate: Accounts_Aggregate
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>
  /** fetch data from the table: "issues" */
  issues: Array<Issues>
  /** fetch aggregated fields from the table: "issues" */
  issues_aggregate: Issues_Aggregate
  /** fetch data from the table: "issues" using primary key columns */
  issues_by_pk?: Maybe<Issues>
  /** fetch data from the table: "points" */
  points: Array<Points>
  /** fetch aggregated fields from the table: "points" */
  points_aggregate: Points_Aggregate
  /** fetch data from the table: "points" using primary key columns */
  points_by_pk?: Maybe<Points>
  /** fetch data from the table: "sessions" */
  sessions: Array<Sessions>
  /** fetch aggregated fields from the table: "sessions" */
  sessions_aggregate: Sessions_Aggregate
  /** fetch data from the table: "sessions" using primary key columns */
  sessions_by_pk?: Maybe<Sessions>
  /** fetch data from the table: "settings" */
  settings: Array<Settings>
  /** fetch aggregated fields from the table: "settings" */
  settings_aggregate: Settings_Aggregate
  /** fetch data from the table: "settings" using primary key columns */
  settings_by_pk?: Maybe<Settings>
  /** fetch data from the table: "speed_dial" */
  speed_dial: Array<Speed_Dial>
  /** fetch aggregated fields from the table: "speed_dial" */
  speed_dial_aggregate: Speed_Dial_Aggregate
  /** fetch data from the table: "speed_dial" using primary key columns */
  speed_dial_by_pk?: Maybe<Speed_Dial>
  /** fetch data from the table: "tenants" */
  tenants: Array<Tenants>
  /** fetch aggregated fields from the table: "tenants" */
  tenants_aggregate: Tenants_Aggregate
  /** fetch data from the table: "tenants" using primary key columns */
  tenants_by_pk?: Maybe<Tenants>
}

/** subscription root */
export type Subscription_RootAccountsArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Accounts_Order_By>>
  where?: Maybe<Accounts_Bool_Exp>
}

/** subscription root */
export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Accounts_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Accounts_Order_By>>
  where?: Maybe<Accounts_Bool_Exp>
}

/** subscription root */
export type Subscription_RootAccounts_By_PkArgs = {
  account_id: Scalars['String']
}

/** subscription root */
export type Subscription_RootIssuesArgs = {
  distinct_on?: Maybe<Array<Issues_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Issues_Order_By>>
  where?: Maybe<Issues_Bool_Exp>
}

/** subscription root */
export type Subscription_RootIssues_AggregateArgs = {
  distinct_on?: Maybe<Array<Issues_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Issues_Order_By>>
  where?: Maybe<Issues_Bool_Exp>
}

/** subscription root */
export type Subscription_RootIssues_By_PkArgs = {
  account_id: Scalars['String']
  issue_id: Scalars['String']
}

/** subscription root */
export type Subscription_RootPointsArgs = {
  distinct_on?: Maybe<Array<Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Points_Order_By>>
  where?: Maybe<Points_Bool_Exp>
}

/** subscription root */
export type Subscription_RootPoints_AggregateArgs = {
  distinct_on?: Maybe<Array<Points_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Points_Order_By>>
  where?: Maybe<Points_Bool_Exp>
}

/** subscription root */
export type Subscription_RootPoints_By_PkArgs = {
  id: Scalars['uuid']
}

/** subscription root */
export type Subscription_RootSessionsArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Sessions_Order_By>>
  where?: Maybe<Sessions_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSessions_AggregateArgs = {
  distinct_on?: Maybe<Array<Sessions_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Sessions_Order_By>>
  where?: Maybe<Sessions_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSessions_By_PkArgs = {
  jira_access_token: Scalars['String']
}

/** subscription root */
export type Subscription_RootSettingsArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Settings_Order_By>>
  where?: Maybe<Settings_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSettings_AggregateArgs = {
  distinct_on?: Maybe<Array<Settings_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Settings_Order_By>>
  where?: Maybe<Settings_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSettings_By_PkArgs = {
  account_id: Scalars['String']
}

/** subscription root */
export type Subscription_RootSpeed_DialArgs = {
  distinct_on?: Maybe<Array<Speed_Dial_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Speed_Dial_Order_By>>
  where?: Maybe<Speed_Dial_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSpeed_Dial_AggregateArgs = {
  distinct_on?: Maybe<Array<Speed_Dial_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Speed_Dial_Order_By>>
  where?: Maybe<Speed_Dial_Bool_Exp>
}

/** subscription root */
export type Subscription_RootSpeed_Dial_By_PkArgs = {
  account_id: Scalars['String']
  key: Scalars['String']
}

/** subscription root */
export type Subscription_RootTenantsArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tenants_Order_By>>
  where?: Maybe<Tenants_Bool_Exp>
}

/** subscription root */
export type Subscription_RootTenants_AggregateArgs = {
  distinct_on?: Maybe<Array<Tenants_Select_Column>>
  limit?: Maybe<Scalars['Int']>
  offset?: Maybe<Scalars['Int']>
  order_by?: Maybe<Array<Tenants_Order_By>>
  where?: Maybe<Tenants_Bool_Exp>
}

/** subscription root */
export type Subscription_RootTenants_By_PkArgs = {
  tenant_id: Scalars['String']
}

/**
 * OAuth process for a new tenant https://developer.atlassian.com/server/jira/platform/oauth/
 *
 *
 * columns and relationships of "tenants"
 */
export type Tenants = {
  __typename?: 'tenants'
  /** base URL of tenant's jira */
  jira_domain: Scalars['String']
  /** the name of the key we saved to atlassian */
  jira_key: Scalars['String']
  /** private part of the RSA key pair encoded as base64 string */
  jira_private_key: Scalars['String']
  name: Scalars['String']
  tenant_id: Scalars['String']
}

/** aggregated selection of "tenants" */
export type Tenants_Aggregate = {
  __typename?: 'tenants_aggregate'
  aggregate?: Maybe<Tenants_Aggregate_Fields>
  nodes: Array<Tenants>
}

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_Fields = {
  __typename?: 'tenants_aggregate_fields'
  count?: Maybe<Scalars['Int']>
  max?: Maybe<Tenants_Max_Fields>
  min?: Maybe<Tenants_Min_Fields>
}

/** aggregate fields of "tenants" */
export type Tenants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tenants_Select_Column>>
  distinct?: Maybe<Scalars['Boolean']>
}

/** order by aggregate values of table "tenants" */
export type Tenants_Aggregate_Order_By = {
  count?: Maybe<Order_By>
  max?: Maybe<Tenants_Max_Order_By>
  min?: Maybe<Tenants_Min_Order_By>
}

/** input type for inserting array relation for remote table "tenants" */
export type Tenants_Arr_Rel_Insert_Input = {
  data: Array<Tenants_Insert_Input>
  on_conflict?: Maybe<Tenants_On_Conflict>
}

/** Boolean expression to filter rows from the table "tenants". All fields are combined with a logical 'AND'. */
export type Tenants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Tenants_Bool_Exp>>>
  _not?: Maybe<Tenants_Bool_Exp>
  _or?: Maybe<Array<Maybe<Tenants_Bool_Exp>>>
  jira_domain?: Maybe<String_Comparison_Exp>
  jira_key?: Maybe<String_Comparison_Exp>
  jira_private_key?: Maybe<String_Comparison_Exp>
  name?: Maybe<String_Comparison_Exp>
  tenant_id?: Maybe<String_Comparison_Exp>
}

/** unique or primary key constraints on table "tenants" */
export enum Tenants_Constraint {
  /** unique or primary key constraint */
  TenantsPkey = 'tenants_pkey',
  /** unique or primary key constraint */
  TenantsTenantIdKey = 'tenants_tenant_id_key',
}

/** input type for inserting data into table "tenants" */
export type Tenants_Insert_Input = {
  jira_domain?: Maybe<Scalars['String']>
  jira_key?: Maybe<Scalars['String']>
  jira_private_key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
}

/** aggregate max on columns */
export type Tenants_Max_Fields = {
  __typename?: 'tenants_max_fields'
  jira_domain?: Maybe<Scalars['String']>
  jira_key?: Maybe<Scalars['String']>
  jira_private_key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
}

/** order by max() on columns of table "tenants" */
export type Tenants_Max_Order_By = {
  jira_domain?: Maybe<Order_By>
  jira_key?: Maybe<Order_By>
  jira_private_key?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant_id?: Maybe<Order_By>
}

/** aggregate min on columns */
export type Tenants_Min_Fields = {
  __typename?: 'tenants_min_fields'
  jira_domain?: Maybe<Scalars['String']>
  jira_key?: Maybe<Scalars['String']>
  jira_private_key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
}

/** order by min() on columns of table "tenants" */
export type Tenants_Min_Order_By = {
  jira_domain?: Maybe<Order_By>
  jira_key?: Maybe<Order_By>
  jira_private_key?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant_id?: Maybe<Order_By>
}

/** response of any mutation on the table "tenants" */
export type Tenants_Mutation_Response = {
  __typename?: 'tenants_mutation_response'
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int']
  /** data of the affected rows by the mutation */
  returning: Array<Tenants>
}

/** input type for inserting object relation for remote table "tenants" */
export type Tenants_Obj_Rel_Insert_Input = {
  data: Tenants_Insert_Input
  on_conflict?: Maybe<Tenants_On_Conflict>
}

/** on conflict condition type for table "tenants" */
export type Tenants_On_Conflict = {
  constraint: Tenants_Constraint
  update_columns: Array<Tenants_Update_Column>
  where?: Maybe<Tenants_Bool_Exp>
}

/** ordering options when selecting data from "tenants" */
export type Tenants_Order_By = {
  jira_domain?: Maybe<Order_By>
  jira_key?: Maybe<Order_By>
  jira_private_key?: Maybe<Order_By>
  name?: Maybe<Order_By>
  tenant_id?: Maybe<Order_By>
}

/** primary key columns input for table: "tenants" */
export type Tenants_Pk_Columns_Input = {
  tenant_id: Scalars['String']
}

/** select columns of table "tenants" */
export enum Tenants_Select_Column {
  /** column name */
  JiraDomain = 'jira_domain',
  /** column name */
  JiraKey = 'jira_key',
  /** column name */
  JiraPrivateKey = 'jira_private_key',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
}

/** input type for updating data in table "tenants" */
export type Tenants_Set_Input = {
  jira_domain?: Maybe<Scalars['String']>
  jira_key?: Maybe<Scalars['String']>
  jira_private_key?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  tenant_id?: Maybe<Scalars['String']>
}

/** update columns of table "tenants" */
export enum Tenants_Update_Column {
  /** column name */
  JiraDomain = 'jira_domain',
  /** column name */
  JiraKey = 'jira_key',
  /** column name */
  JiraPrivateKey = 'jira_private_key',
  /** column name */
  Name = 'name',
  /** column name */
  TenantId = 'tenant_id',
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>
  _gt?: Maybe<Scalars['timestamptz']>
  _gte?: Maybe<Scalars['timestamptz']>
  _in?: Maybe<Array<Scalars['timestamptz']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['timestamptz']>
  _lte?: Maybe<Scalars['timestamptz']>
  _neq?: Maybe<Scalars['timestamptz']>
  _nin?: Maybe<Array<Scalars['timestamptz']>>
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>
  _gt?: Maybe<Scalars['uuid']>
  _gte?: Maybe<Scalars['uuid']>
  _in?: Maybe<Array<Scalars['uuid']>>
  _is_null?: Maybe<Scalars['Boolean']>
  _lt?: Maybe<Scalars['uuid']>
  _lte?: Maybe<Scalars['uuid']>
  _neq?: Maybe<Scalars['uuid']>
  _nin?: Maybe<Array<Scalars['uuid']>>
}

export type IssueDataFragment = { __typename?: 'issues' } & Pick<
  Issues,
  'title' | 'issue_id'
>

export type SpeedDialDataFragment = { __typename?: 'speed_dial' } & Pick<
  Speed_Dial,
  'key'
> & { issue?: Maybe<{ __typename?: 'issues' } & IssueDataFragment> }

export type SetSpeedDialMutationVariables = {
  key: Scalars['String']
  issue: Scalars['String']
}

export type SetSpeedDialMutation = { __typename?: 'mutation_root' } & {
  insert_speed_dial?: Maybe<
    { __typename?: 'speed_dial_mutation_response' } & {
      returning: Array<{ __typename?: 'speed_dial' } & SpeedDialDataFragment>
    }
  >
}

export type InsertSpeedDialMutationVariables = {
  speedDial: Array<Speed_Dial_Insert_Input>
}

export type InsertSpeedDialMutation = { __typename?: 'mutation_root' } & {
  insert_speed_dial?: Maybe<
    { __typename?: 'speed_dial_mutation_response' } & {
      returning: Array<{ __typename?: 'speed_dial' } & SpeedDialDataFragment>
    }
  >
}

export type InsertSettingsMutationVariables = {
  expandWorklogs?: Maybe<Scalars['Boolean']>
  showTimeline?: Maybe<Scalars['Boolean']>
  ftePercentage?: Maybe<Scalars['Int']>
  daysToShow?: Maybe<Scalars['Int']>
}

export type InsertSettingsMutation = { __typename?: 'mutation_root' } & {
  insert_settings?: Maybe<
    { __typename?: 'settings_mutation_response' } & Pick<
      Settings_Mutation_Response,
      'affected_rows'
    >
  >
}

export type UpsertPointsMutationVariables = {
  points: Array<Points_Insert_Input>
  pointIdsToRemove: Array<Scalars['uuid']>
}

export type UpsertPointsMutation = { __typename?: 'mutation_root' } & {
  delete_points?: Maybe<
    { __typename?: 'points_mutation_response' } & Pick<
      Points_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'points' } & Pick<Points, 'id'>> }
  >
  insert_points?: Maybe<
    { __typename?: 'points_mutation_response' } & Pick<
      Points_Mutation_Response,
      'affected_rows'
    > & { returning: Array<{ __typename?: 'points' } & Pick<Points, 'id'>> }
  >
}

export type IssuesQueryVariables = {}

export type IssuesQuery = { __typename?: 'query_root' } & {
  issues: Array<{ __typename?: 'issues' } & IssueDataFragment>
}

export type SpeedDialQueryVariables = {}

export type SpeedDialQuery = { __typename?: 'query_root' } & {
  speed_dial: Array<{ __typename?: 'speed_dial' } & SpeedDialDataFragment>
}

export type TenantsQueryVariables = {}

export type TenantsQuery = { __typename?: 'query_root' } & {
  tenants: Array<
    { __typename?: 'tenants' } & Pick<Tenants, 'tenant_id' | 'name'>
  >
}

export type GetSettingsQueryVariables = {
  accountId: Scalars['String']
}

export type GetSettingsQuery = { __typename?: 'query_root' } & {
  settings_by_pk?: Maybe<
    { __typename?: 'settings' } & Pick<
      Settings,
      'expand_worklogs' | 'show_timeline' | 'fte_percentage' | 'days_to_show'
    >
  >
}

export type PointsQueryVariables = {}

export type PointsQuery = { __typename?: 'query_root' } & {
  points: Array<
    { __typename?: 'points' } & Pick<
      Points,
      | 'description'
      | 'free_time'
      | 'id'
      | 'issue_id'
      | 'synced'
      | 'timestamp'
      | 'created_at'
      | 'jira_id'
    >
  >
}

export const IssueDataFragmentDoc = gql`
  fragment IssueData on issues {
    title
    issue_id
  }
`
export const SpeedDialDataFragmentDoc = gql`
  fragment SpeedDialData on speed_dial {
    key
    issue {
      ...IssueData
    }
  }
  ${IssueDataFragmentDoc}
`
export const SetSpeedDialDocument = gql`
  mutation SetSpeedDial($key: String!, $issue: String!) {
    insert_speed_dial(
      objects: { issue_id: $issue, key: $key }
      on_conflict: { constraint: speed_dial_pkey, update_columns: issue_id }
    ) {
      returning {
        ...SpeedDialData
      }
    }
  }
  ${SpeedDialDataFragmentDoc}
`
export type SetSpeedDialMutationFn = ApolloReactCommon.MutationFunction<
  SetSpeedDialMutation,
  SetSpeedDialMutationVariables
>

/**
 * __useSetSpeedDialMutation__
 *
 * To run a mutation, you first call `useSetSpeedDialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetSpeedDialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setSpeedDialMutation, { data, loading, error }] = useSetSpeedDialMutation({
 *   variables: {
 *      key: // value for 'key'
 *      issue: // value for 'issue'
 *   },
 * });
 */
export function useSetSpeedDialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetSpeedDialMutation,
    SetSpeedDialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetSpeedDialMutation,
    SetSpeedDialMutationVariables
  >(SetSpeedDialDocument, baseOptions)
}
export type SetSpeedDialMutationHookResult = ReturnType<
  typeof useSetSpeedDialMutation
>
export type SetSpeedDialMutationResult = ApolloReactCommon.MutationResult<
  SetSpeedDialMutation
>
export type SetSpeedDialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetSpeedDialMutation,
  SetSpeedDialMutationVariables
>
export const InsertSpeedDialDocument = gql`
  mutation InsertSpeedDial($speedDial: [speed_dial_insert_input!]!) {
    insert_speed_dial(
      objects: $speedDial
      on_conflict: { constraint: speed_dial_pkey, update_columns: issue_id }
    ) {
      returning {
        ...SpeedDialData
      }
    }
  }
  ${SpeedDialDataFragmentDoc}
`
export type InsertSpeedDialMutationFn = ApolloReactCommon.MutationFunction<
  InsertSpeedDialMutation,
  InsertSpeedDialMutationVariables
>

/**
 * __useInsertSpeedDialMutation__
 *
 * To run a mutation, you first call `useInsertSpeedDialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSpeedDialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSpeedDialMutation, { data, loading, error }] = useInsertSpeedDialMutation({
 *   variables: {
 *      speedDial: // value for 'speedDial'
 *   },
 * });
 */
export function useInsertSpeedDialMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InsertSpeedDialMutation,
    InsertSpeedDialMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    InsertSpeedDialMutation,
    InsertSpeedDialMutationVariables
  >(InsertSpeedDialDocument, baseOptions)
}
export type InsertSpeedDialMutationHookResult = ReturnType<
  typeof useInsertSpeedDialMutation
>
export type InsertSpeedDialMutationResult = ApolloReactCommon.MutationResult<
  InsertSpeedDialMutation
>
export type InsertSpeedDialMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InsertSpeedDialMutation,
  InsertSpeedDialMutationVariables
>
export const InsertSettingsDocument = gql`
  mutation InsertSettings(
    $expandWorklogs: Boolean
    $showTimeline: Boolean
    $ftePercentage: Int
    $daysToShow: Int
  ) {
    insert_settings(
      objects: {
        expand_worklogs: $expandWorklogs
        show_timeline: $showTimeline
        fte_percentage: $ftePercentage
        days_to_show: $daysToShow
      }
      on_conflict: {
        constraint: settings_pkey
        update_columns: [
          expand_worklogs
          show_timeline
          fte_percentage
          days_to_show
        ]
      }
    ) {
      affected_rows
    }
  }
`
export type InsertSettingsMutationFn = ApolloReactCommon.MutationFunction<
  InsertSettingsMutation,
  InsertSettingsMutationVariables
>

/**
 * __useInsertSettingsMutation__
 *
 * To run a mutation, you first call `useInsertSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSettingsMutation, { data, loading, error }] = useInsertSettingsMutation({
 *   variables: {
 *      expandWorklogs: // value for 'expandWorklogs'
 *      showTimeline: // value for 'showTimeline'
 *      ftePercentage: // value for 'ftePercentage'
 *      daysToShow: // value for 'daysToShow'
 *   },
 * });
 */
export function useInsertSettingsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    InsertSettingsMutation,
    InsertSettingsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    InsertSettingsMutation,
    InsertSettingsMutationVariables
  >(InsertSettingsDocument, baseOptions)
}
export type InsertSettingsMutationHookResult = ReturnType<
  typeof useInsertSettingsMutation
>
export type InsertSettingsMutationResult = ApolloReactCommon.MutationResult<
  InsertSettingsMutation
>
export type InsertSettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  InsertSettingsMutation,
  InsertSettingsMutationVariables
>
export const UpsertPointsDocument = gql`
  mutation UpsertPoints(
    $points: [points_insert_input!]!
    $pointIdsToRemove: [uuid!]!
  ) {
    delete_points(
      where: { synced: { _eq: false }, id: { _in: $pointIdsToRemove } }
    ) {
      affected_rows
      returning {
        id
      }
    }
    insert_points(
      on_conflict: {
        constraint: points_pkey
        update_columns: [
          timestamp
          description
          issue_id
          free_time
          synced
          jira_id
        ]
      }
      objects: $points
    ) {
      returning {
        id
      }
      affected_rows
    }
  }
`
export type UpsertPointsMutationFn = ApolloReactCommon.MutationFunction<
  UpsertPointsMutation,
  UpsertPointsMutationVariables
>

/**
 * __useUpsertPointsMutation__
 *
 * To run a mutation, you first call `useUpsertPointsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPointsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPointsMutation, { data, loading, error }] = useUpsertPointsMutation({
 *   variables: {
 *      points: // value for 'points'
 *      pointIdsToRemove: // value for 'pointIdsToRemove'
 *   },
 * });
 */
export function useUpsertPointsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpsertPointsMutation,
    UpsertPointsMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpsertPointsMutation,
    UpsertPointsMutationVariables
  >(UpsertPointsDocument, baseOptions)
}
export type UpsertPointsMutationHookResult = ReturnType<
  typeof useUpsertPointsMutation
>
export type UpsertPointsMutationResult = ApolloReactCommon.MutationResult<
  UpsertPointsMutation
>
export type UpsertPointsMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpsertPointsMutation,
  UpsertPointsMutationVariables
>
export const IssuesDocument = gql`
  query Issues {
    issues {
      ...IssueData
    }
  }
  ${IssueDataFragmentDoc}
`

/**
 * __useIssuesQuery__
 *
 * To run a query within a React component, call `useIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIssuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IssuesQuery,
    IssuesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IssuesQuery, IssuesQueryVariables>(
    IssuesDocument,
    baseOptions
  )
}
export function useIssuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IssuesQuery,
    IssuesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<IssuesQuery, IssuesQueryVariables>(
    IssuesDocument,
    baseOptions
  )
}
export type IssuesQueryHookResult = ReturnType<typeof useIssuesQuery>
export type IssuesLazyQueryHookResult = ReturnType<typeof useIssuesLazyQuery>
export type IssuesQueryResult = ApolloReactCommon.QueryResult<
  IssuesQuery,
  IssuesQueryVariables
>
export const SpeedDialDocument = gql`
  query SpeedDial {
    speed_dial(order_by: { key: asc }) {
      ...SpeedDialData
    }
  }
  ${SpeedDialDataFragmentDoc}
`

/**
 * __useSpeedDialQuery__
 *
 * To run a query within a React component, call `useSpeedDialQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpeedDialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpeedDialQuery({
 *   variables: {
 *   },
 * });
 */
export function useSpeedDialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SpeedDialQuery,
    SpeedDialQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SpeedDialQuery, SpeedDialQueryVariables>(
    SpeedDialDocument,
    baseOptions
  )
}
export function useSpeedDialLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SpeedDialQuery,
    SpeedDialQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SpeedDialQuery, SpeedDialQueryVariables>(
    SpeedDialDocument,
    baseOptions
  )
}
export type SpeedDialQueryHookResult = ReturnType<typeof useSpeedDialQuery>
export type SpeedDialLazyQueryHookResult = ReturnType<
  typeof useSpeedDialLazyQuery
>
export type SpeedDialQueryResult = ApolloReactCommon.QueryResult<
  SpeedDialQuery,
  SpeedDialQueryVariables
>
export const TenantsDocument = gql`
  query Tenants {
    tenants {
      tenant_id
      name
    }
  }
`

/**
 * __useTenantsQuery__
 *
 * To run a query within a React component, call `useTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    TenantsQuery,
    TenantsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<TenantsQuery, TenantsQueryVariables>(
    TenantsDocument,
    baseOptions
  )
}
export function useTenantsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TenantsQuery,
    TenantsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<TenantsQuery, TenantsQueryVariables>(
    TenantsDocument,
    baseOptions
  )
}
export type TenantsQueryHookResult = ReturnType<typeof useTenantsQuery>
export type TenantsLazyQueryHookResult = ReturnType<typeof useTenantsLazyQuery>
export type TenantsQueryResult = ApolloReactCommon.QueryResult<
  TenantsQuery,
  TenantsQueryVariables
>
export const GetSettingsDocument = gql`
  query GetSettings($accountId: String!) {
    settings_by_pk(account_id: $accountId) {
      expand_worklogs
      show_timeline
      fte_percentage
      days_to_show
    }
  }
`

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    baseOptions
  )
}
export function useGetSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >(GetSettingsDocument, baseOptions)
}
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>
export type GetSettingsLazyQueryHookResult = ReturnType<
  typeof useGetSettingsLazyQuery
>
export type GetSettingsQueryResult = ApolloReactCommon.QueryResult<
  GetSettingsQuery,
  GetSettingsQueryVariables
>
export const PointsDocument = gql`
  query Points {
    points(order_by: { timestamp: asc, created_at: asc }) {
      description
      free_time
      id
      issue_id
      synced
      timestamp
      created_at
      jira_id
    }
  }
`

/**
 * __usePointsQuery__
 *
 * To run a query within a React component, call `usePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePointsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePointsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PointsQuery,
    PointsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PointsQuery, PointsQueryVariables>(
    PointsDocument,
    baseOptions
  )
}
export function usePointsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PointsQuery,
    PointsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PointsQuery, PointsQueryVariables>(
    PointsDocument,
    baseOptions
  )
}
export type PointsQueryHookResult = ReturnType<typeof usePointsQuery>
export type PointsLazyQueryHookResult = ReturnType<typeof usePointsLazyQuery>
export type PointsQueryResult = ApolloReactCommon.QueryResult<
  PointsQuery,
  PointsQueryVariables
>
