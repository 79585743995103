import moment from 'moment'
import { createSelector } from 'reselect'

import { DateTimeFormat } from '../../consts'
import { State } from '../../store/state'
import { eventsByDateSelector } from '../eventsSelectors'

const displayedDateSelector = (state: State) => state.displayedDate

export const dateEventsSelector = createSelector(
  [eventsByDateSelector, displayedDateSelector],
  (events, date) =>
    events[moment(date).startOf('day').format(DateTimeFormat.LONG_DATE)]
)
