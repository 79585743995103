import React from 'react'

import { DialogVariant } from '../../consts'
import { useSelector } from '../../hooks/useSelector'

import { JoinIntervalsDialog } from './JoinIntervalsDialog'

const DialogContainer = () => {
  const dialog = useSelector((state) => state.dialog)

  if (!dialog) return null

  return (
    <div onKeyDown={(e) => e.stopPropagation()}>
      {dialog.type === DialogVariant.JOIN_INTERVALS && <JoinIntervalsDialog />}
    </div>
  )
}

export default DialogContainer
