import React from 'react'

import {
  makeStyles,
  Tooltip,
  Button,
  IconButton,
  Popover,
  Typography,
} from '@material-ui/core'
import { Undo, Redo, Warning, CalendarToday } from '@material-ui/icons'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import classnames from 'classnames'
import { isWeekend, parseISO } from 'date-fns'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { undo, redo } from '../../actions/historyActions'
import {
  switchMode,
  moveDisplayedDateAndCursor,
  setDisplayedDateAndCursor,
} from '../../actions/trackerActions'
import { Mode, DateTimeFormat, ComponentHeights } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { formatTime, SECONDS_IN_WORKING_DAY } from '../../utils'
import { Timeline } from '../Timeline'
import {
  secondsAssignedSelector,
  isDaySavedSelector,
} from '../timelineSelectors'

import { HeaderCalendar } from './HeaderCalendar'
import {
  disabledRedoSelector,
  disabledUndoSelector,
  disabledMoveToNextDaySelector,
  isEveryWorklogAssignedSelector,
} from './worklogsHeaderSelectors'

const useStyles = makeStyles((theme) => ({
  header: {
    height: `${ComponentHeights.WORKLOGS_HEADER}`,
    padding: theme.spacing(0, 3),
    boxSizing: 'border-box',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  headerWrapper: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: { width: '100%', textAlign: 'center' },
  moveToTodayIcon: {
    marginRight: theme.spacing(-1.5),
  },
  typographyRoot: {
    marginRight: theme.spacing(1),
  },
  secondsSaved: {
    textAlign: 'right',
    padding: theme.spacing(2),
  },
  lastItem: {
    width: '300px',
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    padding: theme.spacing(1),
  },
  calendarContainer: {
    width: '30px',
    height: '30px',
    margin: 'auto',
    position: 'relative',
  },
  unsavedDay: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: theme.palette.secondary.main,
    right: '55%',
    top: '35%',
  },
  displayedDate: {
    display: 'inline-block',
    textAlign: 'center',
    width: '250px',
  },
}))

export const WorklogsHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const displayedDate = useSelector((state) => state.displayedDate)
  const secondsAssigned = useSelector(secondsAssignedSelector)
  const isDaySaved = useSelector(isDaySavedSelector)
  const mode = useSelector((state) => state.mode)
  const undoDisabled = useSelector(disabledUndoSelector)
  const redoDisabled = useSelector(disabledRedoSelector)
  const disabledMoveToNextDay = useSelector(disabledMoveToNextDaySelector)
  const { showTimeline, ftePercentage } = useSelector((state) => state.settings)
  const isEveryWorklogAssigned = useSelector(isEveryWorklogAssignedSelector)

  const formattedDate = moment(displayedDate)
    .startOf('day')
    .format(DateTimeFormat.LONG_DATE)

  const undoButton = (
    <IconButton onClick={() => dispatch(undo())} disabled={undoDisabled}>
      <Undo className="icon-button" />
    </IconButton>
  )
  const redoButton = (
    <IconButton onClick={() => dispatch(redo())} disabled={redoDisabled}>
      <Redo className="icon-button" />
    </IconButton>
  )

  const removeExcessZeros = (formattedTime: string) =>
    formattedTime.endsWith('00min')
      ? formattedTime.split(' ')[0]
      : formattedTime

  const moveToPrevDay = (
    <IconButton
      size="small"
      onClick={() => dispatch(moveDisplayedDateAndCursor(-1))}
    >
      <ArrowBackIosIcon fontSize="small" />
    </IconButton>
  )
  const moveToNextDay = (
    <IconButton
      size="small"
      disabled={disabledMoveToNextDay}
      onClick={() => dispatch(moveDisplayedDateAndCursor(1))}
    >
      <ArrowForwardIosIcon fontSize="small" />
    </IconButton>
  )

  const moveToToday = (
    <IconButton
      size="small"
      disabled={disabledMoveToNextDay}
      onClick={() =>
        dispatch(setDisplayedDateAndCursor(new Date().toISOString()))
      }
    >
      <ArrowForwardIosIcon
        fontSize="small"
        className={classes.moveToTodayIcon}
      />
      <ArrowForwardIosIcon fontSize="small" />
    </IconButton>
  )

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'header-calendar' : undefined

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.header}>
        {mode === Mode.SAVING ? (
          <div className={classes.container}>
            <Typography
              variant="subtitle2"
              component="h2"
              className={classes.title}
            >
              Review worklogs
            </Typography>
          </div>
        ) : (
          <div className={classes.container}>
            <div className={classes.wrapper}>
              {undoDisabled ? (
                undoButton
              ) : (
                <Tooltip title={'Undo'} placement="top">
                  {undoButton}
                </Tooltip>
              )}
              {redoDisabled ? (
                redoButton
              ) : (
                <Tooltip title={'Redo'} placement="top">
                  {redoButton}
                </Tooltip>
              )}
            </div>
            <div className={classnames(classes.wrapper, 'tutorial-changeDate')}>
              <Tooltip title={'Move to the previous day'} placement="top">
                {moveToPrevDay}
              </Tooltip>
              <IconButton aria-describedby={id} onClick={handleClick}>
                <div className={classes.calendarContainer}>
                  {isEveryWorklogAssigned ? (
                    <CalendarToday fontSize={'small'} />
                  ) : (
                    <>
                      <CalendarToday fontSize={'small'} />
                      <div className={classes.unsavedDay} />
                    </>
                  )}
                </div>
              </IconButton>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <HeaderCalendar />
              </Popover>

              <div className={classes.displayedDate}>
                <Typography
                  classes={{ root: classes.typographyRoot }}
                  variant="subtitle2"
                  component="h2"
                >
                  {formattedDate}
                </Typography>
              </div>
              {disabledMoveToNextDay ? (
                moveToNextDay
              ) : (
                <Tooltip title={'Move to the next day'} placement="top">
                  {moveToNextDay}
                </Tooltip>
              )}
              {disabledMoveToNextDay ? (
                moveToToday
              ) : (
                <Tooltip title={'Move to today'} placement="top">
                  {moveToToday}
                </Tooltip>
              )}
            </div>
            <div className={classnames(classes.wrapper, classes.lastItem)}>
              {!isDaySaved && (
                <Tooltip title={'You have unsaved worklogs'} placement="top">
                  <Warning fontSize="small" />
                </Tooltip>
              )}
              <div className={classes.secondsSaved}>
                <strong>
                  {secondsAssigned
                    ? `${removeExcessZeros(formatTime(secondsAssigned))}`
                    : '0h'}
                  {!isWeekend(parseISO(displayedDate))
                    ? ` / ${removeExcessZeros(
                        formatTime(
                          Math.floor(
                            (SECONDS_IN_WORKING_DAY * ftePercentage) / 100
                          )
                        )
                      )}`
                    : '/0h'}
                </strong>
              </div>
              <div
                className={classnames(
                  classes.btnWrapper,
                  'tutorial-sidebarSave'
                )}
              >
                <Tooltip title={'Review and save worklogs'} placement="top">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="tutorial-sidebarSave"
                    data-cy="submit-button"
                    onClick={() => dispatch(switchMode(Mode.SAVING))}
                  >
                    Submit
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>
        )}
      </div>
      {mode !== Mode.SAVING && showTimeline && <Timeline />}
    </div>
  )
}
