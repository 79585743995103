import { Action } from '../types/reduxTypes'

import getInitialState, { State } from './state'

export const rootReducer = (
  state: State = getInitialState(),
  action: Action
) => {
  const { reducer } = action
  // fallback for 3rd-party actions
  if (!reducer) return state
  return reducer(state)
}
