import React from 'react'

import {
  Tooltip,
  IconButton,
  makeStyles,
  Typography,
  Slide,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import classnames from 'classnames'
import lodash from 'lodash'
import { useDispatch } from 'react-redux'

import { toggleShortcuts } from '../../actions/trackerActions'
import { Mode, ComponentHeights, ComponentWidths } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { keyBindings, SET_SPEED_DIAL } from '../keyBindingsUtils'

type CreateStylesProps = {
  mode: Mode
  shortcutsShown: boolean
}

const useStyles = makeStyles((theme) => ({
  shortcuts: {
    backgroundColor: ({ shortcutsShown, mode }: CreateStylesProps) =>
      shortcutsShown && mode === Mode.LOGGING ? 'white' : 'initial',
    zIndex: ({ shortcutsShown, mode }: CreateStylesProps) =>
      shortcutsShown && mode === Mode.LOGGING ? 3 : 1,
    maxWidth: `${ComponentWidths.LEFT_SIDEBAR}`,
    borderRight: `1px solid ${theme.palette.border.light}`,
    boxSizing: 'border-box',
  },
  icon: {
    position: 'relative',
    top: '-1px',
    padding: theme.spacing(1),
  },
  shortcutsHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: ({ shortcutsShown }: CreateStylesProps) =>
      shortcutsShown ? 'row' : 'column',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    maxHeight: '68px',
  },
  shortcutsTitle: {
    lineHeight: '36px',
    paddingRight: theme.spacing(2),
  },
  shortcutsContainer: {
    overflowY: 'auto',
    maxHeight: `calc(100% - ${ComponentHeights.SHORTCUTS_HEADER})`,
    padding: theme.spacing(0, 3),
  },
  shortcutsCategory: {
    paddingBottom: theme.spacing(3),
  },
  categoryTitle: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '12px',
  },
  categoryContainer: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  shortcut: {
    borderBottom: `1px solid ${theme.palette.border.main}`,
    padding: '12px 0',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  keys: {
    whiteSpace: 'nowrap',
  },
  key: {
    margin: 0,
    '& + &': {
      marginLeft: theme.spacing(1) / 2,
    },
  },
  message: {
    fontWeight: 400,
    lineHeight: '22px',
    padding: '0 6px',
  },
  note: {
    border: 'none',
    textAlign: 'right',
    fontSize: 14,
    paddingBottom: theme.spacing(3),
    color: theme.palette.text.primary,
  },
}))

const checkIfMac = () => window.navigator.userAgent.includes('Mac')

const keyLabels = {
  arrowup: '↑',
  arrowdown: '↓',
  arrowleft: '←',
  arrowright: '→',

  upDescription: '↑',
  downDescription: '↓',

  upTime: '↑',
  downTime: '↓',

  escape: 'ESC',
  ' ': 'SPACE',
}

const mapKeyToLabel = (key: string) => keyLabels[key] || key

const mapPathToSpecialKeys = (path: string): string[] => {
  return (
    ['ctrl', 'alt', 'shift']
      .map(
        (key) =>
          path.toLowerCase().includes(key) &&
          (key === 'ctrl' && checkIfMac() ? '⌘' : key.toUpperCase())
      )
      // TS https://github.com/microsoft/TypeScript/pull/29955
      // @ts-ignore
      .filter<string>(Boolean)
  )
}

export const Shortcuts = () => {
  const dispatch = useDispatch()
  const mode = useSelector((state) => state.mode)
  const shortcutsShown = useSelector((state) => state.shortcutsShown)
  const classes = useStyles({ mode, shortcutsShown })
  const shortcuts = lodash(
    Object.values(keyBindings).map((bindings: any) =>
      Object.keys(bindings).map((path) => {
        const keys = mapPathToSpecialKeys(path)
        return Object.entries(bindings[path])
          .filter((entry: any) => entry[1].category && entry[1].description)
          .map((entry: any) => ({
            keys: [...keys, mapKeyToLabel(entry[0])],
            message: entry[1].description,
            category: entry[1].category,
          }))
      })
    )
  )
    .flattenDeep()
    .groupBy('category')
    .value()

  return (
    <Slide in={shortcutsShown} direction="right" unmountOnExit mountOnEnter>
      <div className={classes.shortcuts}>
        <header className={classes.shortcutsHeader}>
          <Tooltip title="Hide shortcuts">
            <IconButton
              className={classes.icon}
              onClick={() => dispatch(toggleShortcuts())}
            >
              <Close fontSize="small" />
            </IconButton>
          </Tooltip>
          <Typography variant="subtitle2" className={classes.shortcutsTitle}>
            Shortcuts for FineTracker
          </Typography>
          <kbd className={classes.key}>f1</kbd>
        </header>
        <div className={classes.shortcutsContainer}>
          {Object.keys(shortcuts).map((category) => (
            <div className={classes.shortcutsCategory} key={category}>
              <Typography variant="subtitle2" className={classes.categoryTitle}>
                {category}
              </Typography>
              <div className={classes.categoryContainer}>
                {shortcuts[category].map((shortcut, index) => (
                  <div
                    key={index}
                    className={classnames(
                      classes.shortcut,
                      shortcut.message === SET_SPEED_DIAL &&
                        'tutorial-setSpeedDial'
                    )}
                  >
                    <Typography variant="subtitle2" className={classes.message}>
                      {shortcut.message}
                    </Typography>
                    <div className={classes.keys}>
                      {shortcut.keys.map((key) => (
                        <kbd key={key} className={classes.key}>
                          {key}
                        </kbd>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <aside className={classes.note}>
            <small>Shortcuts are case insensitive.</small>
          </aside>
        </div>
      </div>
    </Slide>
  )
}
