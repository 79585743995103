import { set, update } from '@siegrift/tsfunct'

import { MessageType } from '../consts'
import { SyncStatus } from '../store/state'
import { Action, Thunk } from '../types/reduxTypes'

export const deactivateUser = (): Action => ({
  type: `Set user active status`,
  payload: null,
  reducer: (state) => {
    const { user } = state
    if (!user) return state

    return set(state, ['user'], {
      ...user,
      isActive: false,
    })
  },
})

export const displayMessage = (body: string[], type: MessageType): Action => ({
  type: 'Display message',
  payload: { body, type },
  reducer: (state) =>
    set(state, ['message'], { body, type, isDisplayed: true }),
})

export const hideMessage = (): Action => ({
  type: 'Hide message',
  payload: undefined,
  reducer: (state) =>
    update(state, ['message'], (message) => ({
      ...message,
      isDisplayed: false,
    })),
})

export const updateDbSyncStatus = (
  newStatus: Partial<SyncStatus>
): Thunk<void> => (dispatch) => {
  if (newStatus.error) {
    dispatch(displayMessage([newStatus.error], MessageType.ERROR))
  }
  dispatch(_updateDbSyncStatus(newStatus))
}

const _updateDbSyncStatus = (newStatus: Partial<SyncStatus>): Action => ({
  type: 'Change sync status',
  payload: newStatus,
  reducer: (state) =>
    update(state, ['syncStatus'], (status) => ({ ...status, ...newStatus })),
})
