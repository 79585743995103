import { set } from '@siegrift/tsfunct'

import { State } from '../store/state'
import { Action } from '../types/reduxTypes'

type UpdateValueOptions = {
  undoable?: boolean | ((previous: State, next: State) => boolean)
}

// TODO: remove usage of this functions as it is very hard to type
// instead use strongly typed update/set functions from tsfunct library
export const updateValue = (
  path: any,
  data: any,
  type: string,
  options?: UpdateValueOptions
): Action => ({
  type,
  payload: data,
  undoable: options?.undoable,
  reducer: (state) => set(state, path, data),
})
