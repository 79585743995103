import React, { useEffect, useCallback, useMemo, useState } from 'react'

import * as Sentry from '@sentry/browser'
import { isAfter, subDays, startOfDay } from 'date-fns'
import ReactGA from 'react-ga'
import { useDispatch, useStore } from 'react-redux'
import useFetch, { CachePolicies } from 'use-http'

import { updateValue } from '../actions/genericActions'
import { showCursor } from '../actions/trackerActions'
import { updateDbSyncStatus } from '../actions/utilActions'
import {
  useUpsertPointsMutation,
  useSpeedDialLazyQuery,
  usePointsLazyQuery,
} from '../generated/graphqlSdk'
import {
  useSetTutorialEnabled,
  useSetTutorialStartTime,
} from '../hooks/tutorialHooks'
import { useSelector } from '../hooks/useSelector'
import { Point, State } from '../store/state'
import { allIssuesObjSelector } from '../tracker/issuesSelectors'
import { createTutorialSteps } from '../tracker/Tutorial/tutorialSteps'
import { mapPoints } from '../tracker/utils'

import { useInterval } from './useInterval'

interface UseFetchDataProps {
  fetch: {
    path: string
    headers?: {
      credentials: string
    }
  }
  onData?: Array<(data: any) => void>
  errorComponent?: React.ReactNode
  polling?: number
  timeout?: number
}

const addParameterToURL = (url, paramKey, paramValue) =>
  url + (url.split('?')[1] ? '&' : '?') + paramKey + '=' + paramValue

//TODO: utilize this library instead https://swr.now.sh/#custom-data-fetching.
export const useFetchData = (options: UseFetchDataProps) => {
  const defaultTimeout = 2 * 60 * 1000
  const [fetched, setFetched] = useState(false)
  const [timestamp, setTimestamp] = useState(new Date().getTime().toString())
  const url = useMemo(
    () => addParameterToURL(options.fetch.path, 'timestamp', timestamp),
    [options.fetch.path, timestamp]
  )

  const { get, data, loading, error } = useFetch(
    url,
    {
      headers: options.fetch.headers || {},
      timeout: options.timeout || defaultTimeout,
      cachePolicy: CachePolicies.NO_CACHE,
    },
    []
  )

  useEffect(() => {
    if (options.onData && !loading && !error && !fetched) {
      options.onData.forEach((fn) => fn(data))
      setFetched(true)
    }
  }, [data, loading, options.onData, error, fetched])

  useInterval(() => {
    if (options.polling) {
      setFetched(false)
      setTimestamp(new Date().getTime().toString())
      get()
    }
  }, options.polling || null)

  return { data, loading: loading || !fetched, error }
}

export const useDispatchFetchedData = () => {
  const dispatch = useDispatch()

  return useCallback(
    (data: any, path: string, ref?: string) =>
      dispatch(updateValue([path], data, `Received data: ${ref || path}`)),
    [dispatch]
  )
}

export const useResetTutorial = () => {
  const tutorial = useSelector((state) => state.tutorial)

  const dispatch = useDispatch()
  const setTutorialStartTime = useSetTutorialStartTime()
  const setTutorialEnabled = useSetTutorialEnabled()
  const store = useStore()

  return useCallback(() => {
    if (!tutorial.enabled) return

    const steps = createTutorialSteps(tutorial.startTime, setTutorialStartTime)
    steps[tutorial.index].afterStep?.(() => store.getState(), dispatch)

    setTutorialEnabled(false)
  }, [dispatch, setTutorialEnabled, setTutorialStartTime, store, tutorial])
}

// TODO: investigate hasura/apollo offline options
export const useFetchPoints = () => {
  const dispatch = useDispatch()
  const daysToShow = useSelector((state) => state.settings.daysToShow)

  const [fetchPoints, { data, loading, error, refetch }] = usePointsLazyQuery()

  useEffect(() => {
    // if daysToShow change, we need to refetch the points, because users might have modified
    // them since they were last queried. This hook will only be triggered if the data is changed
    // (meaning, it can be called from inside the hook).
    //
    // NOTE: after the if, we still render the stale 'data' and it will flick, when the new ones
    // are fetched and this hook is triggered again.
    if (refetch) {
      refetch()
    }

    if (!loading && !error && data) {
      const daysToShowTresholdDate = startOfDay(
        subDays(Date.now(), daysToShow - 1) // -1 because of today
      )

      //TODO: keep this in apollo cache only
      const dbPoints = data.points.map((p) => ({
        synced: p.synced,
        freeTime: p.free_time,
        marked: false,
        id: p.id,
        description: p.description,
        timestamp: p.timestamp,
        issue: p.issue_id,
        jiraId: p.jira_id,
      }))

      /**
       * This is a hack to boost the performance. You can set the number of days to show
       * in the settings. Older worklogs will be hidden and you will be blocked from
       * changing the history by not being able to move to move to the older days.
       *
       * There is a possibility that the last worklog to be hidden was synced and
       * the time of the first shown point shouldn't be modified. In order to prevent this
       * we include the last hidden point in the state (but you can't see it from the app -
       * apart from description history).
       *
       * TODO: The performance issues should be generally solved with better state model -
       * a lot of FT actions operate on whole points array, despite needing just the worklogs
       * from a single day - unfortunately, this would require a large refactor.
       */
      let firstPointIndex = dbPoints.findIndex((point) =>
        isAfter(new Date(point.timestamp), daysToShowTresholdDate)
      )
      if (firstPointIndex === -1) firstPointIndex = dbPoints.length
      // take the last point if there is some
      firstPointIndex = Math.max(firstPointIndex - 1, 0)
      const slicedPoints: Point[] = dbPoints.slice(firstPointIndex)

      dispatch(updateValue(['points'], slicedPoints, 'Set initial DB points'))
      dispatch(showCursor())
    }
    dispatch(
      updateDbSyncStatus({
        loading,
        success: !error && !loading,
        error: error?.message,
      })
    )
  }, [data, loading, error, dispatch, daysToShow, refetch])

  return { fetchPoints, loading }
}

export const useValidateIssues = () => {
  const store = useStore<State>()
  const state = store.getState()
  const dispatch = useDispatch()

  const { points } = state
  const issues = useMemo(() => allIssuesObjSelector(state), [state])

  const validatedPoints = useMemo(
    () =>
      points.map((point) =>
        (point.issue && issues[point.issue]) || point.synced
          ? point
          : {
              ...point,
              issue: null,
            }
      ),
    [issues, points]
  )

  const [updateValidatedPoints] = useUpsertPointsMutation()
  const [fetchSpeedDial] = useSpeedDialLazyQuery()

  return useCallback(async () => {
    fetchSpeedDial()

    try {
      await updateValidatedPoints({
        variables: {
          points: mapPoints(validatedPoints),
          pointIdsToRemove: [],
        },
      })
      dispatch(
        updateValue(['points'], validatedPoints, 'Update validated points')
      )
    } catch (e) {
      dispatch(
        updateDbSyncStatus({
          error: e?.message,
        })
      )
    }
  }, [dispatch, fetchSpeedDial, updateValidatedPoints, validatedPoints])
}

export const useInitUserAnalytics = () => {
  return useCallback((data: any) => {
    if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.setUser({ id: data.accountId, username: data.name })
    }

    ReactGA.set({ userId: data.accountId })
    ReactGA.event({
      category: 'Login',
      action: 'User login',
      label: data.name,
    })
  }, [])
}
