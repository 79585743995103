import { createSelector } from 'reselect'

import { State } from '../store/state'
import { touchesSyncedInterval } from '../utils'

import { selectionSelector } from './selectionSelector'

const pointsSelector = (state: State) => state.points

export const firstSelectedPointSelector = createSelector(
  [pointsSelector, selectionSelector],
  (points, selection) =>
    selection.length && points[selection[0]]
      ? {
          ...points[selection[0]],
          touchesSynced: touchesSyncedInterval(points)(selection[0]),
        }
      : null
)
