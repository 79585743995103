import React from 'react'

import * as Sentry from '@sentry/browser'
import Promise from 'bluebird'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'

import { client } from './apollo/client'
import Root from './Root'
import { configureStore } from './store/configureStore'

// report any uncaught exceptions triggered in the application
// setup from:
// https://github.com/getsentry/sentry-docs/blob/master/src/collections/_documentation/platforms/javascript/getting-started-dsn/react.md
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_VERSION,
  })
}

// TODO: TS fix, see https://github.com/DefinitelyTyped/DefinitelyTyped/issues/11027
// @ts-ignore
window.Promise = Promise

const gaId = process.env.REACT_APP_GA_TRACKING_ID || ''
if (!gaId && process.env.NODE_ENV === 'development') {
  throw new Error('GA tracking ID missing')
}
ReactGA.initialize(gaId)
ReactGA.pageview('/')

const store = configureStore(client)

const rootElement = document.getElementById('root') as any

ReactDOM.render(<Root store={store} client={client} />, rootElement)

// This is still required even with CRA - see
// https://vacuum.atlassian.net/browse/FINE-112
if (module.hot) {
  module.hot.accept('./Root', () => {
    const NewRoot = require('./Root').default
    ReactDOM.render(<NewRoot store={store} client={client} />, rootElement)
  })
}
