import React, { useState } from 'react'

import { Button, makeStyles } from '@material-ui/core'
import Spinner from 'react-spinkit'

const useStyles = makeStyles({
  loader: {
    height: '22px',
    display: 'flex',
    placeItems: 'center',
    transform: 'scaleY(0.5)',
  },
})

type LoginProps = {
  tenant: string
  text: string
}

export const LoginButton = ({ tenant, text }: LoginProps) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  return (
    <Button
      color="primary"
      variant="contained"
      href={`/auth/login?tenant=${tenant}`}
      onClick={() => setLoading(true)}
    >
      {loading ? (
        <Spinner
          fadeIn="quarter"
          name="line-scale-pulse-out"
          color="#fff"
          className={classes.loader}
        />
      ) : (
        text
      )}
    </Button>
  )
}
