import React, { useState } from 'react'

import { makeStyles, Dialog, Tooltip, Grid, Button } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import classnames from 'classnames'
import { find } from 'lodash'
import { useDispatch } from 'react-redux'

import { worklogsForbiddenToBeSubmittedSelector } from '../../actions/submittableWorkLogsSelector'
import { submitWorklogs, switchMode } from '../../actions/trackerActions'
import Loading from '../../components/Loading'
import { Mode } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import * as styles from '../WorkLogs/styles'

import LoggedTimeSummary from './LoggedTimeSummary'

const useStyles = makeStyles((theme) => ({
  worklogs: {
    ...styles.worklogs,
    width: '100%',
    height: '100%',
    maxWidth: 678,
    margin: `${theme.spacing(3)}px auto ${theme.spacing(6)}px`,
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.palette.boxShadow.review,
    borderRadius: 4,
  },
  btnGroup: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 2),
    borderTop: `1px solid ${theme.palette.border.main}`,
  },
  btnWrapper: {
    padding: theme.spacing(1),
  },
  btn: {
    height: '32px',
    lineHeight: '31px',
    textTransform: 'none',
    borderRadius: '2px',
    padding: theme.spacing(0, 3),
  },
}))

// TODO: move to its own file, better type for error
type ShowStatusDialogStatus = { loading: boolean; error: any; success: boolean }
type ShowStatusDialogProps = {
  status: ShowStatusDialogStatus
}

const ShowStatusDialog = ({
  status: { loading, error, success },
}: ShowStatusDialogProps) => (
  <Dialog open>
    <span>
      {/* TODO: add color prop for Loading ?*/}
      {(loading && <Loading />) ||
        (error && <ErrorIcon color="primary" />) ||
        (success && <Check color="primary" />)}
      {error && (
        <span style={{ marginLeft: '5px', fontWeight: 'bold' }}>{error}</span>
      )}
    </span>
  </Dialog>
)

export const WorklogsToSave = () => {
  const classes = useStyles()
  //TODO: TS-FIX
  const dispatch: any = useDispatch()

  const issues = useSelector((state) => state.issues)
  const forbiddenWorklogs = useSelector(worklogsForbiddenToBeSubmittedSelector)
  const tutorialEnabled = useSelector((state) => state.tutorial.enabled)
  const [status, setStatus] = useState<ShowStatusDialogStatus>({
    loading: false,
    error: false,
    success: false,
  })

  const handleSubmit = async () => {
    // if you hold enter in tutorial you are able to submit the worklogs
    if (tutorialEnabled) return

    setStatus({ ...status, loading: true })
    const submitStatus = await dispatch(submitWorklogs())
    setStatus({ ...submitStatus, loading: false })
    setTimeout(
      () =>
        submitStatus.error
          ? setStatus({ ...status, error: false })
          : dispatch(switchMode(Mode.LOGGING)),
      submitStatus.error ? 5000 : 1000
    )
  }

  const submitDisabled = forbiddenWorklogs.length > 0

  return (
    <div className={classnames(classes.worklogs, 'tutorial-worklogsToSubmit')}>
      {issues.length < 1 ? (
        <Loading />
      ) : (
        <>
          {find(status) && <ShowStatusDialog status={status} />}
          <LoggedTimeSummary />
          <Grid
            container
            alignItems="center"
            justify="flex-end"
            className={classes.btnGroup}
          >
            <div className={classes.btnWrapper}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.btn}
                data-cy="save-back-button"
                onClick={() => dispatch(switchMode(Mode.LOGGING))}
              >
                Back
              </Button>
            </div>
            <div
              className={classnames(
                classes.btnWrapper,
                'tutorial-worklogsToSave'
              )}
            >
              <Tooltip
                title={
                  submitDisabled
                    ? "You can't submit worklogs into an already closed month(s). Please mark them as free time before submitting."
                    : 'Save to Jira'
                }
                placement="top"
              >
                <span>
                  <Button
                    disabled={submitDisabled}
                    autoFocus
                    variant="contained"
                    color="primary"
                    disableElevation
                    aria-label="Submit worklogs"
                    data-cy="save-jira-submit"
                    className={classes.btn}
                    onClick={handleSubmit}
                  >
                    Save to JIRA
                  </Button>
                </span>
              </Tooltip>
            </div>
          </Grid>
        </>
      )}
    </div>
  )
}
