import { update, set } from '@siegrift/tsfunct'
import {
  isToday,
  isFuture,
  startOfDay,
  addDays,
  isSameDay,
  parseISO,
  subDays,
  isBefore,
  isAfter,
} from 'date-fns'
import { clamp, uniq, some, findLastIndex } from 'lodash'
import moment from 'moment'
import ReactGA from 'react-ga'
import { batch } from 'react-redux'

import { displayMessage, updateDbSyncStatus } from '../actions/utilActions'
import { Mode, Focus, DialogVariant, Input, MessageType } from '../consts'
import {
  SpeedDialDocument,
  SpeedDialQuery,
  UpsertPointsDocument,
} from '../generated/graphqlSdk'
import { getInitialHistoryState, FocusState, Settings } from '../store/state'
import { allIssuesObjSelector } from '../tracker/issuesSelectors'
import { selectionSelector } from '../tracker/selectionSelector'
import { formatIssueLabel, mapPoints } from '../tracker/utils'
import { Action, Thunk } from '../types/reduxTypes'
import { getNewWorklogTimestamp } from '../utils'

import {
  copyWorklogs,
  editPoints,
  deletePoints,
  joinIntervals,
} from './editPointsActions'
import { updateValue } from './genericActions'
import { modifyLogger, setModifiedFlag } from './loggerActions'
import {
  submittableWorkLogsSelector,
  freeTimeLogsSelector,
} from './submittableWorkLogsSelector'

export const toggleShortcuts = (): Action => ({
  type: `Toggle shortcuts`,
  payload: null,
  reducer: (state) =>
    update(
      state,
      ['shortcutsShown'],
      (shortcutsShown: boolean) => !shortcutsShown
    ),
})

export const showShortcuts = (showShortcuts: boolean): Action => ({
  type: `Show or hide shortcuts`,
  payload: { showShortcuts },
  reducer: (state) => set(state, ['shortcutsShown'], showShortcuts),
})

const setJiraIssue = (indices: number[], issue?: string): Action => ({
  type: 'Set Jira issue',
  payload: { indices, issue },
  undoable: true,
  reducer: (state) =>
    update(state, ['points'], (points) =>
      points.map((point, i) =>
        indices.includes(i) && point.description
          ? { ...point, issue, freeTime: false }
          : point
      )
    ),
})

const toggleIsPointFreeTime = (indices: number[]): Action => ({
  type: 'Toggle point as free time',
  payload: { indices },
  undoable: true,
  reducer: (state) =>
    update(state, ['points'], (points) =>
      points.map((point, i) =>
        indices.includes(i)
          ? { ...point, freeTime: !point.freeTime, issue: null }
          : point
      )
    ),
})

export const toggleFreeTime = (indices: number[]): Thunk<void> => (
  dispatch,
  getState
) => {
  dispatch(toggleIsPointFreeTime(indices))
  dispatch(updatePointsInHasura(indices))
}

export const toggleMarkedPoint = (index: number): Action => ({
  type: '(Un)Mark point',
  payload: index,
  reducer: (state) =>
    update(state, ['points', index, 'marked'], (marked) => !marked),
})

export const saveRecentIssue = (issue: string): Action => ({
  type: 'Save recent issue',
  payload: issue,
  reducer: (state) =>
    update(state, ['recentIssues'], (recentIssuesState) =>
      uniq([issue, ...recentIssuesState])
    ),
})

interface SubmittedPoint {
  /** Our internal id (index) of the point */
  id: number
  /**
   * Worklog id in jira.
   */
  jiraId: string | null
}

export const closeDialog = (): Action => ({
  type: `Close dialog`,
  payload: null,
  reducer: (state) => set(state, ['dialog'], null),
})

export const setCursor = (index: number): Action => ({
  type: 'Set cursor position',
  payload: index,
  reducer: (state) => set(state, ['cursor'], index),
})

export const setCursorAndIssueInput = (index: number): Thunk<void> => (
  dispatch,
  getState,
  { logger }
) => {
  batch(() => {
    logger.log('Thunk - Set cursor and issue input')
    dispatch(setCursor(index))

    const allIssues = allIssuesObjSelector(getState())
    const point = getState().points[index]
    const minDate = getState().minimalDate

    if (point && isAfter(new Date(point.timestamp), minDate)) {
      const selectedIssue = formatIssueLabel(allIssues, point.issue)
      dispatch(setDisplayedDate(point.timestamp))
      dispatch(modifyLogger(Input.ISSUE, selectedIssue))
    }
  })
}

/**
 * Handle scroll speed / inertia / acceleration
 *
 * This is more a hack than a good solution
 */

const createMoveCursor = () => {
  // does not support acceleration now
  // moving is not in state, so it does not further degrade
  // rendering performance - also reason we use closure
  let moving = false
  //to avoid ignoring fast clicks
  window.addEventListener('keyup', () => (moving = false))
  // for how long (ms) are cursor events ignored
  // should probably be average reaction time -> 250ms -> that
  // feels like its too much
  // also, the lower bound is depended on store update time (up to
  // 200ms on my machine, with average ~70ms)
  // If set to 0 -> cursor moves as fast as possible
  const minStopTime = 0

  const moveCursor = (increment: number): Thunk<void> => (
    dispatch,
    getState
  ) => {
    const { cursor, points } = getState()

    if (moving) {
      return
    }

    moving = true
    // update duration metrics for smoother scrolling
    const updateStart = new Date().getTime()
    const newCursor = clamp(cursor + increment, 0, points.length - 1)
    if (
      moment(points[newCursor].timestamp).isSame(
        points[cursor]?.timestamp,
        'day'
      )
    ) {
      // dispatch cant be in setTimeout, or we would get lag
      dispatch(setCursorAndIssueInput(newCursor))
    }
    const updateDuration = new Date().getTime() - updateStart
    const correctedStopTime =
      minStopTime -
      (updateDuration > minStopTime ? minStopTime : updateDuration)

    setTimeout(() => {
      moving = false
    }, correctedStopTime)
  }

  return moveCursor
}

export const moveCursor = createMoveCursor()

export const setDisplayedDate = (date: string): Action => ({
  type: 'Set displayed date',
  payload: date,
  reducer: (state) => set(state, ['displayedDate'], date),
})

/**
 * If cursor is hidden (-1) this displays it at the last worklog in current day
 */
export const showCursor = (): Action => ({
  type: 'Show cursor',
  payload: null,
  reducer: (state) => {
    const { cursor, displayedDate, points } = state

    if (cursor === -1) {
      const date = parseISO(displayedDate)
      const newIndex = findLastIndex(points, (p) =>
        isSameDay(parseISO(p.timestamp), date)
      )
      return set(state, ['cursor'], newIndex)
    }

    return state
  },
})

export const setDisplayedDateAndCursor = (
  date: string,
  endOfDay?: boolean
): Thunk<void> => (dispatch, getState, { logger }) => {
  logger.log('Thunk - Set displayed date and cursor')
  const { points } = getState()

  const parsedDate = parseISO(date)
  if (isFuture(startOfDay(parsedDate))) return
  const minDate = getState().minimalDate
  if (isBefore(parsedDate, minDate)) {
    dispatch(
      displayMessage(
        ['Unable to show worklogs. Check "days to show" in settings.'],
        MessageType.ERROR
      )
    )
    return
  }
  dispatch(setDisplayedDate(date))

  dispatch(
    modifyLogger(Input.TIMESTAMP, getNewWorklogTimestamp(points, parsedDate))
  )
  dispatch(setModifiedFlag(Input.TIMESTAMP, !isToday(parsedDate)))

  const newPosition = endOfDay
    ? findLastIndex(points, (point) =>
        isSameDay(parsedDate, parseISO(point.timestamp))
      )
    : points.findIndex((point) =>
        isSameDay(parsedDate, parseISO(point.timestamp))
      )
  dispatch(setCursorAndIssueInput(newPosition))
}

export const moveDisplayedDateAndCursor = (increment: number): Thunk<void> => (
  dispatch,
  getState
) => {
  const { displayedDate } = getState()

  const newDate = addDays(parseISO(displayedDate), increment).toISOString()

  dispatch(setDisplayedDateAndCursor(newDate, increment < 0))
}

export const clearMarked = (): Action => ({
  type: `Clear marked worklogs`,
  payload: null,
  reducer: (state) =>
    update(state, ['points'], (points) =>
      points.map((p) => ({ ...p, marked: false }))
    ),
})

export const switchMode = (mode: Mode): Action => ({
  type: `Change mode to ${mode}`,
  payload: mode,
  reducer: (state) => {
    // TODO: this should probably be handled otherwise (e.g. allow saving mode, but in saving scren show that user is inactive)
    if (mode === Mode.SAVING && state.user?.isActive === false) {
      return state
    }
    return set(state, ['mode'], mode)
  },
})

export const openDialog = (
  dialogVariant: DialogVariant,
  payload?: any
): Action => ({
  type: 'Open dialog',
  payload: payload,
  reducer: (state) => set(state, ['dialog'], { type: dialogVariant, payload }),
})

export const setFocus = (focus: FocusState): Action => ({
  type: `Focus on ${focus.focusType}`,
  payload: focus,
  reducer: (state) => set(state, ['focus'], focus),
})

export const clearFocus = (): Action => ({
  type: 'Clear Focus',
  payload: null,
  reducer: (state) => set(state, ['focus'], undefined),
})

const setJiraIssueFromSpeedDial = (
  indices: number[],
  index: string
): Thunk<void> => (dispatch, getState, { client, logger }) => {
  logger.log('Thunk - Set Jira issue from speed dial')

  const speedDial = client.readQuery<SpeedDialQuery>({
    query: SpeedDialDocument,
  })?.speed_dial
  const issue = speedDial?.find((option) => option.key === index)?.issue
  if (issue) {
    dispatch(setJiraIssueAndInput(indices, issue.issue_id))
  } else {
    dispatch(setFocus({ focusType: Focus.ISSUE, payload: { index } }))
  }
}

const setJiraIssueAndInput = (
  indices: number[],
  issueKey?: string
): Thunk<void> => (dispatch, getState, { logger }) => {
  logger.log('Thunk - Set Jira issue and issue input')

  const allIssues = allIssuesObjSelector(getState())
  const selectedIssue = formatIssueLabel(allIssues, issueKey)

  dispatch(setJiraIssue(indices, issueKey))
  dispatch(modifyLogger(Input.ISSUE, selectedIssue))

  dispatch(updatePointsInHasura(indices))
}

/**
 * Updates points in our DB specified by points indices.
 * Optionally, marks the points specified by the indices as synced.
 *
 * If the function fails, the points will not be backed up in DB (and might already be submitted to
 * Jira).
 */
export const updatePointsInHasura = (
  indices: number[],
  markedPointsAsSynced = false,
  pointIdsToRemove: string[] = []
): Thunk<void> => async (dispatch, getState, { logger, client }) => {
  logger.log('Thunk - Update points in DB', {
    indices,
    markedPointsAsSynced,
    pointIdsToRemove,
  })

  const setPointsAsSynced = (indices: number[], syncValue = true): Action => ({
    type: 'Set point as synced',
    payload: indices,
    reducer: (state) => {
      return update(state, ['points'], (pointsState) =>
        pointsState.map((p, ind) =>
          indices.includes(ind) ? { ...p, synced: syncValue } : p
        )
      )
    },
  })

  try {
    dispatch(updateDbSyncStatus({ loading: true }))
    if (markedPointsAsSynced) dispatch(setPointsAsSynced(indices))

    const { points } = getState()
    const pointsToUpdate = points.filter((p, ind) => indices.includes(ind))
    await client.mutate({
      mutation: UpsertPointsDocument,
      variables: { points: mapPoints(pointsToUpdate), pointIdsToRemove },
    })

    dispatch(updateDbSyncStatus({ loading: false, success: true }))
  } catch (e) {
    // revert the sync on points
    if (markedPointsAsSynced) dispatch(setPointsAsSynced(indices, false))
    dispatch(
      updateDbSyncStatus({
        loading: false,
        success: false,
        error: e.message,
      })
    )
  }
}

type SubmitWorklogsResponse = { error: string | false; success: boolean }

interface SubmitWorklogApiResponse {
  id: number
  // https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-issue-worklogs/#api-rest-api-3-issue-issueidorkey-worklog-post
  response: {
    // there are many other properties, but we only need the id of the worklog to unsync it.
    id: string
  }
}

export const submitWorklogs = (): Thunk<
  Promise<SubmitWorklogsResponse>
> => async (dispatch, getState, { logger }) => {
  logger.log('Thunk - Submit worklogs')
  const state = getState()

  const freeTimeIds = freeTimeLogsSelector(state)
  dispatch(
    updatePointsInHasura(
      freeTimeIds.map((id) => id.id),
      true
    )
  )

  const worklogs = submittableWorkLogsSelector(state)
  const promises = worklogs.map(async (worklog) => {
    try {
      // if there is a jiraId for this worklog and the point is unsynced. It means that
      // the mutation which updates the points failed. In this case, skip jira request and
      // try resyncing the points in hasura.
      if (worklog.jiraId) {
        return Promise.resolve({
          id: worklog.id,
          response: { id: worklog.jiraId },
        } as SubmitWorklogApiResponse)
      }

      const response = await fetch('/api/submit', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify(worklog),
      })
      if (!response.ok) return null

      const data: SubmitWorklogApiResponse = await response.json()
      return data
    } catch (e) {
      return null
    }
  })

  const successful = (await Promise.all(promises)).filter(
    (res): res is SubmitWorklogApiResponse => res !== null && res.id >= 0
  )
  const submittedWorklogs = successful.map(({ id, response }) => ({
    id,
    jiraId: response.id,
  }))
  const syncedIds = submittedWorklogs.map((w) => w.id)

  batch(() => {
    dispatch(setJiraIdsOfSubmittedWorklogs(submittedWorklogs))
    dispatch(updatePointsInHasura(syncedIds, true))
    dispatch(clearMarked())
    dispatch(
      updateValue(['history'], getInitialHistoryState(), 'Clear History')
    )
  })

  const error =
    syncedIds.length < worklogs.length &&
    `Something went wrong. Logged ${syncedIds.length} / ${worklogs.length} logs. Please try again.`

  !error &&
    ReactGA.event({
      category: 'Worklogs',
      action: 'Submitted worklogs to JIRA',
      value: syncedIds.length,
    })
  return {
    error,
    success: !error,
  }
}

const setJiraIdsOfSubmittedWorklogs = (
  submittedPoints: SubmittedPoint[]
): Action => ({
  type: `Set jira issue of submitted worklogs`,
  payload: submittedPoints,
  reducer: (state) =>
    update(state, ['points'], (points) => {
      const newPoints = [...points]
      submittedPoints.forEach((p) => {
        newPoints[p.id].jiraId = p.jiraId
      })

      return newPoints
    }),
})

// TODO: TS types
const makeSelectionAction = (actionCreator, worksWithSyncedPoints = false) => (
  ...args: any[]
): Thunk<void> => (dispatch, getState, { logger }) => {
  batch(() => {
    logger.log('Thunk - makeSelectionAction')

    const state = getState()
    const { points } = state
    const selection = selectionSelector(state)
    if (
      !worksWithSyncedPoints &&
      some(selection, (index) => points[index].synced)
    ) {
      dispatch(
        displayMessage(
          ['Cannot apply on synced worklog(s).'],
          MessageType.ERROR
        )
      )
      return
    }

    dispatch(actionCreator(selection, ...args))
    dispatch(clearMarked())
  })
}

export const deleteSelection = makeSelectionAction(deletePoints)

export const copySelectedWorklogs = makeSelectionAction(copyWorklogs, true)
export const editPointForSelection = makeSelectionAction(editPoints)
export const joinSelectedIntervals = makeSelectionAction(joinIntervals)
export const setJiraIssueForSelection = makeSelectionAction(
  setJiraIssueAndInput
)
export const setJiraIssueFromSpeedDialForSelection = makeSelectionAction(
  setJiraIssueFromSpeedDial
)
export const toggleIsFreeTimeForSelection = makeSelectionAction(toggleFreeTime)

export const toggleWorklogsDetail = (value?: boolean): Action => ({
  type: `${value === undefined ? 'Toggle' : 'Set'} worklogs details`,
  payload: value,
  reducer: (state) =>
    update(
      state,
      ['settings', 'expandWorklogs'],
      (expanded) => value ?? !expanded
    ),
})

export const setSettings = (value: Partial<Settings>): Action => ({
  type: `Update settings`,
  payload: value,
  reducer: (state) =>
    set(state, ['settings'], {
      ...state.settings,
      ...value,
    }),
})

export const setTutorialInDropdown = (value: boolean): Action => ({
  type: `Set tutorial button in dropdown`,
  payload: value,
  reducer: (state) => set(state, ['tutorialInDropdown'], value),
})

export const unsyncPointAtCursor = (): Thunk<void> => async (
  dispatch,
  getState,
  { logger }
) => {
  logger.log(`Unsync point`)

  const cursor = getState().cursor
  const toUnsync = getState().points[cursor]
  // this action can be triggered by keyboard even for points that can't be unsynced
  if (!toUnsync || !(toUnsync.jiraId || toUnsync.freeTime)) {
    return
  }

  try {
    const skipJiraRequest = !toUnsync.jiraId || !toUnsync.issue
    if (!skipJiraRequest) {
      const response = await fetch('/api/unsync', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: { 'Content-Type': 'application/json; charset=utf-8' },
        redirect: 'follow',
        referrer: 'no-referrer',
        body: JSON.stringify({ id: toUnsync.jiraId, issue: toUnsync.issue }),
      })
      if (!response.ok) throw new Error('Removing worklog from jira failed!')
    }
  } catch (e) {
    dispatch(displayMessage([e.message], MessageType.ERROR))
    // TODO: this could be called when jira request was previously successfull
    // but request to hasura failed. We could check if worklog exists in jira to be sure the
    // this is the cause of error and we could resync in hasura.
  }

  dispatch({
    type: 'Mark point as unsynced',
    payload: undefined,
    reducer: (state) =>
      update(state, ['points', cursor], (p) => ({
        ...p,
        jiraId: null,
        synced: false,
      })),
  })

  dispatch(updatePointsInHasura([cursor]))
}

export const setMinimalDate = (): Action => ({
  type: 'Set minimal date',
  payload: undefined,
  reducer: (state) => {
    const minDate = startOfDay(
      subDays(new Date(), state.settings.daysToShow - 1)
    )
    return set(state, ['minimalDate'], minDate)
  },
})
