import React from 'react'

import { makeStyles, Typography, Tooltip } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import classNames from 'classnames'
import { map } from 'lodash'

import { useSelector } from '../../hooks/useSelector'

import * as styles from './styles'

const useStyles = makeStyles((theme) => ({
  container: {
    ...styles.container,
    padding: theme.spacing(0, 3, 3),
    position: 'relative',
    top: theme.spacing(-3),
  },
  issueOptions: {
    border: `1px solid ${theme.palette.border.light}`,
    borderTop: 0,
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    boxShadow: theme.palette.boxShadow.issueOptions,
    overflowY: 'auto',
  },
  recentTitle: {
    display: 'block',
    fontSize: '12px',
    padding: '13px',
    color: theme.palette.text.secondary,
  },
  item: {
    ...styles.item,
    position: 'relative',
    '& + &::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 12,
      right: 12,
      height: 1,
      backgroundColor: theme.palette.border.main,
    },
  },
  recentItem: {
    backgroundColor: 'red',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 13px',
  },
  itemTitle: {
    ...styles.itemTitle,
    display: 'block',
    width: '240px',
  },
  itemTitleRecent: {
    width: '210px',
  },
  itemId: {
    ...styles.itemId,
    color: theme.palette.text.secondary,
  },
  itemCursor: {
    backgroundColor: theme.palette.worklog.selected,
  },
  icon: {
    color: theme.palette.text.secondary,
  },
}))

type Props = {
  handleIssueClick: (value: string) => void
}

const IssueOptions = ({ handleIssueClick }: Props) => {
  const classes = useStyles()
  const cursor = useSelector((state) => state.logger.issueOptionsCursor)
  const issueOptions = useSelector((state) => state.logger.issueOptions)
  const recentIssues = useSelector((state) => state.recentIssues)

  return (
    <div className={classes.container} data-cy="issue-options">
      <div className={classes.issueOptions}>
        {issueOptions.length === 0 && (
          <span className={classes.recentTitle}>No results</span>
        )}
        {map(issueOptions, (issue, index) => (
          <div
            key={issue.key}
            className={classNames(
              classes.item,
              index === cursor && classes.itemCursor
            )}
            onMouseDown={() => handleIssueClick(issue.key)}
            ref={(ref) =>
              cursor === index &&
              ref &&
              ref.scrollIntoView({ block: 'nearest' })
            }
          >
            <div className={classes.wrapper}>
              <div>
                <span
                  className={classNames(
                    classes.itemTitle,
                    recentIssues.includes(issue.key) && classes.itemTitleRecent
                  )}
                >
                  {issue.title}
                </span>
                <Typography variant="overline" className={classes.itemId}>
                  {issue.key}
                </Typography>
              </div>
              {recentIssues.includes(issue.key) && (
                <Tooltip title="Recent search">
                  <AccessTime fontSize="small" className={classes.icon} />
                </Tooltip>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
export default IssueOptions
