import React from 'react'

import { makeStyles } from '@material-ui/core'

import { useSelector } from '../../hooks/useSelector'
import { getCursorByDate } from '../utils'

import { dateEventsSelector } from './dateEventsSelector'
import * as styles from './styles'
import { WorkLog } from './WorkLog'

const useStyles = makeStyles({
  worklogs: styles.worklogs,
  container: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  worklogList: {
    marginBottom: 'auto',
  },
})

export const WorkLogs = () => {
  const classes = useStyles()
  const dateEvents = useSelector(dateEventsSelector)
  const points = useSelector((state) => state.points)
  const tutorialStartTime = useSelector((state) => state.tutorial.startTime)

  return (
    <div className={classes.worklogs}>
      <div className={classes.container}>
        <div className={classes.worklogList} data-cy="worklog-list">
          {dateEvents?.map((event, index) => (
            <WorkLog
              key={index}
              event={event}
              usedForTutorial={
                event.index === getCursorByDate(points, tutorialStartTime)
              }
            />
          ))}
        </div>
      </div>
    </div>
  )
}
