import {
  addMinutes,
  setMinutes,
  setHours,
  parseISO,
  isSameDay,
  isEqual,
} from 'date-fns'
import { findLastIndex } from 'lodash'

import { TIME_REG_EXP, HOURS_REG_EXP } from '../consts'
import { Point } from '../store/state'
import { roundNumber } from '../utils'

import { AllIssuesObj } from './issuesSelectors'

export const formatIssueLabel = (
  allIssuesObj: AllIssuesObj,
  issueKey?: string | null
) => {
  if (!issueKey) return ''
  if (!allIssuesObj[issueKey]) return issueKey
  return `${issueKey}: ${allIssuesObj[issueKey].title}`
}

export const getCursorByDate = (points: Point[], date: string) =>
  findLastIndex(points, (point) =>
    isEqual(parseISO(date), parseISO(point.timestamp))
  )

const STEP = 15
const ARROWS = {
  ArrowUp: {
    method: 'ceil',
    step: STEP,
  },
  ArrowDown: {
    method: 'floor',
    step: -STEP,
  },
  j: {
    method: 'ceil',
    step: STEP,
  },
  k: {
    method: 'floor',
    step: -STEP,
  },
}
const timeAdjustmentKeys = Object.keys(ARROWS)
export const adjustTime = (
  e: React.KeyboardEvent<HTMLInputElement>,
  timestamp: string
) => {
  if (!timeAdjustmentKeys.includes(e.key)) return null

  const input = (e.target as HTMLInputElement).value
  if (!TIME_REG_EXP.test(input) && !HOURS_REG_EXP.test(input)) return null

  const momentaryValue = HOURS_REG_EXP.test(input)
    ? setMinutes(
        setHours(parseISO(timestamp), parseInt(input.split(':')[0])),
        0
      )
    : undefined

  const dateValue = momentaryValue || new Date(timestamp)
  const rounded = roundNumber(
    dateValue.getMinutes(),
    ARROWS[e.key].method,
    STEP
  )
  const newValue =
    dateValue.getMinutes() === rounded
      ? addMinutes(dateValue, ARROWS[e.key].step)
      : setMinutes(dateValue, rounded)

  if (!isSameDay(newValue, parseISO(timestamp))) return null
  return newValue.toISOString()
}

export const mapPoints = (points: Point[]) => {
  return points.map((p) => ({
    id: p.id,
    description: p.description,
    timestamp: p.timestamp,
    free_time: p.freeTime,
    issue_id: p.issue,
    synced: p.synced,
    jira_id: p.jiraId,
  }))
}
