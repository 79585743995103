import * as Sentry from '@sentry/browser'
import ApolloClient, { NormalizedCache, InMemoryCache } from 'apollo-boost'

enum ErrorCodes {
  INVALID_JWT = 'invalid-jwt',
}

export const client = new ApolloClient<NormalizedCache>({
  uri: process.env.REACT_APP_GQL_ENDPOINT,
  request: (operation) => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    })
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        switch (error.extensions?.code) {
          case ErrorCodes.INVALID_JWT:
            if (process.env.NODE_ENV === 'development') {
              console.warn('JWT authentication failed, token removed.')
            }
            localStorage.removeItem('token')
            break
          default:
            if (process.env.NODE_ENV === 'development') {
              //Errors should be generally handled when getting the data, this is simply additional information
              //https://github.com/vacuumlabs/fine-tracker/pull/531#discussion_r425737187
              console.warn(`[GraphQL eror]: ${error.message}`)
            } else if (process.env.REACT_APP_SENTRY_DSN) {
              Sentry.captureException(error)
            }
            break
        }
      })
    }
    if (networkError) {
      if (process.env.NODE_ENV === 'development')
        console.warn(`[Apollo network eror]: ${networkError}`)
    }
  },
  //TODO: improve cache setup
  cache: new InMemoryCache(),
})

export type TrackerApolloClient = typeof client
