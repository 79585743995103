import { update } from '@siegrift/tsfunct'
import { groupBy, map } from 'lodash'
import { createSelector } from 'reselect'

import {
  submittableWorkLogsSelector,
  Worklog,
} from '../../actions/submittableWorkLogsSelector'
import { State } from '../../store/state'
import { formatTime } from '../../utils'
import { allIssuesObjSelector } from '../issuesSelectors'
import { formatIssueLabel } from '../utils'

const getProjectKey = (issueKey: string) =>
  issueKey.substring(0, issueKey.indexOf('-'))

export type LoggedTimeByIssue = {
  projectIssueKey: string
  projectIssueTitle: string
  projectIssueTime: string
  projectIssueWorklogs: Worklog[]
}

const secondsByIssueKeySelector = createSelector(
  submittableWorkLogsSelector,
  (worklogs): Record<string, number> =>
    worklogs.reduce(
      (acc: Record<string, number>, worklog) =>
        update(
          acc,
          [worklog.issue],
          (time: number) => (isNaN(time) ? 0 : time) + worklog.timeSpentSeconds
        ),
      {}
    )
)

export const totalLoggedTimeSelector = createSelector(
  secondsByIssueKeySelector,
  (secondsByIssueKey) =>
    formatTime(
      Object.values(secondsByIssueKey).reduce(
        (acc, seconds) => acc + seconds,
        0
      )
    )
)

export const loggedTimeByProjectSelector = createSelector(
  [submittableWorkLogsSelector, allIssuesObjSelector],
  (worklogs, allIssuesObj) =>
    map(
      groupBy(worklogs, (worklog) => getProjectKey(worklog.issue)),
      (projectWorklogs, projectKey) => ({
        projectKey,
        projectTime: formatTime(
          projectWorklogs.reduce(
            (acc, issue) => acc + issue.timeSpentSeconds,
            0
          )
        ),
        projectIssues: map(
          groupBy(projectWorklogs, (projectWorklog) => projectWorklog.issue),
          (projectIssueWorklogs, projectIssueKey) => ({
            projectIssueKey,
            projectIssueTitle: formatIssueLabel(allIssuesObj, projectIssueKey),
            projectIssueTime: formatTime(
              projectIssueWorklogs.reduce(
                (acc, issue) => acc + issue.timeSpentSeconds,
                0
              )
            ),
            projectIssueWorklogs,
          })
        ),
      })
    )
)

export const totalNumberOfWorkLogsSelector = createSelector(
  loggedTimeByProjectSelector,
  (timeByProjects) =>
    timeByProjects.reduce(
      (acc, project) => (acc += project.projectIssues.length),
      0
    )
)

export const expandWorklogsSelector = (state: State) =>
  state.settings.expandWorklogs
