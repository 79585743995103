import { createStore, applyMiddleware, Middleware } from 'redux'
import { save, load } from 'redux-localstorage-simple'
import { createLogger } from 'redux-logger'
import thunk, { ThunkAction } from 'redux-thunk'

import { TrackerApolloClient } from '../apollo/client'
import { Action, ThunkExtra, Logger, LoggerFn } from '../types/reduxTypes'

import history from './history'
import logActionToGA from './logActionToGA'
import { rootReducer } from './rootReducer'
import getInitialState, { State } from './state'

// we still keep some personal info in localstorage and not in hasura
const localStorageConfig = {
  namespace: 'fine-logger',
  states: [
    'recentIssues',
    'tutorialInDropdown',
    'tutorial' /* TODO: should tutorial be here? */,
  ],
}

export const configureStore = (client: TrackerApolloClient) => {
  const isDevelop = process.env.NODE_ENV === 'development'
  const developLog: LoggerFn = (message, payload) =>
    store.dispatch({
      type: message,
      payload,
      reducer: (x) => x,
    } as Action)

  const logger: Logger = {
    log: isDevelop ? developLog : () => {},
  }

  const loggerMiddleware = createLogger({
    collapsed: true,
    predicate: (_getState: () => State, action: Action) => !action.doNotLog,
  })

  const middlewares: Middleware[] = [
    thunk.withExtraArgument({ logger, client }),
    save(localStorageConfig),
    history,
  ]

  if (isDevelop) {
    middlewares.push(loggerMiddleware)
  } else {
    middlewares.push(logActionToGA)
  }

  const state = load({
    ...localStorageConfig,
    preloadedState: getInitialState(),
  }) as State

  const store = createStore(
    rootReducer,
    state,
    applyMiddleware<ThunkAction<unknown, State, ThunkExtra, Action>, State>(
      ...middlewares
    )
  )

  return store
}

export type AppStore = ReturnType<typeof configureStore>
