import { groupBy } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { Mode, DateTimeFormat } from '../consts'
import { State } from '../store/state'
import { secondsBetweenDates, formatTime } from '../utils'

import { selectionSelector } from './selectionSelector'

export type Event = {
  issue?: string
  title: string
  index: number
  start: Date | moment.Moment
  end?: Date | moment.Moment
  duration: string
  durationInSeconds: number
  actual?: boolean
  selected: boolean
  synced: boolean
  freeTime: boolean
  jiraId?: string | null
  id: string
}

export interface DetailedEvent extends Event {
  hasCursor: boolean
  editingDescription: boolean
  editingTime: boolean
  timeGrayedOut: boolean
  showShortcuts: boolean
}

const ETERNITY = moment().add(1000, 'years').toDate()

const modeSelector = (state: State) => state.mode
const pointsSelector = (state: State) => state.points
const cursorSelector = (state: State) => state.cursor

export const detailedEventSelector = (event: Event) =>
  createSelector(
    cursorSelector,
    modeSelector,
    selectionSelector,
    (cursor, mode, selection) => {
      const pointInd = event.index
      const selected = pointInd === cursor || event.selected
      const editingDescription = selected && mode === Mode.EDIT_DESCRIPTION
      const editingTime = selected && mode === Mode.EDIT_TIME

      return {
        ...event,
        hasCursor: pointInd === cursor,
        editingDescription,
        editingTime,
        timeGrayedOut: (selected && selection.length > 1) || event.synced,
        showShortcuts:
          pointInd === cursor &&
          !event.synced &&
          !editingDescription &&
          !editingTime,
      }
    }
  )

export const eventsSelector = createSelector([pointsSelector], (points) =>
  points.map(
    (point, i): Event => {
      const isActual = i === points.length - 1
      const eventStart = moment(point.timestamp)
      const eventEnd = isActual ? ETERNITY : moment(points[i + 1].timestamp)
      const durationInSeconds =
        point.description && !isActual
          ? secondsBetweenDates(points[i + 1].timestamp, point.timestamp)
          : 0
      const eventDuration = formatTime(durationInSeconds)

      return {
        issue: point.issue ?? undefined,
        title: point.description,
        index: i,
        start: eventStart,
        end: eventEnd,
        durationInSeconds,
        duration: eventDuration,
        actual: isActual,
        selected: point.marked,
        synced: point.synced,
        freeTime: point.freeTime,
        jiraId: point.jiraId,
        id: point.id,
      }
    }
  )
)

export const eventsByDateSelector = createSelector(
  eventsSelector,
  (events: Event[]) =>
    groupBy(events, (event) =>
      moment(event.start).startOf('day').format(DateTimeFormat.LONG_DATE)
    )
)
