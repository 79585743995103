import React from 'react'

import { Tooltip, IconButton } from '@material-ui/core'
import { SyncProblem } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

import { updatePointsInHasura } from '../../actions/trackerActions'
import Loading from '../../components/Loading'
import { useSelector } from '../../hooks/useSelector'

import { unsyncedPointsSelector } from './worklogsHeaderSelectors'

export const SyncPoints = () => {
  const dispatch = useDispatch()
  const unsyncedPoints = useSelector(unsyncedPointsSelector)
  const { loading, success } = useSelector((state) => state.syncStatus)

  return (
    <div style={{ minWidth: '50px' }}>
      {loading ? (
        <Tooltip title={'Syncing worklogs to DB.'}>
          <div style={{ marginRight: 16 }}>
            <Loading />
          </div>
        </Tooltip>
      ) : (
        !success && (
          <Tooltip title={`Resync worklogs`}>
            <IconButton
              onClick={() =>
                dispatch(
                  updatePointsInHasura(
                    unsyncedPoints.map(([_, index]) => index)
                  )
                )
              }
            >
              <SyncProblem />
            </IconButton>
          </Tooltip>
        )
      )}
    </div>
  )
}
