import { makeStyles } from '@material-ui/core'

import { DetailedEvent } from '../eventsSelectors'

export const worklogs = {
  height: 'inherit',
  overflowY: 'hidden',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
} as const

export const fab = {
  position: 'absolute',
  bottom: '20px',
  right: '25px',
} as const

export const duration = {
  minWidth: 85,
  textAlign: 'right',
  whiteSpace: 'nowrap',
} as const

export const key = {
  backgroundColor: '#fff',
  borderRadius: '2px',
  padding: `0 4px`,
  height: 16,
  minWidth: 16,
  fontSize: 10,
  lineHeight: '16px',
  margin: 0,
  marginLeft: '8px',
} as const

export const useWorklogStyles = makeStyles((theme) => ({
  worklog: ({ hasCursor, selected, synced }: DetailedEvent) => ({
    borderTop: `1px solid ${theme.palette.border.dark}`,
    minHeight: 56,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2, 0, 3),
    boxSizing: 'border-box',
    // synced
    ...(synced && { color: 'rgba(0, 0, 0, 0.2)' }),
    ...{
      backgroundColor:
        (hasCursor && theme.palette.worklog.focused) ||
        (selected && theme.palette.worklog.selected) ||
        theme.palette.common.white,
    },
  }),
  worklogEditMode: {
    padding: theme.spacing(0),
  },
  editableContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    fontSize: '15px',
    lineHeight: '24px',
    marginLeft: theme.spacing(2),
  },
  descriptionContainerTime: {
    whiteSpace: 'nowrap',
    color: theme.palette.worklog.time,
    textAlign: 'right',
    margin: 'auto',
    marginRight: 0,
  },
  issueContainer: {
    display: 'flex',
    alignItems: 'baseline',
    minWidth: '150px',
    justifyContent: 'flex-end',
  },
  issueWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  freeTag: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '2px',
    height: '24px',
    width: '81px',
    boxSizing: 'border-box',
    padding: '0 5px',
    textTransform: 'uppercase',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '24px',
    position: 'relative',
    display: 'inline-flex',
    justifyContent: 'center',
  },
  key: {
    ...key,
  },
  issueTitle: ({ issue, showShortcuts }: DetailedEvent) => ({
    display: 'block',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5),
    border: !issue && showShortcuts ? '1px dashed #0085FF' : 'none',
    borderRadius: '3px',
  }),
  checkbox: {
    padding: 0,
  },
  checkboxWrapper: { width: 15, height: 15, display: 'table' },
  unsync: { color: theme.palette.text.primary, margin: -6 },
  icon: {
    width: 12,
    height: 12,
    borderRadius: '2px',
    border: `1px solid ${theme.palette.border.checkbox}`,
    backgroundColor: theme.palette.common.white,
  },
  checkedIcon: {
    '&:before': {
      content: '""',
      position: 'relative',
      display: 'block',
      left: '4px',
      top: '1px',
      width: 3,
      height: 7,
      transform: 'rotate(40deg)',
      borderStyle: 'solid',
      borderColor: theme.palette.text.primary,
      borderImage: 'initial',
      borderWidth: '0px 1px 1px 0px',
    },
  },
  timeWrapper: ({ timeGrayedOut }: DetailedEvent) => ({
    position: 'relative',
    color: timeGrayedOut
      ? theme.palette.worklog.time
      : theme.palette.text.primary,
    width: 80,
  }),
  time: {
    display: 'inline-block',
    minWidth: 40,
    textAlign: 'center',
  },
  keyTime: {
    ...key,
    position: 'absolute',
    top: '4px',
  },

  descriptionWrapper: {
    overflow: 'hidden',
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(6),
  },
  descriptionTitle: {
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  descriptionTextField: {
    width: 500,
    marginRight: theme.spacing(2),
  },
}))
