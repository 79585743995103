import { ComponentHeights } from '../../consts'

export const container = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
} as const

export const item = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: 55,
  boxSizing: 'border-box',
} as const

export const itemTitle = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: '15px',
  lineHeight: '15px',
} as const

export const itemId = {
  fontSize: '12px',
  lineHeight: '12px',
  display: 'block',
  marginTop: '2px',
} as const

export const rightSideBar = {
  display: 'flex',
  flexDirection: 'column',
  height: `calc(100vh - ${ComponentHeights.HEADER})`,
} as const
