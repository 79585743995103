import React, { useState } from 'react'

import {
  Button,
  Avatar,
  AppBar,
  Toolbar,
  Box,
  makeStyles,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
} from '@material-ui/core'
import {
  AccountCircle,
  HelpOutline,
  PlayArrow,
  Stop,
  Settings,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons'
import classnames from 'classnames'
import {
  lastDayOfMonth,
  setDate,
  differenceInBusinessDays,
  isSameMonth,
  parseISO,
  addDays,
} from 'date-fns'
import { useDispatch } from 'react-redux'

import { toggleShortcuts } from '../../actions/trackerActions'
import Logo from '../../assets/logo-icon.svg'
import { ComponentHeights, ComponentWidths } from '../../consts'
import {
  useSetTutorialEnabled,
  useSetTutorialIndex,
} from '../../hooks/tutorialHooks'
import { useSelector } from '../../hooks/useSelector'
import { formatTime, SECONDS_IN_WORKING_DAY } from '../../utils'
import { SettingsDialog } from '../SettingsDialog'
import { SyncPoints } from '../WorklogsHeader/SyncPoints'

import { Logout } from './Logout'
import { secondsAssignedMonthlySelector } from './secondsAssignedMonthlySelector'
import * as styles from './styles'

const workingDaysCount = (displayedISODate: string) => {
  const displayedDate = parseISO(displayedISODate)
  const endingDate = isSameMonth(new Date(), displayedDate)
    ? new Date()
    : lastDayOfMonth(displayedDate)

  //the function counts with (last, first> not <last, first>
  return differenceInBusinessDays(
    addDays(endingDate, 1),
    setDate(displayedDate, 1)
  )
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    height: `${ComponentHeights.HEADER}`,
    boxShadow: 'none',
    borderBottom: `1px solid ${theme.palette.border.main}`,
    boxSizing: 'border-box',
  },
  container: {
    padding: theme.spacing(0, 2),
  },
  logo: {
    width: 23,
    height: 'auto',
    padding: theme.spacing(1),
    // TODO: temporary
    marginRight: theme.spacing(2),
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  name: {
    marginRight: theme.spacing(1),
  },
  btn: {
    height: 32,
  },
  tutorial: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.button.lightgrey,
    paddingLeft: '5px',
    paddingRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.button.darkgrey,
    },
    '& > span > svg': {
      width: 16,
      height: 16,
    },
  },
  btnTitle: {
    textTransform: 'none',
    fontSize: 12,
    lineHeight: '12px',
  },
  tutorialTitle: {
    marginLeft: '6px',
  },
  shortcuts: {
    marginRight: theme.spacing(2),
  },
  shortcutsTitle: {
    marginLeft: theme.spacing(1),
    lineHeight: '23px',
  },
  avatar: {
    marginRight: theme.spacing(1),
  },
  menu: {
    width: `${ComponentWidths.MENU_DROPDOWN}`,
    boxSizing: 'border-box',
    padding: theme.spacing(1),
  },
  menuItem: {
    ...styles.menuItem,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  menuText: styles.menuText,
  requiredTime: {
    borderRadius: '3px',
    padding: '6px',
    fontSize: '11px',
    textAlign: 'center',
    marginRight: theme.spacing(3),
    border: `1px solid`,
  },
}))

export const Header = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tutorialEnabled = useSelector((state) => state.tutorial.enabled)
  const tutorialInDropdown = useSelector((state) => state.tutorialInDropdown)
  const setTutorialEnabled = useSetTutorialEnabled()
  const setTutorialIndex = useSetTutorialIndex()
  const user = useSelector((state) => state.user)
  const shortcutsShown = useSelector((state) => state.shortcutsShown)
  const ftePercentage = useSelector((state) => state.settings.ftePercentage)
  const secondsAssignedMonthly = useSelector(secondsAssignedMonthlySelector)
  const displayedDate = useSelector((state) => state.displayedDate)

  const currentPeriodSeconds =
    secondsAssignedMonthly -
    (workingDaysCount(displayedDate) * SECONDS_IN_WORKING_DAY * ftePercentage) /
      100

  const startTutorial = () => {
    handleClose()
    setTutorialIndex(0)
    setTutorialEnabled(!tutorialEnabled)
  }

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const [isSettingsOpened, setSettingsOpened] = useState(false)

  const openSettings = () => {
    setAnchorEl(null)
    setSettingsOpened(true)
  }
  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'logout' : undefined

  return !user ? (
    <AppBar color="transparent" position="static" className={classes.header} />
  ) : (
    <AppBar color="transparent" position="static" className={classes.header}>
      <Toolbar className={classes.container}>
        <img
          src={Logo}
          alt="logo"
          className={classnames(classes.logo, 'tutorial-welcome')}
        />

        <div className={classes.right}>
          <SyncPoints />
          <div className={classes.requiredTime}>
            {`Current Period ${currentPeriodSeconds > 0 ? '+' : ''}${formatTime(
              currentPeriodSeconds
            )}`}
          </div>
          {(!tutorialInDropdown || tutorialEnabled) && (
            <Button
              className={classnames(classes.btn, classes.tutorial)}
              onClick={startTutorial}
              data-cy="tutorial-button"
            >
              {tutorialEnabled ? (
                <Stop fontSize="small" />
              ) : (
                <PlayArrow fontSize="small" />
              )}
              <Box
                className={classnames(classes.btnTitle, classes.tutorialTitle)}
              >
                {tutorialEnabled ? 'Stop' : 'Start'} tutorial
              </Box>
            </Button>
          )}
          <Tooltip title={shortcutsShown ? 'Hide shortcuts' : 'Show shortcuts'}>
            <Button
              onClick={() => dispatch(toggleShortcuts())}
              className={classnames(classes.shortcuts, 'tutorial-shortcuts')}
            >
              <HelpOutline />
              <Box className={classes.shortcutsTitle}>shortcuts</Box>
            </Button>
          </Tooltip>

          <Button aria-describedby={id} onClick={handleClick}>
            {user.avatarUrl ? (
              <Avatar
                alt={user.name}
                src={user.avatarUrl}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.avatar}>
                <AccountCircle />
              </Avatar>
            )}
            {anchorEl === null ? (
              <ExpandMore fontSize="small" />
            ) : (
              <ExpandLess fontSize="small" />
            )}
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <MenuList className={classes.menu}>
              <MenuItem onClick={openSettings} className={classes.menuItem}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                <ListItemText className={classes.menuText} primary="Settings" />
              </MenuItem>
              {tutorialInDropdown && (
                <MenuItem onClick={startTutorial} className={classes.menuItem}>
                  <ListItemIcon>
                    <PlayArrow fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.menuText}
                    primary="Start tutorial"
                  />
                </MenuItem>
              )}
              <Logout />
            </MenuList>
          </Popover>
          <SettingsDialog
            isOpened={isSettingsOpened}
            onClose={() => setSettingsOpened(false)}
          />
        </div>
      </Toolbar>
    </AppBar>
  )
}
