import * as React from 'react'

import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import moment from 'moment'

import { ComponentHeights } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { timelineEventsSelector } from '../timelineSelectors'

import { Interval, TIME_POINTS } from './Interval'

const useStyles = makeStyles((theme) => ({
  timeline: {
    borderBottom: '1px solid',
    borderBottomColor: grey[300],
    height: `${ComponentHeights.TIMELINE}`,
    position: 'relative',
    boxSizing: 'border-box',
  },
  axis: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    left: 30,
    right: 30,
    bottom: 0,
  },
  point: {
    position: 'relative',
    width: 1,
    height: 70,
    backgroundColor: grey[300],
  },
  pointLabel: {
    position: 'absolute',
    top: -18,
    left: -16,
    color: grey[600],
  },
  intervals: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    paddingTop: 35,
    paddingLeft: 30,
    paddingRight: 30,
  },
}))

export const Timeline = () => {
  const classes = useStyles()

  const events = useSelector(timelineEventsSelector)
  const startHour = events.length ? moment(events[0].start).hours() : 0

  return (
    <div className={classes.timeline}>
      <div className={classes.axis}>
        {Array(TIME_POINTS)
          .fill(undefined)
          .map((_, index) => (
            <div className={classes.point} key={index}>
              <small className={classes.pointLabel}>
                {`${(index + startHour) % TIME_POINTS}`.padStart(2, '0')}:00
              </small>
            </div>
          ))}
      </div>
      <div className={classes.intervals}>
        {events.map((event, index) => (
          <Interval key={index} event={event} index={index} />
        ))}
      </div>
    </div>
  )
}
