import { Mode, Focus, DialogVariant, Input, MessageType } from '../consts'
import { roundPointTime } from '../utils'

type User = {
  name: string
  avatarUrl: string
  accountId: string
  token: string
  isActive: boolean
  tenantId: string
}

type PointProps = {
  synced: boolean
  freeTime: boolean
  marked: boolean
  /**
   * Jira id of the worklog (used when unsyncing the point). "Free time" and logs submitted before
   * this feature don't have jira id.
   */
  jiraId?: string | null
  id: string
}

export type PointInput = {
  description: string
  timestamp: string
  // TODO: remove null (it's optional already)
  issue?: string | null
}

export type Point = PointInput & PointProps

type Message = {
  body: string[]
  type: MessageType
  isDisplayed: boolean
}

type Dialog = { type: DialogVariant; payload?: any }
export type Issue = { key: string; title: string }
type History = { states: Object[]; index: number }

type GenericFocusState = {
  focusType: Focus.DATE | Focus.TIME
}
type IssueFocusState = {
  focusType: Focus.ISSUE
  payload?: { index: string }
}
type DescriptionFocusState = {
  focusType: Focus.DESCRIPTION | Focus.JOIN_DIALOG
  payload?: { insertPoint: Point }
}
export type FocusState =
  | GenericFocusState
  | IssueFocusState
  | DescriptionFocusState

type InputType = {
  value: string
  isModified: boolean
}

type LoggerInputs = {
  [inputName in Input]: InputType
}

type Logger = {
  issueOptions: Issue[]
  issueOptionsCursor: number
  inputs: LoggerInputs
}

type Schedule = {
  monthlyRequiredSeconds: number
  currentlyRequiredSeconds: number
}

export type Settings = {
  expandWorklogs: boolean
  showTimeline: boolean
  ftePercentage: number
  daysToShow: number
}

export type SyncStatus = {
  loading: boolean
  error: string
  success: boolean
}

export type State = {
  user: User | null
  mode: Mode
  shortcutsShown: boolean
  points: Point[]
  syncStatus: SyncStatus
  displayedDate: string
  message: Message
  cursor: number
  focus?: FocusState
  dialog?: Dialog | null
  issues: Issue[]
  logger: Logger
  recentIssues: string[]
  schedule: Schedule | null
  history: History
  tutorial: {
    enabled: boolean
    index: number
    startTime: string
  }
  settings: Settings
  tutorialInDropdown: boolean
  minimalDate: Date
}

export const getInitialHistoryState = (): History => ({ states: [], index: -1 })

const getInitialMessageState = (): Message => ({
  body: [''],
  type: MessageType.WARNING,
  isDisplayed: false,
})

const getInitialLoggerState = (): Logger => ({
  issueOptions: [],
  issueOptionsCursor: -1,
  inputs: {
    [Input.DESCRIPTION]: {
      value: '',
      isModified: false,
    },
    [Input.LOCAL_DESCRIPTION]: {
      value: '',
      isModified: false,
    },
    [Input.TIMESTAMP]: {
      value: new Date().toISOString(),
      isModified: false,
    },
    [Input.ISSUE]: {
      value: '',
      isModified: false,
    },
  },
})

const getInitialState = (): State => ({
  user: null,
  mode: Mode.LOGGING,
  shortcutsShown: false,
  points: [],
  syncStatus: {
    error: '',
    success: true,
    loading: false,
  },
  displayedDate: new Date().toISOString(),
  message: getInitialMessageState(),
  cursor: -1,
  focus: undefined,
  dialog: null,
  issues: [],
  logger: getInitialLoggerState(),
  recentIssues: [],
  schedule: null,
  history: getInitialHistoryState(),
  tutorial: {
    enabled: false,
    startTime: roundPointTime(new Date().toISOString()),
    index: 0,
  },
  settings: {
    expandWorklogs: false,
    showTimeline: false,
    ftePercentage: 100,
    daysToShow: 35,
  },
  tutorialInDropdown: false,
  minimalDate: new Date(), // will be on the first load
})

export default getInitialState
