import * as React from 'react'

import { makeStyles } from '@material-ui/core'
import moment from 'moment'
import { useDispatch } from 'react-redux'

import { toggleMarkedPoint } from '../../actions/trackerActions'
import { DateTimeFormat } from '../../consts'
import { Event } from '../eventsSelectors'

export const TIME_POINTS = 24
const countSpacing = (minutes) => (minutes / ((TIME_POINTS - 1) * 60)) * 100

const useStyles = makeStyles((theme) => ({
  interval: {
    boxSizing: 'border-box',
    height: 24,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 6,
    borderRightWidth: (props: Props) => (props.event.actual ? 0 : 1),
    backgroundColor: (props: Props) =>
      props.event.issue
        ? props.event.selected
          ? theme.palette.timeline.secondarySelected
          : theme.palette.timeline.secondary
        : props.event.selected
        ? theme.palette.timeline.primarySelected
        : theme.palette.timeline.primary,
    borderColor: (props: Props) =>
      props.event.issue
        ? theme.palette.timeline.secondarySelected
        : theme.palette.timeline.primarySelected,
    visibility: (props: Props) =>
      props.event.freeTime || !props.event.title ? 'hidden' : 'visible',
    opacity: (props: Props) => (props.event.synced ? 0.25 : 1),
    backgroundImage: (props: Props) =>
      props.event.actual
        ? 'linear-gradient(-90deg, white, rgba(255, 255, 255, 0) 50%)'
        : '',
    width: (props: Props) =>
      props.spacing <= 100 ? `${props.spacing}%` : 'auto',
    flex: (props: Props) => (props.spacing > 100 ? 1 : 'initial'),
    marginLeft: (props: Props) =>
      props.index === 0
        ? `${countSpacing(moment(props.event.start).minutes())}%`
        : 0,
    '& + &': {
      marginLeft: -1,
    },
  },
}))

type Props = {
  event: Event
  index: number
  spacing: number
  handleClick: (event: Event) => void
}

export const Interval = ({ event, index }) => {
  const dispatch = useDispatch()

  const handleClick = (event) => {
    if (!event.synced) {
      dispatch(toggleMarkedPoint(event.index))
    }
  }

  const eventEnd = event.actual ? moment() : event.end
  const spacing = countSpacing(
    moment(eventEnd).diff(moment(event.start), 'minutes')
  )
  const classes = useStyles({ event, index, spacing, handleClick })

  return (
    <div
      onClick={() => handleClick(event)}
      hidden={!spacing}
      className={classes.interval}
      title={`${moment(event.start).format(DateTimeFormat.TIME)} – ${
        event.actual ? 'now' : moment(event.end).format(DateTimeFormat.TIME)
      }`}
    />
  )
}
