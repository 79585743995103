import React, { useState, useEffect } from 'react'

interface Props<T> {
  labelComponent: React.ReactChild
  editComponent: (
    localValue: T,
    setLocalValue: (val: T) => void
  ) => React.ReactElement
  // needed to focus the element when changing to edit mode
  editRef: React.MutableRefObject<HTMLElement | undefined>
  className?: string
  onTab?: () => void
  value: T
  changeValue: (newValue: T) => void
  isEditing: boolean
  setEditing: (editing: boolean) => void
  editValue?: T
  setEditValue?: (newValue: T) => void
  // We use blur event to exit edit mode, but we also force focus for edit component when entering
  // edit mode. This is problem if we edit multiple inputs at once as they both require focus,
  // blurring each other (and we would exit edit mode). Thus, we pick one (main) component that
  // recieves the focus.
  //
  // Multiple editing is slow mainly because:
  //  1.  It's going through redux
  //  2.  Change of one points causes all worklogs to re-render. This is
  //      partially mitigated by custom shouldComponentUpdate with deep
  //      comparison of the event.
  //  3.  Need to change multiple components between keystrokes (little time)
  //
  // We improve the performance by throttling the redux updates and keeping the edited value for the
  // main component in local store.
  //
  // TODO: revisit this after we only show worklogs from one day (and there is less stuff to render)
  isMainEditComponent: boolean
}

// inspired by: https://blog.logrocket.com/the-complete-guide-to-building-inline-editable-ui-in-react/
export function ContentEditable<T>({
  editRef,
  labelComponent,
  editComponent,
  changeValue,
  onTab,
  value,
  className,
  isEditing,
  setEditing,
  editValue: propsEditValue,
  setEditValue: propsSetEditValue,
  isMainEditComponent,
}: Props<T>) {
  const [localEditValue, setLocalEditValue] = useState(value)
  // local state is not reset after props change
  useEffect(() => setLocalEditValue(value), [value])

  const updateEditValue = (value: T) => {
    setLocalEditValue(value)
    propsSetEditValue?.(value)
  }

  const displayValue = isMainEditComponent
    ? localEditValue
    : propsEditValue ?? localEditValue

  useEffect(() => {
    if (isMainEditComponent && editRef?.current && isEditing === true) {
      editRef.current.focus()
    }
  }, [editRef, isEditing, isMainEditComponent])

  const handleKeyDown = (event) => {
    const { key } = event

    if (key === 'Enter') {
      changeValue(displayValue)
      setEditing(false)
    } else if (key === 'Tab') {
      changeValue(displayValue)
      if (onTab) {
        onTab()
        event.preventDefault()
      }
    } else if (key === 'Escape') {
      updateEditValue(value)
      setEditing(false)
    }
  }

  return (
    <>
      {isEditing ? (
        <div
          className={className}
          onBlur={() => {
            //avoid blur triggered by window change
            if (document.activeElement === editRef?.current) return
            updateEditValue(value)
            setEditing(false)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          {editComponent(displayValue, updateEditValue)}
        </div>
      ) : (
        <div className={className} onClick={() => setEditing(true)}>
          {labelComponent}
        </div>
      )}
    </>
  )
}
