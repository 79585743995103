import React, { useCallback } from 'react'

import { Checkbox } from '@material-ui/core'
import classnames from 'classnames'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import {
  toggleMarkedPoint,
  setCursorAndIssueInput,
  setFocus,
} from '../../actions/trackerActions'
import { Focus } from '../../consts'
import { detailedEventSelector, Event } from '../eventsSelectors'

import { EditableDescription, DescriptionLabel } from './EditableDescription'
import { EditableTime, TimeLabel } from './EditableTime'
import { useWorklogStyles } from './styles'

interface Props {
  event: Event
  usedForTutorial: boolean
}

const clearFocus = (e: React.FocusEvent<HTMLButtonElement>) => e.target.blur()

export const WorkLog = React.memo(
  ({ event, usedForTutorial }: Props) => {
    const detailedEvent = useSelector(detailedEventSelector(event))
    const classes = useWorklogStyles(detailedEvent)
    const dispatch = useDispatch()

    const handleClick = () => {
      if (!detailedEvent.hasCursor)
        dispatch(setCursorAndIssueInput(event.index))
    }

    const toggleMarked = useCallback(
      () => dispatch(toggleMarkedPoint(event.index)),
      [dispatch, event.index]
    )
    return (
      <div
        data-cy="worklog"
        className={classnames(
          classes.worklog,
          usedForTutorial && 'tutorial-selectedWorklog'
        )}
        onClick={handleClick}
        ref={(ref) =>
          detailedEvent.hasCursor &&
          ref &&
          ref.scrollIntoView({ block: 'nearest' })
        }
      >
        <div className={classes.checkboxWrapper}>
          {!event.synced && (
            <Checkbox
              color="primary"
              checked={event.selected}
              // avoid focusable checkboxes
              tabIndex={-1}
              disabled={event.synced}
              onChange={toggleMarked}
              onFocus={clearFocus}
              className={classes.checkbox}
              checkedIcon={
                <span
                  className={classnames(classes.icon, classes.checkedIcon)}
                />
              }
              icon={<span className={classes.icon} />}
            />
          )}
          {event.synced && (event.jiraId || event.freeTime) && (
            <kbd className={classes.unsync}>U</kbd>
          )}
        </div>
        <div className={classes.descriptionContainer}>
          {event.synced ? (
            <>
              <TimeLabel event={detailedEvent} classes={classes} />
              <DescriptionLabel event={detailedEvent} classes={classes} />
            </>
          ) : (
            <>
              <EditableTime event={detailedEvent} classes={classes} />
              <EditableDescription event={detailedEvent} classes={classes} />
            </>
          )}
          <span className={classes.descriptionContainerTime}>
            {event.actual ? 'In progress...' : event.duration}
          </span>
        </div>
        {/* TODO: remove the inner container, take care about tutorial step */}
        <div className={classes.issueContainer}>
          {event.freeTime && <span className={classes.freeTag}>Free time</span>}
          {event.title && !event.freeTime && (
            <div
              className={classnames(
                classes.issueWrapper,
                usedForTutorial && 'tutorial-issueContainer'
              )}
              onClick={() => dispatch(setFocus({ focusType: Focus.ISSUE }))}
            >
              <strong className={classes.issueTitle}>
                {event.issue
                  ? event.issue
                  : detailedEvent.showShortcuts && 'Assign issue'}
              </strong>
              {detailedEvent.showShortcuts ? (
                <kbd
                  className={classes.key}
                  data-cy="worklog-edit-issue-shortcut"
                >
                  S
                </kbd>
              ) : null}
            </div>
          )}
        </div>
      </div>
    )
  },
  (props: Props, nextProps: Props) => {
    // Editing points causes rerender of all worklogs, because we group them in selector (producing
    // new object every time).
    return isEqual(props.event, nextProps.event)
  }
)
