import React from 'react'

import { InputAdornment } from '@material-ui/core'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { modifyLogger } from '../../actions/loggerActions'
import { switchMode } from '../../actions/trackerActions'
import { Mode, Focus, Input } from '../../consts'
import { useHistoryInput } from '../../hooks/inputHooks'
import { useSelector } from '../../hooks/useSelector'
import { useFocusListener } from '../useFocusListener'

import { CustomTextField, useTextFieldStyles } from './styles'

type Props = {
  value: string
  focusedAs: Focus.DESCRIPTION | Focus.JOIN_DIALOG
  handleSubmit: (focusLogger?: boolean, timestampOverride?: Date) => void
}

export const LoggerInput = ({ value, handleSubmit, focusedAs }: Props) => {
  const dispatch = useDispatch()
  const classes = useTextFieldStyles()

  const {
    handleRef,
    focus,
    hasFocus,
    handleBlur,
    ensureFocus,
  } = useFocusListener(focusedAs)
  const { getNextHistoryValue, resetHistory } = useHistoryInput(value)

  const displayedDate = useSelector((state) => state.displayedDate)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetHistory(e.target.value)
    dispatch(modifyLogger(Input.DESCRIPTION, e.target.value))
  }

  const handleDescriptionFocus = () => {
    ensureFocus()
    resetHistory()
    // TODO: https://vacuum.atlassian.net/browse/FINE-189
    if (focus?.focusType === focusedAs && focus.payload) {
      const timestamp = focus.payload?.insertPoint?.timestamp || displayedDate
      dispatch(switchMode(Mode.LOGGING))
      dispatch(modifyLogger(Input.DESCRIPTION, ''))
      dispatch(modifyLogger(Input.TIMESTAMP, timestamp))
    }
  }

  return (
    <div
      className={classnames(
        classes.inputWrapper,
        'description-input',
        'tutorial-descriptionInput'
      )}
    >
      <CustomTextField
        fullWidth
        variant="outlined"
        label="Description"
        type="text"
        value={value}
        onFocus={handleDescriptionFocus}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(e.ctrlKey)
            return
          }
          const historyValue = getNextHistoryValue(e)
          if (historyValue !== null) {
            e.preventDefault()
            dispatch(modifyLogger(Input.DESCRIPTION, historyValue))
          }
        }}
        onChange={handleChange}
        inputRef={handleRef}
        onBlur={handleBlur}
        data-cy="logger-description"
        placeholder="What are you working on?"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {focusedAs === Focus.DESCRIPTION && (
                <kbd className={classes.inputKey}>
                  {hasFocus ? 'ENTER' : 'L'}
                </kbd>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}
