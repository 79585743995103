import React from 'react'

import { useApolloClient } from '@apollo/react-hooks'
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { useSelector } from '../../hooks/useSelector'

import * as styles from './styles'

const useStyles = makeStyles({
  menuItem: styles.menuItem,
  menuText: styles.menuText,
})

export const Logout = () => {
  const classes = useStyles()
  const client = useApolloClient()
  const history = useHistory()
  const tenant = useSelector((state) => state.user!.tenantId)
  const handleLogout = () => {
    client.clearStore().then(() => {
      localStorage.removeItem('token')
      fetch('/auth/logout', {
        method: 'POST',
        redirect: 'follow',
        referrer: 'no-referrer',
      }).then((_res) => {
        history.push(`/${tenant}`)
      })
    })
  }

  return (
    <MenuItem onClick={handleLogout} className={classes.menuItem}>
      <ListItemIcon>
        <ExitToApp fontSize="small" />
      </ListItemIcon>
      <ListItemText className={classes.menuText} primary="Logout" />
    </MenuItem>
  )
}
