import React from 'react'

import { addMinutes, parseISO } from 'date-fns'
import { Step } from 'react-joyride'

import { createPoint, deletePoints } from '../../actions/editPointsActions'
import { setLogger } from '../../actions/loggerActions'
import {
  showShortcuts,
  switchMode,
  setTutorialInDropdown,
  toggleFreeTime,
} from '../../actions/trackerActions'
import { Mode } from '../../consts'
import { State } from '../../store/state'
import { Action, Dispatch } from '../../types/reduxTypes'
import { roundPointTime } from '../../utils'
import { getCursorByDate } from '../utils'

interface TutorialStep extends Step {
  title: string
  // beacon is the feature to hide a step and display a clickable circle which shows the next step
  disableBeacon: true
  content: React.ReactNode | string
  beforeStep?: (getState: () => State, dispatch: Dispatch) => void
  afterStep?: (getState: () => State, dispatch: Dispatch) => void
}

/**
 * Creates steps for app tour in react joyride.
 *
 * The step highlights an element of the app and displays a nice dialog with supplementary text.
 * There are various optional properties for positioning the modal, scrolling...
 * You need to at least set 'title', 'target' and 'content'.
 *
 * You can execute custom logic before and after step, you will also have access to redux state
 * and dispatch, so you can perform any redux action you want to get the app into the state you want
 * (for example, change mode).
 *
 * There is a convention to follow here:
 *  title:    starts with uppercase, should be short
 * disableBeacon: true,
 *  target:   argument of document.querySelectorAll, we are fine with using classnames,
 *            but DO NOT reuse className dealing about logic. Create a new classname,
 *            prefixed with 'tutorial-' and reasonable camelCase description of the target
 *            element.
 * content:   any ReactNode or string. If there is a keyboard shortcut you want to explain
 *            use <kbd> element for it. It is recommended to play with different text styles.
 *            The text should be concise and nicely formatted. Keep in mind, that the tutorial
 *            should be JOY RIDE, like a story which walks you though app from beginning to end.
 *
 *            Feel free to repeat the same step, add logic before and after step and clearly
 *            explain how the flow in fine tracker works.
 */

const issue = 'OP-3'
const description = '1on1 with John'

export const createTutorialSteps = (
  tutorialStartTime: string,
  setTutorialStartTime: (date: string) => Action
): TutorialStep[] => {
  return [
    {
      title: 'Welcome to Fine Tracker',
      disableBeacon: true,
      target: '.tutorial-welcome',
      content: (
        <div>
          <p style={{ marginTop: 0 }}>
            Thank you for trying out Fine Tracker! Let us guide you through the
            main functionalities of the app.
          </p>
          <p>
            This tutorial will show you how to use the Fine Tracker interface
            and keyboard shortcuts. Clicking <i>Next</i> or pressing{' '}
            <kbd>ENTER</kbd> will always move you to the next step, and clicking
            anywhere outside of this tutorial will bring you back to Fine
            Tracker. Full navigation will be enabled as soon as you exit or
            finish the tutorial.
          </p>
          <p>
            Clicking into the field labelled <i>Description</i> at the bottom of
            the screen or pressing <kbd>L</kbd> is used to start logging.
          </p>
        </div>
      ),
      beforeStep: (_getState, dispatch) => {
        dispatch(switchMode(Mode.LOGGING))
        setTutorialStartTime(roundPointTime(new Date().toISOString()))
      },
      placement: 'bottom-end',
    },
    {
      title: 'Description of your work',
      disableBeacon: true,
      target: '.tutorial-descriptionInput',
      content: (
        <span>
          Here you add the description of what you are starting to work on.
          We've pre-filled an example for you now.
        </span>
      ),
      beforeStep: (_getState, dispatch) =>
        dispatch(
          setLogger({
            description: description,
            timestamp: tutorialStartTime,
          })
        ),
    },
    {
      title: 'When did you do the work',
      disableBeacon: true,
      target: '.tutorial-dateAndTime',
      content: (
        <div>
          Leave these as they are if you're logging work you're just starting.
          If you're logging older work, use these to set when you've started
          working on it.
        </div>
      ),
      afterStep: (_getState, dispatch) =>
        dispatch(setLogger({ description: '', timestamp: tutorialStartTime })),
    },
    {
      title: 'Assigned JIRA issue',
      disableBeacon: true,
      target: '.tutorial-issueContainer',
      content: (
        <span>
          You assign the JIRA issue to your worklog after you create it.
          Pressing <kbd>S</kbd> or clicking on the worklog lets you pick the
          JIRA issue from your list.
        </span>
      ),
      beforeStep: (_getState, dispatch) =>
        dispatch(createPoint(description, tutorialStartTime)),
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'List of JIRA issues',
      disableBeacon: true,
      target: '.tutorial-issueInput',
      content:
        "Find the issue you need here. The ones you've used recently will be on the top of the list.",
      placement: 'top',
      beforeStep: (_getState, dispatch) =>
        dispatch(createPoint(description, tutorialStartTime)),
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'Personal time',
      disableBeacon: true,
      target: '.tutorial-issueContainer',
      content: (
        <span>
          Pressing <kbd>P</kbd> marks this log as your personal time, which
          doesn't have any associated issue and isn't counted towards your
          working hours.
        </span>
      ),
      placement: 'top',
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
      },
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'Edit existing worklog',
      disableBeacon: true,
      target: '.tutorial-selectedWorklog',
      content: (
        <span>
          Clicking on an existing worklog lets you edit its description or time.
          You can do the same by pressing <kbd>D</kbd> for the description or{' '}
          <kbd>T</kbd> for the time.
        </span>
      ),
      beforeStep: (getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(
          toggleFreeTime([
            getCursorByDate(getState().points, tutorialStartTime),
          ])
        )
      },
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'Move between days',
      disableBeacon: true,
      target: '.tutorial-changeDate',
      content:
        'You see one day of logs by default. Using left and right arrows moves you around to see previous days.',
      placement: 'top',
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
      },
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'Submit worklogs',
      disableBeacon: true,
      target: '.tutorial-sidebarSave',
      content:
        "When you've completed your logging in Fine Tracker, you need to submit these to JIRA.",
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(switchMode(Mode.LOGGING))
      },
      afterStep: (getState, dispatch) =>
        dispatch(
          deletePoints([getCursorByDate(getState().points, tutorialStartTime)])
        ),
    },
    {
      title: 'Overview of your worklogs',
      disableBeacon: true,
      target: '.tutorial-worklogsToSubmit',
      content:
        "You see an overview of the time you've spent on particular issues since the last submit. Use this to roughly check your numbers, and spot any mistakes early.",
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(
          createPoint(
            'end',
            addMinutes(parseISO(tutorialStartTime), 30).toISOString()
          )
        )
        dispatch(switchMode(Mode.SAVING))
      },
      afterStep: (getState, dispatch) => {
        dispatch(switchMode(Mode.LOGGING))
        dispatch(
          deletePoints([
            getCursorByDate(getState().points, tutorialStartTime),
            getCursorByDate(
              getState().points,
              addMinutes(parseISO(tutorialStartTime), 30).toISOString()
            ),
          ])
        )
      },
    },
    {
      title: 'Save to JIRA',
      disableBeacon: true,
      target: '.tutorial-worklogsToSave',
      content:
        "Clicking here saves your worklogs to JIRA. You won't be able to edit these in Fine Tracker any more.",
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(
          createPoint(
            'end',
            addMinutes(parseISO(tutorialStartTime), 30).toISOString()
          )
        )
        dispatch(switchMode(Mode.SAVING))
      },
      afterStep: (getState, dispatch) => {
        dispatch(switchMode(Mode.LOGGING))
        dispatch(
          deletePoints([
            getCursorByDate(getState().points, tutorialStartTime),
            getCursorByDate(
              getState().points,
              addMinutes(parseISO(tutorialStartTime), 30).toISOString()
            ),
          ])
        )
      },
    },
    {
      title: 'Shortcuts',
      disableBeacon: true,
      target: '.tutorial-shortcuts',
      content: (
        <span>
          Pressing <kbd>F1</kbd>, or clicking here, shows you all keyboard
          shortcuts you can use to make your logging smoother and faster.
        </span>
      ),
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(
          createPoint(
            'end',
            addMinutes(parseISO(tutorialStartTime), 30).toISOString()
          )
        )
        dispatch(showShortcuts(true))
      },
      afterStep: (getState, dispatch) => {
        dispatch(
          deletePoints([
            getCursorByDate(getState().points, tutorialStartTime),
            getCursorByDate(
              getState().points,
              addMinutes(parseISO(tutorialStartTime), 30).toISOString()
            ),
          ])
        )
      },
    },
    {
      title: 'Speed dial',
      disableBeacon: true,
      target: '.tutorial-setSpeedDial',
      content: (
        <span>
          If you often log your time under a few specific issues, set up speed
          dial to assign these even faster.
        </span>
      ),
      beforeStep: (_getState, dispatch) => {
        dispatch(createPoint(description, tutorialStartTime, issue))
        dispatch(
          createPoint(
            'end',
            addMinutes(parseISO(tutorialStartTime), 30).toISOString()
          )
        )
        dispatch(showShortcuts(true))
      },
      afterStep: (getState, dispatch) => {
        dispatch(
          deletePoints([
            getCursorByDate(getState().points, tutorialStartTime),
            getCursorByDate(
              getState().points,
              addMinutes(parseISO(tutorialStartTime), 30).toISOString()
            ),
          ])
        )
        dispatch(showShortcuts(false))
      },
    },
    {
      title: "Let's go!",
      disableBeacon: true,
      target: '.tutorial-welcome',
      content: (
        <div>
          That's it. You're back at the beginning.
          <div>
            We hope you'll enjoy using Fine Tracker. If you have any questions
            or problems, please reach out to the team in{' '}
            <a href="https://vacuumlabs.slack.com/archives/CC2QUSPQW">
              #fine-tracker
            </a>
            .
            <p>
              You can re-run this tutorial anytime from the menu in
              upper-right-hand corner.
            </p>
          </div>
        </div>
      ),
      beforeStep: (_getState, dispatch) => dispatch(switchMode(Mode.LOGGING)),
      afterStep: (_getState, dispatch) => dispatch(setTutorialInDropdown(true)),
    },
  ]
}
