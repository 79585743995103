import { reduce } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { State } from '../../store/state'
import { eventsSelector } from '../eventsSelectors'
const displayedDateSelector = (state: State) => state.displayedDate

export const secondsAssignedMonthlySelector = createSelector(
  [eventsSelector, displayedDateSelector],
  (events, displayedDate) =>
    reduce(
      events,
      (acc, event) =>
        acc +
        (moment(event.start).isSame(displayedDate, 'month') && event.issue
          ? event.durationInSeconds
          : 0),
      0
    )
)
