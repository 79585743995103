import { useEffect, useState } from 'react'

/**
 * Consolidates all focus changes inside page section into 2:
 * - initial focus
 * - final blur
 */
export const useSectionFocus = (onStart: () => void, onStop: () => void) => {
  const [wasBlurred, setWasBlurred] = useState(true)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    if (wasBlurred && isFocused) {
      onStart()
      setWasBlurred(false)
    }
    const timer = setTimeout(() => {
      if (!wasBlurred && !isFocused) {
        onStop()
        setWasBlurred(true)
      }
    }, 0)
    return () => {
      clearTimeout(timer)
    }
  }, [isFocused, wasBlurred, onStart, onStop])

  return { setIsFocused }
}
