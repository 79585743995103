import { createMuiTheme } from '@material-ui/core'

import './index.css'
import { ComponentSizes } from '../consts'

export const theme = createMuiTheme({
  palette: {
    common: {
      white: '#fff',
    },
    primary: {
      main: 'rgb(0, 133, 255)',
    },
    background: {
      default: '#fafafa',
    },
    text: {
      primary: '#495057',
      secondary: '#8997a5',
    },
    worklog: {
      focused: 'rgba(0, 0, 0, 0.05)',
      selected: 'rgba(0, 133, 255, 0.1)',
      highlighted: 'rgba(0, 133, 255, 0.2)',
      time: '#919191',
    },
    timeline: {
      primary: 'rgba(0, 133, 255, 0.5)',
      primarySelected: 'rgb(0, 133, 255)',
      secondary: 'rgba(0, 133, 255, 0.1)',
      secondarySelected: 'rgba(0, 133, 255, 0.2)',
    },
    border: {
      main: 'rgba(225, 230, 239, 0.5)',
      light: '#e1e6ef',
      dark: '#e2e2e2',
      checkbox: 'rgba(73, 80, 87, 0.8)',
    },
    boxShadow: {
      review: '0px 5px 25px rgba(0, 0, 0, 0.05)',
      issueOptions: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    },
    button: {
      light: '#e1e6ef',
      lightgrey: '#8997a5',
      darkgrey: '#495057',
    },
    tutorial: {
      focused: '#f2f2f2',
      selected: '#e5f3ff',
      highlighted: '#cce7ff',
    },
  },
  overrides: {
    MuiDialog: {
      paper: {
        padding: '12px',
      },
    },
    MuiAvatar: {
      root: {
        width: `${ComponentSizes.AVATAR}`,
        height: `${ComponentSizes.AVATAR}`,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 'inherit',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        marginTop: '8px',
        margin: '8px',
      },
      dayLabel: {
        color: '#8997a5',
        fontWeight: 500,
        width: '40px',
        margin: 0,
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        marginTop: '24px',
        minHeight: '240px',
        marginBottom: '16px',
      },
    },
    MuiPickersDay: {
      day: {
        width: '30px',
        height: '30px',
        margin: '5px',
        color: 'inherit',
      },
    },
  },
  typography: {
    fontFamily: "'Rubik', sans-serif",
    body1: { fontSize: '14px' },
    body2: {
      fontSize: '12px',
      letterSpacing: '0.5px',
    },
    useNextVariants: true,
    subtitle2: {
      color: '#495057',
    },
    // TS-FIX: useNextVariants is probably not needed too
  } as any,
  custom: {
    // used to be named --PADDING-RIGHT
    paddingRight: '16px',
  },
} as const)
