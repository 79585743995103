import { uniq } from 'lodash'
import { createSelector } from 'reselect'

import { State } from '../store/state'

const pointsSelector = (state: State) => state.points
// TODO: rewrite such that it returns Point[], not indexes
const markedSelector = createSelector(pointsSelector, (points) => {
  const markedPoints: number[] = []
  points.forEach(({ marked }, ind) => {
    if (marked) markedPoints.push(ind)
  })

  return markedPoints
})
const cursorSelector = (state: State) => state.cursor

export const selectionSelector = createSelector(
  [markedSelector, cursorSelector],
  (marked, cursor) => {
    if (cursor === -1) {
      return marked
    }

    return uniq([...marked, cursor])
  }
)
