import React, { useCallback } from 'react'

import { InputAdornment, makeStyles } from '@material-ui/core'
import { ExpandMore, ExpandLess, Close } from '@material-ui/icons'
import classnames from 'classnames'
import debounce from 'es6-promise-debounce'
import { useDispatch } from 'react-redux'

import {
  modifyLogger,
  setIssueOptionsCursor,
  setIssueOptions,
  setIssueFromCursorPoint,
} from '../../actions/loggerActions'
import {
  setJiraIssueForSelection,
  saveRecentIssue,
} from '../../actions/trackerActions'
import { Input, Focus } from '../../consts'
import {
  SpeedDialDocument,
  useSetSpeedDialMutation,
} from '../../generated/graphqlSdk'
import { useSelector } from '../../hooks/useSelector'
import { blurElement } from '../../utils'
import {
  allIssuesWithRecentFirstSelector,
  allIssuesObjSelector,
} from '../issuesSelectors'
import { CustomTextField } from '../Logger/styles'
import { useFocusListener } from '../useFocusListener'
import { formatIssueLabel } from '../utils'

import {
  getFuzzysortedIssueOptions,
  fuzzysortPreparedIssuesSelector,
} from './fuzzysort'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    padding: theme.spacing(2),
  },
  issueInput: {
    padding: theme.spacing(1),
  },
}))

const ISSUE_OPTIONS_MAX_LENGTH = 20
const INCREMENT_BY_KEY = {
  ArrowUp: -1,
  ArrowDown: 1,
}

type Props = { disabled: boolean }

export const IssueInput = ({ disabled }: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  //TODO: utilize https://www.apollographql.com/docs/react/performance/optimistic-ui/
  const [setSpeedDial] = useSetSpeedDialMutation({
    refetchQueries: [{ query: SpeedDialDocument }],
  })

  const allIssues = useSelector(allIssuesWithRecentFirstSelector)
  const allIssuesObj = useSelector(allIssuesObjSelector)
  const { value } = useSelector((state) => state.logger.inputs[Input.ISSUE])
  const { issueOptionsCursor: cursor, issueOptions } = useSelector(
    (state) => state.logger
  )
  const fuzzysortIssues = useSelector(fuzzysortPreparedIssuesSelector)

  const {
    handleRef,
    handleBlur,
    ensureFocus,
    hasFocus,
    focus,
  } = useFocusListener(Focus.ISSUE)
  const handleFocus = () => {
    ensureFocus()
    const trimmedResults = allIssues.slice(0, ISSUE_OPTIONS_MAX_LENGTH)
    dispatch(modifyLogger(Input.ISSUE, ''))
    dispatch(setIssueOptions(trimmedResults))
    dispatch(setIssueOptionsCursor(0))
  }

  const loadOptions = useCallback(
    debounce(
      (issueInputValue: string) =>
        getFuzzysortedIssueOptions(
          fuzzysortIssues,
          ISSUE_OPTIONS_MAX_LENGTH,
          issueInputValue
        ),
      150
    ),
    []
  )

  const handleSubmit = () => {
    if (!issueOptions[cursor]) return
    focus?.focusType === Focus.ISSUE && focus.payload
      ? setSpeedDial({
          variables: {
            key: focus.payload.index,
            issue: issueOptions[cursor].key,
          },
        })
      : dispatch(setJiraIssueForSelection(issueOptions[cursor].key))

    dispatch(saveRecentIssue(issueOptions[cursor].key))

    const selectedIssue = formatIssueLabel(
      allIssuesObj,
      issueOptions[cursor].key
    )
    dispatch(modifyLogger(Input.ISSUE, selectedIssue))
    blurElement()
  }

  const handleArrow = (e: React.KeyboardEvent) => {
    e.preventDefault()

    if (
      issueOptions.length === 0 ||
      (e.key === 'ArrowUp' && cursor < 1) ||
      (e.key === 'ArrowDown' && cursor > issueOptions.length - 2)
    )
      return

    dispatch(setIssueOptionsCursor(cursor + INCREMENT_BY_KEY[e.key]))
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const keyBindings = {
      Enter: () => handleSubmit(),
      ArrowUp: () => handleArrow(e),
      ArrowDown: () => handleArrow(e),
    }
    keyBindings.hasOwnProperty(e.key) && keyBindings[e.key]()
  }

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(modifyLogger(Input.ISSUE, e.target.value))
    const filteredIssues = await loadOptions(e.target.value)
    dispatch(setIssueOptions(filteredIssues))
    dispatch(setIssueOptionsCursor(0))
  }

  return (
    <section className={classes.inputWrapper}>
      <div className={classnames(classes.issueInput, 'tutorial-issueInput')}>
        <CustomTextField
          disabled={disabled}
          data-cy="issue-input"
          fullWidth
          variant="outlined"
          type="text"
          label="Search issues"
          value={value}
          placeholder="Issue"
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onChange={handleChange}
          onBlur={(e) => {
            dispatch(setIssueFromCursorPoint())
            handleBlur(e)
          }}
          inputRef={handleRef}
          InputProps={{
            endAdornment: !disabled && (
              <>
                {value && !hasFocus ? (
                  <InputAdornment
                    position="end"
                    onClick={() => dispatch(setJiraIssueForSelection(null))}
                    style={{ cursor: 'pointer' }}
                  >
                    <Close fontSize="small" />
                  </InputAdornment>
                ) : (
                  <InputAdornment
                    position="end"
                    onClick={handleFocus}
                    style={{ cursor: 'pointer' }}
                  >
                    {hasFocus ? (
                      <ExpandLess fontSize="small" />
                    ) : (
                      <ExpandMore fontSize="small" />
                    )}
                  </InputAdornment>
                )}
              </>
            ),
          }}
        />
      </div>
    </section>
  )
}
