import React from 'react'

import { Grid, makeStyles } from '@material-ui/core'
import { useParams } from 'react-router-dom'

import Logo from '../assets/logo.svg'
import Loading from '../components/Loading'
import { useTenantsQuery } from '../generated/graphqlSdk'

import { LoginButton } from './LoginButton'

const useStyles = makeStyles({
  container: {
    paddingTop: '10em',
    alignItems: 'center',
  },
  spacing: {
    // when spacing is used on grid component, negative margins create horizontal scrollbar
    // https://github.com/mui-org/material-ui/issues/7466
    width: '100%',
    margin: 0,
  },
  item: {
    textAlign: 'center',
  },
})

const Login = () => {
  const classes = useStyles()
  const { loading: tenantsLoading, data } = useTenantsQuery()
  const { tenantId } = useParams()
  if (tenantsLoading) return <Loading />

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      classes={{
        container: classes.container,
        'spacing-xs-2': classes.spacing,
      }}
    >
      <Grid item xs={12} classes={{ item: classes.item }}>
        <img src={Logo} alt="Logo" height="32" />
      </Grid>
      {tenantId ? (
        <Grid item xs={12} classes={{ item: classes.item }}>
          <LoginButton tenant={tenantId} text="Login" />
        </Grid>
      ) : (
        data?.tenants.map((t) => (
          <Grid key={t.tenant_id} item xs={12} classes={{ item: classes.item }}>
            <LoginButton tenant={t.tenant_id} text={`${t.name} login`} />
          </Grid>
        ))
      )}
    </Grid>
  )
}

export default Login
