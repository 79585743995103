import { Middleware } from 'redux'

import { addToHistory } from '../actions/historyActions'
import { UNDOABLE_STATE } from '../consts'
import { Action } from '../types/reduxTypes'
import { filterObject } from '../utils'

import { State } from './state'

// States of history are saved in an array. History index points at the current state.
// Getting previous and next state can be done by adding/subtracting 1 respectively.
const historyMiddleware: Middleware<{}, State> = ({ dispatch, getState }) => (
  next
) => (action: Action) => {
  const prevState = getState()
  const result = next(action)
  const nextState = getState()

  if (
    !nextState.tutorial.enabled &&
    action.undoable &&
    !prevState.tutorial.enabled &&
    (typeof action.undoable !== 'function' ||
      action.undoable(prevState, nextState))
  ) {
    if (prevState.history.states.length === 0) {
      dispatch(addToHistory(filterObject(prevState, UNDOABLE_STATE)))
    }
    dispatch(addToHistory(filterObject(nextState, UNDOABLE_STATE)))
  }
  return result
}

export default historyMiddleware
