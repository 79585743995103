import { set, omit } from '@siegrift/tsfunct'
import { sortBy, min, max, clamp, uniq, differenceBy } from 'lodash'
import { batch } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { v4 as uuid } from 'uuid'

import { MessageType } from '../consts'
import { Point, State, PointInput } from '../store/state'
import { matchPowerCommand } from '../tracker/powerCommandUtils'
import { matchWildcards } from '../tracker/wildcardUtils'
import { Action, ThunkExtra, Thunk } from '../types/reduxTypes'
import {
  touchesSyncedInterval,
  roundPointTime,
  touchesSyncedFreeTime,
} from '../utils'

import { setCursorAndIssueInput, updatePointsInHasura } from './trackerActions'
import { displayMessage } from './utilActions'

const assertNoTouchSyncErrors = (points: Point[], indices: number[]) => {
  if (indices.some(touchesSyncedInterval(points))) {
    throw new Error('Unable to perform action affecting synced worklogs')
  }
}

const checkFreeTimeAffected = (points: Point[], indices: number[]) => {
  if (indices.some(touchesSyncedFreeTime(points))) {
    return 'Affected free time worklog was adjusted'
  }
}

type EditPointsResponse = {
  newPoints: Point[]
  cursor: number | undefined
  warnings?: string[]
}

interface ChangedPoint extends Point {
  changed?: boolean
}

const assertNoncontinuousInterval = (indices: number[]) => {
  if (Number(max(indices)) - Number(min(indices)) !== indices.length - 1) {
    throw new Error('Unable to join noncontinuous intervals')
  }
}

// TODO naming https://github.com/vacuumlabs/fine-tracker/pull/603#discussion_r446050419
const _createPoint = (pointInput: PointInput): ChangedPoint => ({
  ...pointInput,
  description: pointInput.description.trim(),
  id: uuid(),
  synced: false,
  freeTime: false,
  marked: false,
  changed: true,
})

const insertPoints = (
  points: Point[],
  addedPoints: ChangedPoint[],
  withWarnings?: string[]
): EditPointsResponse => {
  const newPoints: ChangedPoint[] = sortBy(
    [...points, ...addedPoints],
    ['timestamp']
  )
  const indices = newPoints
    .map((p, i) => (p.changed ? i : -1))
    .filter((p) => p !== -1)
  assertNoTouchSyncErrors(newPoints, indices)
  return {
    newPoints: newPoints.map((point) => omit(point, ['changed'])),
    cursor: indices[indices.length - 1],
    warnings: [
      ...(withWarnings || []),
      checkFreeTimeAffected(newPoints, indices),
    ].filter((w) => w !== undefined) as string[],
  }
}

const handleCreatePointWithPowerFeatures = (
  points: Point[],
  getState: () => State,
  description: string,
  timestamp: string,
  issue?: string
) => {
  const enteredPointInput = {
    description: description,
    timestamp: timestamp,
    issue: issue,
  }
  const { pointInputs, warnings } =
    matchPowerCommand(getState, enteredPointInput) ||
    matchWildcards(getState, enteredPointInput)
  return insertPoints(points, pointInputs.map(_createPoint), warnings)
}

const handleCopySelectedWorklogs = (
  points: Point[],
  _getState: () => State,
  indices: number[]
) => {
  const timestamp = roundPointTime(new Date().toISOString())
  const addedPoints: ChangedPoint[] = indices.map((index) =>
    _createPoint({
      description: points[index].description,
      timestamp,
      issue: points[index].issue,
    })
  )
  return insertPoints(points, addedPoints)
}

const handleTimestampChange = (
  points: Point[],
  getState: () => State,
  index: number,
  changes: Partial<Point>
): EditPointsResponse => {
  const newPoint = {
    ...points[index],
    ...changes,
    issue: changes.description === '' ? null : points[index].issue,
    changed: true,
  }
  // This function includes a composition of functions handleDeletePoints and addPoints.
  // Functions handleDeletePoints and addPoints are thoughtfully tested.
  // If you plan to change this implementation, please extend tests in client/cypress/integration/pointsEdit.spec.ts accordingly.
  const {
    newPoints: delPoints,
    warnings: deleteWarnings,
  } = handleDeletePoints(points, getState, [index])
  const {
    newPoints,
    cursor,
    warnings: insertWarnings,
  } = insertPoints(delPoints, [newPoint])
  return {
    newPoints,
    cursor,
    warnings: uniq([...(deleteWarnings || []), ...(insertWarnings || [])]),
  }
}

const handleEditPoints = (
  points: Point[],
  getState: () => State,
  indices: number[],
  changes: Partial<Point>
): EditPointsResponse => {
  if (changes.timestamp) {
    return handleTimestampChange(points, getState, indices[0], changes)
  }
  const newPoints = points.map((point, i) =>
    indices.includes(i)
      ? {
          ...point,
          ...changes,
          issue: changes.description === '' ? null : point.issue,
        }
      : point
  )
  return {
    newPoints,
    cursor: indices[indices.length - 1],
  }
}

const handleDeletePoints = (
  points: Point[],
  _getState: () => State,
  indices: number[]
): EditPointsResponse => {
  assertNoTouchSyncErrors(points, indices)
  const newPoints = points.filter((_, i) => !indices.includes(i))
  const firstIndex = min(indices) ?? -1
  const warning = checkFreeTimeAffected(points, indices)
  return {
    newPoints,
    cursor: clamp(firstIndex, -1, newPoints.length - 1),
    ...(warning && { warnings: [warning] }),
  }
}

const handleJoinIntervals = (
  points: Point[],
  _getState: () => State,
  indices: number[],
  description: string
): EditPointsResponse => {
  //TODO: indices are not sorted, they probably should (this should delete all but the first point), the cursor position is weird as well
  const filterIndices = indices.slice(1)
  assertNoTouchSyncErrors(points, filterIndices)
  assertNoncontinuousInterval(indices)
  const newPoints = points
    .map((point, i) =>
      i === indices[0]
        ? {
            ...point,
            description,
            issue: description === '' ? null : point.issue,
          }
        : point
    )
    .filter((point, i) => !filterIndices.includes(i))
  const warning = checkFreeTimeAffected(points, filterIndices)
  return {
    newPoints,
    cursor: filterIndices.length > 0 ? filterIndices[0] : undefined,
    ...(warning && { warnings: [warning] }),
  }
}

const updatePoints = (actionType: string, newPoints: Point[]): Action => ({
  type: actionType,
  payload: newPoints,
  undoable: true,
  reducer: (state) => set(state, ['points'], newPoints),
})

type Fn = (...args: any[]) => EditPointsResponse
type TailParameters<T extends Fn> = T extends (
  p: Point[],
  getState: () => State,
  ...args: infer P
) => any
  ? P
  : never
type Ret<T extends Fn> = (
  ...args: TailParameters<T>
) => ThunkAction<any, State, ThunkExtra, AnyAction>

const makeEditPointsAction = <T extends Fn>(editPointsFunction: T): Ret<T> => (
  ...args: any[]
): Thunk<void> => (dispatch, getState, { logger, client }) => {
  batch(() => {
    logger.log('Thunk - Point Action')

    const { points } = getState()
    // TODO: this decorator should not know anything about the args, move args[...] logic elsewhere
    if (
      args[1]?.description === '' &&
      args[0].some((index) => points[index].issue)
    ) {
      dispatch(
        displayMessage(
          ['Issues from edited worklogs were unassigned'],
          MessageType.INFO
        )
      )
    }

    try {
      const { newPoints, cursor, warnings } = editPointsFunction(
        points,
        getState,
        ...args
      )

      dispatch(
        updatePoints(
          `Points related action -> ${editPointsFunction.name}`,
          newPoints
        )
      )
      if (cursor !== undefined) dispatch(setCursorAndIssueInput(cursor))

      if (warnings && warnings.length > 0) {
        dispatch(displayMessage(warnings, MessageType.INFO))
      }

      // user can close the app while tutorial is open. Better not sync tutorial points at all.
      if (!getState().tutorial.enabled) {
        const indices: number[] = []
        newPoints.forEach((p, ind) => {
          if (!p.synced) indices.push(ind)
        })

        // lodash seems to be optimized for this
        // https://github.com/lodash/lodash/blob/e0029485ab4d97adea0cb34292afb6700309cf16/.internal/baseDifference.js#L21
        const pointsToRemove = differenceBy(points, newPoints, 'id').map(
          (p) => p.id
        )
        dispatch(updatePointsInHasura(indices, false, pointsToRemove))
      }
    } catch (e) {
      dispatch(displayMessage([e.message], MessageType.ERROR))
    }
  })
}

export const copyWorklogs = makeEditPointsAction(handleCopySelectedWorklogs)
export const joinIntervals = makeEditPointsAction(handleJoinIntervals)
export const deletePoints = makeEditPointsAction(handleDeletePoints)
export const editPoints = makeEditPointsAction(handleEditPoints)
export const createPoint = makeEditPointsAction(
  handleCreatePointWithPowerFeatures
)
