import fuzzysort from 'fuzzysort'
import { createSelector } from 'reselect'

import { Issue } from '../../store/state'
import {
  recentIssuesSelector,
  allIssuesWithRecentFirstSelector,
} from '../issuesSelectors'

type CustomScoreForKey = {
  customScoreForKey: { recent: number; issueDigits: number }
}
type FuzzysortIssue = Issue & CustomScoreForKey

const RECENT_ISSUE_BONUS_SCORE = 1000
const TITLE_HANDICAP = 10_000

export const fuzzysortPreparedIssuesSelector = createSelector(
  recentIssuesSelector,
  allIssuesWithRecentFirstSelector,
  (recentIssues, allIssuesWithRecentFirsts): FuzzysortIssue[] => {
    const numberOfRecentIssues = recentIssues.length

    return allIssuesWithRecentFirsts.map((issue, index) => {
      // We will use this to sort ASC ->
      // [FINE-1, FINE-2, FINE1-1, FINE1-2, FINE2-1]
      // NOTICE, this uses ALL number in key, not only issue number
      const issueDigits = Number(issue.key.replace(/[^0-9]/g, '')) || -1

      // score is from 0 to -inf, 0 is exact match
      // greater than 0 is exact match with preference

      return {
        // If needed, performance can be improved by providing prepared
        // targets made from allIssues array -> see fuzzysort.prepare()
        // NOTICE: documentation for `prepare` is not very good, If we want
        // to use it, one has to look at the code
        // see https://github.com/farzher/fuzzysort
        ...issue,
        customScoreForKey: {
          recent:
            index < numberOfRecentIssues
              ? // If recent, get score greater than 0
                // We add 1000 so we cover cases when this key is in
                // negative number because of sorting order of lib internals
                RECENT_ISSUE_BONUS_SCORE + numberOfRecentIssues - index
              : 0,
          issueDigits,
        },
      }
    })
  }
)

export const getFuzzysortedIssueOptions = (
  fuzzysortIssues: FuzzysortIssue[],
  limit: number,
  issueInputValue?: string
) =>
  new Promise<FuzzysortIssue[]>((resolve) => {
    if (!issueInputValue) {
      return resolve(fuzzysortIssues.slice(0, limit))
    }

    // fuzzysort.go is invoked only when we change input
    const result = fuzzysort.go(issueInputValue, fuzzysortIssues, {
      keys: ['key', 'title'],
      limit: limit,
      scoreFn: (untypedKeysResult) => {
        // we attach custom object for each key
        const keysResult: typeof untypedKeysResult & {
          obj: CustomScoreForKey
        } = untypedKeysResult as any
        const customKeyScore = keysResult.obj?.customScoreForKey

        // types are wrong here, score can be undefined if the issue is irrelevant
        let fuzzyKeyScore: number = keysResult?.[0]?.score ?? -Infinity
        const fuzzyTitleScore: number = keysResult?.[1]?.score ?? -Infinity
        if (fuzzyKeyScore === -Infinity && fuzzyTitleScore === -Infinity)
          return -Infinity

        if (customKeyScore?.recent > 0) {
          // we want to respect the order of recent issues, so we override fuzzy score completely
          fuzzyKeyScore = customKeyScore.recent
        } else if (customKeyScore.issueDigits > 0) {
          // fuzzysort doesn't guarantee the issues order, subtracting issue digits solves this
          // (e.g. you type 'op1' and you will get issues 'op1', 'op10', 'op11'...).
          fuzzyKeyScore -= customKeyScore.issueDigits
        }

        // decrease the value of title score, because key search has higher prio
        return Math.max(fuzzyKeyScore, fuzzyTitleScore - TITLE_HANDICAP)
      },
    })

    return resolve(result.map((value) => ({ ...value.obj })))
  })
