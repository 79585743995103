import React, { useMemo } from 'react'

import { useTheme } from '@material-ui/core/styles'
import Joyride, { ACTIONS, EVENTS } from 'react-joyride'
import { useStore, useDispatch } from 'react-redux'

import { setCursor } from '../../actions/trackerActions'
import { ComponentWidths } from '../../consts'
import {
  useSetTutorialIndex,
  useSetTutorialEnabled,
  useSetTutorialStartTime,
} from '../../hooks/tutorialHooks'
import { useSelector } from '../../hooks/useSelector'

import { createTutorialSteps } from './tutorialSteps'

export const Tutorial = () => {
  const store = useStore()
  const dispatch = useDispatch()
  const tutorialStartTime = useSelector((state) => state.tutorial.startTime)
  const setTutorialIndex = useSetTutorialIndex()
  const setTutorialEnabled = useSetTutorialEnabled()
  const setTutorialStartTime = useSetTutorialStartTime()

  const tutorialSteps = useMemo(
    () => createTutorialSteps(tutorialStartTime, setTutorialStartTime),
    [setTutorialStartTime, tutorialStartTime]
  )

  const tutorialEnabled = useSelector((state) => state.tutorial.enabled)
  const tutorialIndex = useSelector((state) => state.tutorial.index)

  const theme = useTheme()

  return (
    <Joyride
      callback={(data) => {
        const { action, index, type } = data
        // to prevent https://github.com/typescript-eslint/typescript-eslint/blob/v2.27.0/packages/eslint-plugin/docs/rules/unbound-method.md
        const getState = () => store.getState()

        const stepTutorial = (direction: number) => {
          const nextStep = index + direction
          if (0 <= nextStep && nextStep < tutorialSteps.length) {
            tutorialSteps[nextStep].beforeStep?.(getState, dispatch)
          }
          setTutorialIndex(nextStep)
        }

        // disabling changes type but action remains the same so we need to specify the type too to avoid duplicated calls
        if (
          (action === ACTIONS.CLOSE && type === EVENTS.STEP_AFTER) ||
          type === EVENTS.TOUR_END
        ) {
          tutorialSteps[index].afterStep?.(getState, dispatch)
          setTutorialIndex(0)
          setTutorialEnabled(false)
          dispatch(setCursor(getState().points.length - 1))
          // we handle beforeStep ourselves, because joyride needs the element mounted
          // before calling the 'beforeStep' function. We hope that the first element is mounted and
          // we call 'beforeStep' after the previous element is closed.
        } else if (type === EVENTS.STEP_BEFORE && index === 0) {
          tutorialSteps[index].beforeStep?.(getState, dispatch)
        } else if (type === EVENTS.STEP_AFTER) {
          tutorialSteps[index].afterStep?.(getState, dispatch)
          if (action === ACTIONS.PREV) {
            stepTutorial(-1)
          } else if (action === ACTIONS.NEXT) {
            stepTutorial(1)
          }
        }
      }}
      run={tutorialEnabled}
      steps={tutorialSteps}
      continuous={true}
      stepIndex={tutorialIndex}
      showProgress={true}
      showSkipButton={false}
      hideBackButton={false}
      locale={{
        next: <span data-cy="tutorial-next-button">Next</span>,
        last: <span data-cy="tutorial-last-button">Last</span>,
      }}
      scrollToFirstStep={true}
      spotlightPadding={0}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: theme.palette.primary.main,
        },
        tooltip: {
          width: `${ComponentWidths.TUTORIAL_TOOLTIP}`,
          padding: 0,
        },
        tooltipTitle: {
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '14px',
          textAlign: 'left',
          color: theme.palette.text.primary,
          padding: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        tooltipContent: {
          padding: theme.spacing(2),
          fontSize: '14px',
          lineHeight: '22px',
          textAlign: 'left',
          color: theme.palette.text.primary,
        },
        tooltipFooter: {
          marginTop: 0,
          padding: theme.spacing(1, 2, 2),
        },
        buttonBack: {
          height: 30,
          fontSize: '13px',
          lineHeight: '14px',
          outline: 'none',
          borderRadius: '2px',
          padding: theme.spacing(0, 2),
          marginRight: theme.spacing(1.5),
          border: `1px solid ${theme.palette.primary.main}`,
        },
        buttonNext: {
          height: 30,
          fontSize: '13px',
          lineHeight: '14px',
          outline: 'none',
          borderRadius: '2px',
          padding: theme.spacing(0, 2),
        },
        buttonClose: {
          padding: theme.spacing(2),
          color: theme.palette.text.primary,
          outline: 'none',
          fontSize: '14px',
          lineHeight: '17px',
          width: '11px',
          height: '11px',
        },
      }}
    />
  )
}
