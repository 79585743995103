import { reverse, uniq } from 'lodash'
import { createSelector } from 'reselect'

import { DialogVariant } from '../../consts'
import { State } from '../../store/state'
import { selectionSelector } from '../selectionSelector'

const pointsSelector = (state: State) => state.points
const dialogSelector = (state: State) => state.dialog
const inputSelector = (state: State, initialInput: string) => initialInput

const showHintsFromSelectionSelector = createSelector(
  [dialogSelector],
  (dialog) => Boolean(dialog && dialog.type === DialogVariant.JOIN_INTERVALS)
)

const pointDescriptionsSelector = createSelector([pointsSelector], (points) =>
  points.map((point) => point.description)
)

const selectionDescriptionsSelector = createSelector(
  [pointDescriptionsSelector, selectionSelector],
  (descriptions, selection) =>
    descriptions
      .map((d, i) => selection.includes(i) && d)
      // TS https://github.com/microsoft/TypeScript/pull/29955
      // @ts-ignore
      .filter<string>(Boolean)
)

const distinctDescriptionsSelector = createSelector(
  [
    pointDescriptionsSelector,
    selectionDescriptionsSelector,
    showHintsFromSelectionSelector,
  ],
  (allDescriptions, selectionDescriptions, showHintsFromSelection): string[] =>
    reverse(
      uniq(
        // in order to keep the last occurence (the most recent)
        reverse(
          showHintsFromSelection
            ? selectionDescriptions.slice()
            : allDescriptions.slice()
        )
      )
    )
)

export const loggerInputHistorySelector = createSelector(
  [distinctDescriptionsSelector, inputSelector],
  (descriptions, input) => descriptions.filter((d) => d !== input)
)
