import { withStyles, TextField, makeStyles } from '@material-ui/core'

export const CustomTextField = withStyles((theme) => ({
  root: {
    '& label, & label.Mui-focused': {
      color: theme.palette.text.secondary,
      lineHeight: '7px',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      minWidth: 100,
      '& fieldset': {
        borderColor: theme.palette.border.light,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.border.light,
      },
      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.palette.border.light}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '14px 0 14px 14px',
      height: 44,
      boxSizing: 'border-box',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1.5),
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.border.light,
    },
  },
}))(TextField)

export const useTextFieldStyles = makeStyles((theme) => ({
  inputWrapper: {
    width: '125px',
    marginRight: theme.spacing(3),
  },
  inputKey: {
    marginRight: 0,
  },
}))
