import { set } from '@siegrift/tsfunct'

import { Input } from '../consts'
import { Issue, PointInput } from '../store/state'
import { allIssuesObjSelector } from '../tracker/issuesSelectors'
import { formatIssueLabel } from '../tracker/utils'
import { Action } from '../types/reduxTypes'

const createInput = (value?: string) => ({
  value: value || '',
  isModified: false,
})

export const setLogger = (point: PointInput): Action => ({
  type: 'Set logger',
  payload: point,
  reducer: (state) => {
    const allIssuesObj = allIssuesObjSelector(state)
    const issueInputLabel = formatIssueLabel(allIssuesObj, point.issue)
    const loggerState = {
      inputs: {
        [Input.TIMESTAMP]: createInput(point.timestamp),
        [Input.DESCRIPTION]: createInput(point.description),
        [Input.LOCAL_DESCRIPTION]: createInput(),
        [Input.ISSUE]: createInput(issueInputLabel),
      },
      issueOptions: [],
      issueOptionsCursor: -1,
    }
    return set(state, ['logger'], loggerState)
  },
})

export const modifyLogger = (input: Input, value: string): Action => ({
  type: 'Modify logger',
  doNotLog: true,
  disableAnalytics: true,
  payload: value,
  reducer: (state) =>
    set(state, ['logger', 'inputs', input], {
      value,
      isModified: true,
    }),
})

export const setModifiedFlag = (input: Input, isModified: boolean): Action => ({
  type: 'Set modified flag',
  payload: [input, isModified],
  reducer: (state) =>
    set(state, ['logger', 'inputs', input, 'isModified'], isModified),
})

export const setIssueOptions = (issueOptions: Issue[]): Action => ({
  type: 'Set issue options',
  doNotLog: true,
  payload: issueOptions,
  reducer: (state) => set(state, ['logger', 'issueOptions'], issueOptions),
})

export const setIssueOptionsCursor = (cursor: number): Action => ({
  type: 'Set issue options cursor',
  payload: cursor,
  reducer: (state) => set(state, ['logger', 'issueOptionsCursor'], cursor),
})

export const setIssueFromCursorPoint = (): Action => ({
  type: 'Set issue from cursor point',
  payload: null,
  reducer: (state) => {
    const cursorPointIssue = formatIssueLabel(
      allIssuesObjSelector(state),
      state.points[state.cursor].issue
    )
    return set(
      state,
      ['logger', 'inputs', Input.ISSUE, 'value'],
      cursorPointIssue
    )
  },
})
