import { noop } from 'lodash'

import { PointInput, State } from '../store/state'

const POWER_COMMANDS = [
  {
    name: 'PREV_LOG',
    regexp: /^\/prev$/,
    parseCaptureGroups: noop,
    getPointsToAdd: (
      getState: () => State,
      { timestamp }: PointInput,
      _parsedArguments: void
    ): { pointInputs: PointInput[]; warnings?: string[] } => {
      const { points } = getState()
      if (points.length < 2) throw new Error("Couldn't find previous worklog")
      return { pointInputs: [{ ...points[points.length - 2], timestamp }] }
    },
  },
]

/**
 * Compares entered description against power commands.
 * If a match is found (1 only), a list of PointInputs is returned. These are to be added to the points array.
 * If no match is found, undefined is returned.
 * If a match is found, but doesn't meet validations a list of warnings is returned
 *
 * NOTICE: Returned PointInputs are only suggestions, they are subject to standard further validation (touches synced, etc).
 */
export const matchPowerCommand = (
  getState: () => State,
  enteredPointInput: PointInput
): { pointInputs: PointInput[]; warnings?: string[] } | undefined => {
  const command = POWER_COMMANDS.find((command) =>
    command.regexp.test(enteredPointInput.description)
  )

  const newPoints = command?.getPointsToAdd(
    getState,
    enteredPointInput,
    command.parseCaptureGroups(
      command.regexp.exec(enteredPointInput.description)
    )
  )

  return newPoints
}
