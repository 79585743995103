import * as React from 'react'

import { ApolloProvider } from '@apollo/react-hoc'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { TrackerApolloClient } from './apollo/client'
import { Navigation } from './Navigation'
import { AppStore } from './store/configureStore'
import { theme } from './theme'

type RootProps = {
  store: AppStore
  client: TrackerApolloClient
}

type ErrorBoundaryState = {
  error?: Error
  info?: { componentStack: string }
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  ErrorBoundaryState
> {
  constructor(props) {
    super(props)
    // when hot-reloading code, this gets reimported and the component is recreated.
    // therefore we always start with no error
    this.state = {}
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    if (this.state.error && this.state.info) {
      return (
        <>
          <h1> Houston, we have a problem </h1>
          {process.env.NODE_ENV === 'development' && (
            <>
              <h3>{this.state.error.message}</h3>
              <pre>{this.state.info.componentStack}</pre>
            </>
          )}
        </>
      )
    }
    return this.props.children
  }
}

const Root = ({ store, client }: RootProps) => (
  <ErrorBoundary>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
          <MuiThemeProvider theme={theme}>
            <Navigation />
          </MuiThemeProvider>
        </BrowserRouter>
      </Provider>
    </ApolloProvider>
  </ErrorBoundary>
)

export default Root
