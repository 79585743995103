import React from 'react'

import { makeStyles } from '@material-ui/core'

import { Worklog } from '../../actions/submittableWorkLogsSelector'
import * as styles from '../WorkLogs/styles'

const useStyles = makeStyles((theme) => ({
  worklog: {
    fontSize: '13px',
    lineHeight: '19px',
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1) / 2,
    minHeight: 'auto',
    color: theme.palette.text.secondary,
  },
  description: {
    marginRight: theme.spacing(3),
  },
  duration: {
    ...styles.duration,
  },
}))

interface Props {
  worklog: Worklog
}

const WorkLog = ({ worklog }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.worklog}>
      <div className={classes.description}>{worklog.comment}</div>
      <div className={classes.duration}>{worklog.durationLabel}</div>
    </div>
  )
}

export default WorkLog
