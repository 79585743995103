import React, { Ref } from 'react'

import { TextField, InputAdornment } from '@material-ui/core'

import { useHistoryInput } from '../../hooks/inputHooks'

interface EditProps {
  value: string
  setValue: (val: string) => void
  inputRef: Ref<HTMLElement | undefined>
}
export const DescriptionInput = ({ value, setValue, inputRef }: EditProps) => {
  const { getNextHistoryValue, resetHistory } = useHistoryInput(value)

  return (
    <TextField
      fullWidth
      variant="outlined"
      inputRef={inputRef}
      type="text"
      value={value}
      label="Edit description"
      margin="dense"
      onChange={(e) => {
        setValue(e.target.value)
        resetHistory(e.target.value)
      }}
      onKeyDown={(e) => {
        const historyValue = getNextHistoryValue(e)
        if (historyValue !== null) {
          e.preventDefault()
          setValue(historyValue)
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{<kbd>Enter</kbd>}</InputAdornment>
        ),
      }}
    />
  )
}
