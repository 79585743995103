import { isToday, parseISO } from 'date-fns'
import { mapValues, every } from 'lodash'
import { createSelector } from 'reselect'

import { State } from '../../store/state'
import { eventsByDateSelector } from '../eventsSelectors'

const historySelector = (state: State) => state.history
const displayedDateSelector = (state: State) => state.displayedDate
const pointsSelector = (state: State) => state.points

export const disabledUndoSelector = createSelector(
  [historySelector],
  (history) => history.index < 1
)

export const disabledRedoSelector = createSelector(
  [historySelector],
  (history) => history.index + 1 >= history.states.length
)

export const disabledMoveToNextDaySelector = createSelector(
  [displayedDateSelector],
  (displayedDate) => isToday(parseISO(displayedDate))
)

export const daysAssignedSelector = createSelector(
  [eventsByDateSelector],
  (eventsByDate) =>
    mapValues(eventsByDate, (events) =>
      every(
        events,
        (event) => event.issue || event.title === '' || event.freeTime
      )
    )
)

export const isEveryWorklogAssignedSelector = createSelector(
  [daysAssignedSelector],
  (daysAssigned) => Object.values(daysAssigned).every(Boolean)
)

export const unsyncedPointsSelector = createSelector(
  [pointsSelector],
  (points) =>
    points.map((p, ind) => [p, ind] as const).filter(([p]) => !p.synced)
)
