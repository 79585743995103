import React, { useState, useCallback } from 'react'

import { loggerInputHistorySelector } from '../tracker/Logger/loggerInputHistorySelector'

import { useSelector } from './useSelector'

const INCREMENT_BY_KEY = {
  ArrowUp: -1,
  ArrowDown: 1,
}
const historyKeys = Object.keys(INCREMENT_BY_KEY)
export const useHistoryInput = (value: string) => {
  //used for preservation of original written input during moving in history
  const [originalInput, setOriginalInput] = useState(value)
  const [historyIndex, setHistoryIndex] = useState<number | null>(null)

  const history = useSelector((state) =>
    loggerInputHistorySelector(state, originalInput)
  )

  return {
    getNextHistoryValue: useCallback(
      (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (!historyKeys.includes(e.key)) return null
        e.stopPropagation()
        if (e.key === 'ArrowDown' && historyIndex === null) return null
        const defaultValue =
          historyIndex !== null ? historyIndex : history.length
        const propose = defaultValue + INCREMENT_BY_KEY[e.key]
        const final = propose === history.length ? null : Math.max(propose, 0)

        setHistoryIndex(final)
        const historyValue = final !== null ? history[final] : originalInput
        return historyValue
      },
      [originalInput, historyIndex, history]
    ),
    resetHistory: useCallback((initialValue?: string) => {
      setOriginalInput(initialValue || '')
      setHistoryIndex(null)
    }, []),
  }
}
