import React from 'react'

import MomentUtils from '@date-io/moment'
import { makeStyles } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import 'moment/locale/en-gb'

import { setDisplayedDateAndCursor } from '../../actions/trackerActions'
import { DateTimeFormat } from '../../consts'
import { useSelector } from '../../hooks/useSelector'

import { daysAssignedSelector } from './worklogsHeaderSelectors'

const useStyles = makeStyles((theme) => ({
  savedDay: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: theme.palette.primary.main,
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
  dayContainer: {
    position: 'relative',
  },
  unsavedDay: {
    position: 'absolute',
    height: 0,
    width: 0,
    border: '2px solid',
    borderRadius: 4,
    borderColor: 'red',
    right: '50%',
    transform: 'translateX(1px)',
    top: '80%',
  },
}))

export const HeaderCalendar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const displayedDate = useSelector((state) => state.displayedDate)
  const daysAssigned = useSelector(daysAssignedSelector)

  const renderDayInPicker = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element
  ) => {
    if (!dayInCurrentMonth || !day || moment(day).isSame(displayedDate, 'day'))
      return dayComponent

    const formattedDate = moment(day)
      .startOf('day')
      .format(DateTimeFormat.LONG_DATE)

    if (daysAssigned[formattedDate])
      return (
        <div className={classes.dayContainer}>
          {dayComponent}
          <div className={classes.savedDay} />
        </div>
      )

    if (daysAssigned[formattedDate] === false)
      return (
        <div className={classes.dayContainer}>
          {dayComponent}
          <div className={classes.unsavedDay} />
        </div>
      )

    return dayComponent
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DatePicker
        renderDay={renderDayInPicker}
        autoOk
        disableToolbar
        disableFuture
        allowKeyboardControl={false}
        variant="static"
        value={displayedDate}
        onChange={(date) =>
          date && dispatch(setDisplayedDateAndCursor(date.toISOString()))
        }
      />
    </MuiPickersUtilsProvider>
  )
}
