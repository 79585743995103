import React, { useEffect } from 'react'

import { makeStyles, Box } from '@material-ui/core'
import classnames from 'classnames'
import FocusLock from 'react-focus-lock'
import { useDispatch } from 'react-redux'

import { handleKeyboardPress } from './actions/keyboardActions'
import { deactivateUser } from './actions/utilActions'
import Loading from './components/Loading'
import { Mode, ComponentHeights, ComponentWidths } from './consts'
import {
  useDispatchFetchedData,
  useValidateIssues,
  useInitUserAnalytics,
  useResetTutorial,
  useFetchData,
  useFetchPoints,
} from './hooks/dataProviderHooks'
import { useSelector } from './hooks/useSelector'
import Login from './Login'
import DialogContainer from './tracker/dialogs/DialogContainer'
import { Header } from './tracker/Header'
import { JiraSidebar } from './tracker/JiraSidebar'
import { Logger } from './tracker/Logger'
import { Notice } from './tracker/Message'
import { Shortcuts } from './tracker/Shortcuts'
import { Tutorial } from './tracker/Tutorial'
import { WorkLogs } from './tracker/WorkLogs'
import { WorklogsHeader } from './tracker/WorklogsHeader'
import { WorklogsToSave } from './tracker/WorklogsToSave'

type CreateStylesProps = { mode: Mode; showTimeline: Boolean }

const useStyles = makeStyles((theme) => ({
  body: {
    color: theme.palette.text.primary,
  },
  fullwidth: {
    width: '100%',
  },
  container: {
    maxWidth: `calc(100% - ${ComponentWidths.RIGHT_SIDEBAR})`,
  },
  main: { position: 'relative' },
  wrapper: ({ mode, showTimeline }: CreateStylesProps) => ({
    display: 'flex',
    height: `${
      mode === Mode.SAVING
        ? `calc(100vh - ${ComponentHeights.HEADER} - ${ComponentHeights.WORKLOGS_HEADER})`
        : `calc(100vh - ${ComponentHeights.HEADER} - ${
            ComponentHeights.WORKLOGS_HEADER
          } - ${ComponentHeights.LOGGER}${
            showTimeline ? ` - ${ComponentHeights.TIMELINE}` : ''
          })`
    }`,
  }),
  section: {
    flex: '1',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.default,
  },
  sidebar: {
    width: '100%',
    maxWidth: `${ComponentWidths.RIGHT_SIDEBAR}`,
  },
}))

const App = () => {
  const { showTimeline } = useSelector((state) => state.settings)
  const tutorialInDropdown = useSelector((state) => state.tutorialInDropdown)

  const dispatchFetchedData = useDispatchFetchedData()
  const validateIssues = useValidateIssues()
  const { fetchPoints, loading: loadingPoints } = useFetchPoints()
  const initUserAnalytics = useInitUserAnalytics()
  const dispatch = useDispatch()
  const resetTutorial = useResetTutorial()
  const mode = useSelector((state) => state.mode)
  const classes = useStyles({ showTimeline, mode })

  const { loading: loadingUser, error: errorUser } = useFetchData({
    fetch: {
      path: 'api/user',
      headers: { credentials: 'same-origin' },
    },
    onData: [
      (data) => dispatchFetchedData(data, 'user'),
      (data) => initUserAnalytics(data),
      (data) => localStorage.setItem('token', data.token),
      () => resetTutorial(),
      fetchPoints,
    ],
  })
  const { loading: loadingIssuesCache } = useFetchData({
    fetch: {
      path: 'api/issues?cache=true',
      headers: { credentials: 'same-origin' },
    },
    onData: [(data) => dispatchFetchedData(data, 'issues', 'issues (cached)')],
  })
  const { loading: loadingIssues, error: errorIssues } = useFetchData({
    fetch: {
      path: 'api/issues',
      headers: { credentials: 'same-origin' },
    },
    onData: [
      (data) => dispatchFetchedData(data, 'issues'),
      // TODO: there is no guarantee that this will be triggered when points are loaded in state
      // make sure it is triggered only after points are loaded from BE
      () => validateIssues(),
    ],
    polling: 20 * 60 * 1000,
  })

  useEffect(() => {
    if (errorIssues) dispatch(deactivateUser())
  }, [dispatch, errorIssues])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      dispatch(handleKeyboardPress(e))
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch])

  if (errorUser) return <Login />

  if (loadingUser || loadingPoints) return <Loading />
  if (loadingIssuesCache && loadingIssues) return <Loading />

  return (
    <>
      <FocusLock
        autoFocus={!tutorialInDropdown}
        persistentFocus={false}
        className={classes.body}
      >
        <Tutorial />
        <Header />
        <DialogContainer />
        <Box display="flex">
          <Box
            className={classnames(
              classes.fullwidth,
              mode !== Mode.SAVING && classes.container
            )}
          >
            <WorklogsHeader />
            <main className={classes.main}>
              <div className={classes.wrapper}>
                <Shortcuts />
                <section className={classes.section}>
                  {mode === Mode.SAVING ? <WorklogsToSave /> : <WorkLogs />}
                </section>
              </div>
              {mode !== Mode.SAVING && <Logger />}
            </main>
          </Box>
          <Notice />
          {mode !== Mode.SAVING && (
            <Box className={classes.sidebar}>
              <JiraSidebar />
            </Box>
          )}
        </Box>
      </FocusLock>
    </>
  )
}

export default App
