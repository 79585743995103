import { useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { updateValue } from '../actions/genericActions'
import { Action } from '../types/reduxTypes'

export const useSetTutorialEnabled = (): ((isEnabled: boolean) => Action) => {
  const dispatch = useDispatch()

  return (isEnabled) =>
    dispatch(
      updateValue(['tutorial', 'enabled'], isEnabled, 'Toggle tutorial enabled')
    )
}

export const useSetTutorialIndex = (): ((index: number) => Action) => {
  const dispatch = useDispatch()

  return (index) =>
    dispatch(
      updateValue(['tutorial', 'index'], index, 'Change tutorial step index')
    )
}

export const useSetTutorialStartTime = (): ((date: string) => Action) => {
  const dispatch = useDispatch()
  return useCallback(
    (date) =>
      dispatch(
        updateValue(['tutorial', 'startTime'], date, 'Set tutorial point date')
      ),
    [dispatch]
  )
}
