import React from 'react'

import { makeStyles, Typography } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

import { setJiraIssueFromSpeedDialForSelection } from '../../actions/trackerActions'
import Loading from '../../components/Loading'
import { useSpeedDialQuery } from '../../generated/graphqlSdk'

import * as styles from './styles'

const useStyles = makeStyles((theme) => ({
  listTitle: {
    fontSize: '12px',
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(1) / 4,
  },
  item: {
    ...styles.item,
    padding: theme.spacing(1.5, 3, 1.5, 1.5),
    '& + &': {
      borderTop: `1px solid ${theme.palette.border.main}`,
    },
  },
  itemTitle: {
    ...styles.itemTitle,
    width: '210px',
  },
  itemId: {
    ...styles.itemId,
    color: theme.palette.text.secondary,
    display: 'inline-block',
  },
  container: { ...styles.container, padding: theme.spacing(0, 0, 1.5, 3) },
  key: {
    margin: 0,
  },
}))

export const SpeedDialOptions = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const { loading, error, data } = useSpeedDialQuery()

  if (loading) return <Loading />
  if (error)
    return (
      <div>
        <ErrorOutline color="primary" />
        {error.message}
      </div>
    )

  return (
    <div className={classes.container} data-cy="speed-dial-options">
      <span className={classes.listTitle}>Speed Dial</span>
      <>
        {
          // move 0 to the end of asc sorted keys
          data?.speed_dial
            .sort((a, b) => {
              if (a.key === '0') return 1
              if (b.key === '0') return -1
              return parseInt(a.key, 10) - parseInt(b.key)
            })
            .map((dial) => (
              <div
                key={dial.key}
                className={classes.item}
                onClick={() =>
                  dispatch(setJiraIssueFromSpeedDialForSelection(dial.key))
                }
              >
                <div>
                  <div className={classes.itemTitle}>{dial?.issue?.title}</div>
                  <Typography variant="overline" className={classes.itemId}>
                    {dial?.issue?.issue_id}
                  </Typography>
                </div>
                <kbd className={classes.key}>{dial.key}</kbd>
              </div>
            ))
        }
      </>
    </div>
  )
}
