import React from 'react'

import { makeStyles } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { useDispatch } from 'react-redux'

import {
  setJiraIssueForSelection,
  saveRecentIssue,
} from '../../actions/trackerActions'
import { Focus } from '../../consts'
import {
  SpeedDialDocument,
  useSetSpeedDialMutation,
} from '../../generated/graphqlSdk'
import { useSelector } from '../../hooks/useSelector'
import { issueInputDisabledSelector } from '../issuesSelectors'

import { IssueInput } from './IssueInput'
import IssueOptions from './IssueOptions'
import { SpeedDialOptions } from './SpeedDialOptions'
import * as styles from './styles'

const useStyles = makeStyles((theme) => ({
  rightSideBar: {
    ...styles.rightSideBar,
    borderLeft: `1px solid ${theme.palette.border.light}`,
  },
  container: styles.container,
  savingMessage: { margin: '0px 15px' },
}))

const InactiveAlert = () => (
  <Alert severity="warning">
    <AlertTitle>You are not active in JIRA</AlertTitle>
    You are unable to access new issues and save worklogs
  </Alert>
)

export const JiraSidebar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const focus = useSelector((state) => state.focus)
  const isActive = useSelector((state) => state.user?.isActive)

  //TODO: utilize https://www.apollographql.com/docs/react/performance/optimistic-ui/
  const [setSpeedDial] = useSetSpeedDialMutation({
    refetchQueries: [{ query: SpeedDialDocument }],
  })

  const handleIssueClick = (key: string) => {
    focus?.focusType === Focus.ISSUE && focus.payload
      ? setSpeedDial({ variables: { key: focus.payload.index, issue: key } })
      : dispatch(setJiraIssueForSelection(key))
    dispatch(saveRecentIssue(key))
  }

  const issueInputDisabled = useSelector(issueInputDisabledSelector)

  return (
    <aside className={classes.rightSideBar}>
      {!isActive && <InactiveAlert />}
      {/* TODO: maybe display free time or synced label if disabled */}
      <IssueInput disabled={issueInputDisabled} />
      {!issueInputDisabled &&
        (focus?.focusType === Focus.ISSUE ? (
          <IssueOptions handleIssueClick={handleIssueClick} />
        ) : (
          <SpeedDialOptions />
        ))}
    </aside>
  )
}
