import React, { useState } from 'react'

import MomentUtils from '@date-io/moment'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { addDays, isBefore, isToday, parseISO } from 'date-fns'
import { useDispatch } from 'react-redux'

import { modifyLogger } from '../../actions/loggerActions'
import { setDisplayedDate, setFocus } from '../../actions/trackerActions'
import { displayMessage } from '../../actions/utilActions'
import { Input, Focus, MessageType } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { blurElement } from '../../utils'
import { useFocusListener } from '../useFocusListener'

import { CustomTextField } from './styles'

const ARROWS = {
  ArrowUp: 1,
  ArrowDown: -1,
  j: 1,
  k: -1,
}

type Props = {
  value: string //ISO string

  handleSubmit: (focusLogger?: boolean, timestampOverride?: Date) => void
}

export const LoggerDateInput = ({ value, handleSubmit }: Props) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  const { handleRef, handleBlur, ensureFocus } = useFocusListener(Focus.DATE)
  const dispatch = useDispatch()
  const minDate = useSelector((state) => state.minimalDate)

  const setNewDate = (newDate: Date) => {
    if (isBefore(newDate, minDate)) {
      dispatch(
        displayMessage(
          ['Unable to show worklogs. Check "days to show" in settings.'],
          MessageType.ERROR
        )
      )
      return
    }
    const newDateISO = newDate.toISOString()
    dispatch(modifyLogger(Input.TIMESTAMP, newDateISO))
    dispatch(setDisplayedDate(newDateISO))
  }

  const handleArrow = (key: string) => {
    if (ARROWS[key] > 0 && isToday(parseISO(value))) return
    const newDate = addDays(parseISO(value), ARROWS[key])
    setNewDate(newDate)
  }

  const handleChange = (date: MaterialUiPickersDate) => {
    if (!date) return
    setNewDate(date.toDate())
  }

  const handleTab = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(setFocus({ focusType: Focus.DESCRIPTION }))
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const keyBindings = {
      Enter: () => handleSubmit(e.ctrlKey),
      Tab: () => handleTab(e),
      ArrowUp: () => handleArrow(e.key),
      ArrowDown: () => handleArrow(e.key),
      j: () => handleArrow(e.key),
      k: () => handleArrow(e.key),
    }
    keyBindings.hasOwnProperty(e.key) && keyBindings[e.key]()
  }

  return (
    <div data-cy="logger-date">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          autoOk
          disableToolbar
          disableFuture
          allowKeyboardControl={false}
          variant="inline"
          inputVariant="outlined"
          label="Date"
          value={value}
          onFocus={(e) => {
            ensureFocus()
            setAnchorEl(e.currentTarget)
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          PopoverProps={{
            id: 'popover',
            onExiting: blurElement,
            anchorEl: anchorEl,
          }}
          format="MMM DD, ddd"
          TextFieldComponent={CustomTextField}
          InputProps={{
            onKeyDown: handleKeyDown,
            inputRef: handleRef,
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
}
