import React, { useState, useEffect } from 'react'

import { makeStyles, IconButton, Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'

import { useSelector } from '../../hooks/useSelector'
import * as styles from '../WorkLogs/styles'

import {
  LoggedTimeByIssue,
  expandWorklogsSelector,
} from './saveWorklogsSelectors'
import WorkLog from './WorkLog'

const useStyles = makeStyles((theme) => ({
  item: {
    paddingBottom: theme.spacing(1.5),
    '& + &': {
      paddingTop: theme.spacing(1.5),
      borderTop: `1px solid ${theme.palette.border.main}`,
    },
  },
  issue: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  issueTitle: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '21px',
    marginRight: theme.spacing(3),
  },
  issueTime: {
    ...styles.duration,
  },
}))

interface Props {
  issue: LoggedTimeByIssue
  handleVisibilityChange: (visible: boolean) => void
}

const Issue = ({ issue, handleVisibilityChange }: Props) => {
  const classes = useStyles()
  const allExpanded = useSelector(expandWorklogsSelector)
  const [isWorklogsVisible, setIsWorklogsVisible] = useState(allExpanded)

  const toggle = () => {
    setIsWorklogsVisible(!isWorklogsVisible)
    handleVisibilityChange(!isWorklogsVisible)
  }

  useEffect(() => {
    setIsWorklogsVisible(allExpanded)
  }, [allExpanded])

  return (
    <div className={classes.item}>
      <div className={classes.issue} key={issue.projectIssueKey}>
        <div className={classes.issueTitle}>
          {issue.projectIssueTitle}
          <IconButton onClick={toggle} size="small">
            {isWorklogsVisible ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </IconButton>
        </div>
        <div className={classes.issueTime}>{issue.projectIssueTime}</div>
      </div>

      <Collapse in={isWorklogsVisible}>
        {issue.projectIssueWorklogs.map((worklog) => {
          return <WorkLog key={worklog.id} worklog={worklog} />
        })}
      </Collapse>
    </div>
  )
}

export default Issue
