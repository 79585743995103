import React from 'react'

import NumberFormat from 'react-number-format'
import { useDispatch } from 'react-redux'

import { setSettings } from '../../actions/trackerActions'

import { CustomTextField, useTextFieldStyles } from './styles'

const isAllowed = (inputObj) => {
  const { value, floatValue } = inputObj
  if (value === '' || (Number.isInteger(floatValue) && floatValue >= 0))
    return inputObj
}

export const DaysToShowInput = ({ value }) => {
  const classes = useTextFieldStyles()
  const dispatch = useDispatch()

  return (
    <div className={classes.inputWrapper}>
      <NumberFormat
        variant="outlined"
        label="Number of days"
        customInput={CustomTextField}
        isAllowed={isAllowed}
        value={value}
        onBlur={(e) =>
          dispatch(
            setSettings({
              daysToShow:
                e.target.value === ''
                  ? 35
                  : Math.max(parseInt(e.target.value, 10), 1),
            })
          )
        }
      />
    </div>
  )
}
