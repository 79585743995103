import { reduce } from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'

import { State } from '../store/state'

import { eventsSelector } from './eventsSelectors'

const displayedDateSelector = (state: State) => state.displayedDate
const displayDateEvents = createSelector(
  eventsSelector,
  displayedDateSelector,
  (events, displayedDate) =>
    // TODO: this changes everytime events are changed, however timeline is only interested in
    // start/end time. Return only needed info in timeline selectors.
    // https://vacuum.atlassian.net/browse/FINE-190
    events.filter((event) => moment(event.start).isSame(displayedDate, 'day'))
)

const lastEventSelector = createSelector(
  eventsSelector,
  (events) => events[events.length - 1]
)

export const timelineEventsSelector = createSelector(
  [displayDateEvents, displayedDateSelector, lastEventSelector],
  (events, displayedDate, lastEvent) =>
    events.length
      ? events
      : [
          {
            ...lastEvent,
            start: moment(displayedDate).startOf('day'),
          },
        ]
)

export const secondsAssignedSelector = createSelector(
  [timelineEventsSelector],
  (timelineEvents) =>
    reduce(
      timelineEvents,
      (acc, event) => acc + (event.issue ? event.durationInSeconds : 0),
      0
    )
)

export const isDaySavedSelector = createSelector(
  [timelineEventsSelector],
  (timelineEvents) =>
    timelineEvents.every((event) => event.synced || !event.issue)
)
