export enum Mode {
  LOGGING = 'logging',
  SAVING = 'saving',
  EDIT_DESCRIPTION = 'edit description',
  EDIT_TIME = 'edit time',
}

export enum Focus {
  JOIN_DIALOG = 'joinDialog',
  DESCRIPTION = 'description',
  TIME = 'time',
  DATE = 'date',
  ISSUE = 'issue',
}

export enum MessageType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum DialogVariant {
  JOIN_INTERVALS = 'joinIntervals',
  SUBMIT_WORKLOGS = 'submitWorklogs',
}

export enum DateTimeFormat {
  DATE = 'DD.MM.YYYY',
  LONG_DATE = 'dddd, MMMM DD, YYYY',
  TIME = 'HH:mm',
}
export enum Input {
  DESCRIPTION = 'description',
  LOCAL_DESCRIPTION = 'localDesc',
  TIMESTAMP = 'timestamp',
  ISSUE = 'issue',
}

export const UNDOABLE_STATE = {
  points: true,
}

export enum ComponentHeights {
  HEADER = '64px',
  WORKLOGS_HEADER = '64px',
  TIMELINE = '84px',
  LOGGER = '84px',
  SHORTCUTS_HEADER = '68px',
  LOGGED_SUMMARY_HEADER = '77px',
  LOGGED_SUMMARY_FOOTER = '65px',
}

export enum ComponentWidths {
  RIGHT_SIDEBAR = '320px',
  LEFT_SIDEBAR = '320px',
  MENU_DROPDOWN = '160px',
  TUTORIAL_TOOLTIP = '320px',
}

export enum ComponentSizes {
  AVATAR = '42px',
}

export const HOURS_REG_EXP = /^([0-1][0-9]|2[0-3]):mm$/
export const TIME_REG_EXP = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/
