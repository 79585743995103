import React, { useRef, useCallback } from 'react'

import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { modifyLogger } from '../../actions/loggerActions'
import { editPointForSelection, switchMode } from '../../actions/trackerActions'
import { ContentEditable } from '../../components/ContentEditable'
import { Mode, Input } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { DetailedEvent } from '../eventsSelectors'

import { DescriptionInput } from './EditableDescriptionInput'

interface Props {
  event: DetailedEvent
  classes: Record<
    | 'descriptionWrapper'
    | 'description'
    | 'descriptionTextField'
    | 'descriptionTitle'
    | 'key',
    string
  >
}

export const DescriptionLabel = ({ event, classes }: Props) => {
  return (
    <div className={classes.descriptionWrapper}>
      <div className={classes.description}>
        <span className={classes.descriptionTitle}>{event.title}</span>
        {event.showShortcuts && (
          <kbd
            className={classes.key}
            data-cy="worklog-edit-description-shortcut"
          >
            D
          </kbd>
        )}
      </div>
    </div>
  )
}

export const EditableDescription = ({ event, classes }: Props) => {
  const descriptionRef = useRef<HTMLElement>()
  const dispatch = useDispatch()

  const { value: localDescription } = useSelector(
    (state) => state.logger.inputs[Input.LOCAL_DESCRIPTION]
  )

  const setEditing = useCallback(
    (editing: boolean) => {
      dispatch(switchMode(editing ? Mode.EDIT_DESCRIPTION : Mode.LOGGING))
    },
    [dispatch]
  )
  const changeValue = useCallback(
    (description) => dispatch(editPointForSelection({ description })),
    [dispatch]
  )
  const setEditValue = useCallback(
    (newValue) => dispatch(modifyLogger(Input.LOCAL_DESCRIPTION, newValue)),
    [dispatch]
  )

  const { title, editingDescription, hasCursor } = event

  return (
    <ContentEditable<string>
      labelComponent={<DescriptionLabel event={event} classes={classes} />}
      isEditing={editingDescription}
      setEditing={setEditing}
      onTab={() => dispatch(switchMode(Mode.EDIT_TIME))}
      editRef={descriptionRef}
      editComponent={(value, setValue) => (
        <DescriptionInput
          value={value}
          setValue={setValue}
          inputRef={descriptionRef}
        />
      )}
      value={title}
      changeValue={changeValue}
      className={classnames(
        editingDescription && classes.descriptionTextField,
        classes.descriptionWrapper
      )}
      editValue={localDescription}
      setEditValue={setEditValue}
      isMainEditComponent={hasCursor}
    />
  )
}
