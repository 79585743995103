// NOTE: This is just a copy pasted code from BE with slight modifications.
//
// TODO: Create a commons file for shared code between FE and BE.
// See: https://vacuum.atlassian.net/browse/FINE-349
import {
  setDate,
  endOfDay,
  isBefore,
  startOfMonth,
  isPast,
  subMonths,
  endOfMonth,
  isAfter,
} from 'date-fns'

/**
 * Number of days in the current month to keep the previous timesheet open.
 * (If the current day of the month is bigger, previous period will be frozen).
 */
const CLOSED_PERIOD_DAY = 5

/**
 * Worklog should NOT be submitted to JIRA to already closed period, because it creates mismatch
 * between timesheet approved by PM and finance accounting.
 *
 * There is one exception. You can submit worklogs to previous month if it's only a start of the
 * current month. The idea is that you might have forgot to submit the hours due to weekend or
 * public holidays.
 */
export const isForbiddenToBeSubmitted = (worklogStart: Date) => {
  const now = new Date()

  // if the last datetime for the opened previous period was already in the past
  const previousPeriodClosed = isPast(setDate(endOfDay(now), CLOSED_PERIOD_DAY))
  const prevMonth = subMonths(now, 1)
  const previousPeriodStart = startOfMonth(prevMonth)
  const previousPeriodEnd = endOfMonth(prevMonth)

  if (isAfter(worklogStart, previousPeriodEnd)) {
    // allow submissions to current month and future
    // TODO: should we forbit submitting future? (maybe too distant future?)
    // FT client allows you to submit future time, but you can't go to future day
    return false
  } else if (
    !isBefore(worklogStart, previousPeriodStart) &&
    isBefore(worklogStart, previousPeriodEnd)
  ) {
    // allow submissions to previous period only if it's not frozen
    return previousPeriodClosed
  } else {
    // all other worklogs are in the past and should be forbidden
    return true
  }
}

/** Returns true if the worklog date belongs to past periods. */
export const isPrevPeriodWorklogDate = (worklogStart: Date) =>
  isBefore(worklogStart, startOfMonth(new Date()))
