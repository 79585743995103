import React from 'react'

import {
  makeStyles,
  IconButton,
  Snackbar,
  SnackbarContent,
} from '@material-ui/core'
import { Cancel, CheckCircle, Close, Info, Warning } from '@material-ui/icons'
import { useDispatch } from 'react-redux'

import { hideMessage } from '../../actions/utilActions'
import { useSelector } from '../../hooks/useSelector'
import { theme } from '../../theme'

const useStyles = makeStyles({
  notice: {
    bottom: '120px',
  },
  content: {
    backgroundColor: theme.palette.common.white,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
  },
  error: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(2),
  },
  info: {
    color: theme.palette.info.light,
    marginRight: theme.spacing(2),
  },
  success: {
    color: theme.palette.success.light,
    marginRight: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(2),
  },
})

const iconTypes = {
  error: Cancel,
  info: Info,
  success: CheckCircle,
  warning: Warning,
}

export const Notice = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const message = useSelector((state) => state.message)
  const Icon = iconTypes[message.type]

  const handleClose = () => {
    dispatch(hideMessage())
  }

  return (
    <Snackbar
      className={classes.notice}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={message.isDisplayed}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={classes.content}
        message={message.body.map((msg, i) => {
          return (
            <span className={classes.message} key={i}>
              <Icon className={classes[message.type]} />
              {msg}
            </span>
          )
        })}
        action={
          <IconButton size="small" onClick={handleClose}>
            <Close />
          </IconButton>
        }
      />
    </Snackbar>
  )
}
