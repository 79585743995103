import { createSelector } from 'reselect'

import { Mode, Input } from '../../consts'
import { State } from '../../store/state'

const isTimestampModifiedSelector = (state: State) =>
  state.logger.inputs[Input.TIMESTAMP].isModified
const modeSelector = (state: State) => state.mode

export const displayNowSelector = createSelector(
  [isTimestampModifiedSelector, modeSelector],
  (isTimestampModified, mode) => !isTimestampModified && mode === Mode.LOGGING
)
