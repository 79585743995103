import { uniq, keyBy } from 'lodash'
import { createSelector } from 'reselect'

import { State, Issue } from '../store/state'

export type AllIssuesObj = { [key: string]: Issue }
const allIssuesSelector = (state: State) => state.issues
const pointsSelector = (state: State) => state.points
const cursorSelector = (state: State) => state.cursor

export const allIssuesObjSelector = createSelector(
  [allIssuesSelector],
  (allIssues) => keyBy(allIssues, 'key')
)

const recentIssueKeysSelector = (state: State) => state.recentIssues
export const recentIssuesSelector = createSelector(
  recentIssueKeysSelector,
  allIssuesObjSelector,
  (keys, allIssuesObj) => keys.map((key) => allIssuesObj[key]).filter(Boolean)
)

export const allIssuesWithRecentFirstSelector = createSelector(
  [recentIssuesSelector, allIssuesSelector],
  (recentIssues, allIssues) => uniq(recentIssues.concat(allIssues))
)

export const issueInputDisabledSelector = createSelector(
  pointsSelector,
  cursorSelector,
  (points, cursor) =>
    !points[cursor] ||
    points[cursor].synced ||
    points[cursor].description === ''
)
