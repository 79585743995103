import React from 'react'

import { Tooltip } from '@material-ui/core'
import { format, setHours, setMinutes, parseISO } from 'date-fns'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import { useDispatch } from 'react-redux'

import { modifyLogger, setModifiedFlag } from '../../actions/loggerActions'
import { setDisplayedDate } from '../../actions/trackerActions'
import {
  Focus,
  Input,
  DateTimeFormat,
  TIME_REG_EXP,
  HOURS_REG_EXP,
} from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { useFocusListener } from '../useFocusListener'
import { adjustTime } from '../utils'

import { displayNowSelector } from './displayNowSelector'
import { CustomTextField, useTextFieldStyles } from './styles'

const MAX_TIME = {
  hours: 23,
  minutes: 59,
}

const limit = (input: string, max: string) => {
  if (input.length === 2 && input > max) return max
  if (input.length === 1 && input[0] > max[0]) return `0${input}`
  return input
}

const timeFormat = (input: string) => {
  const hours = limit(input.substring(0, 2), String(MAX_TIME.hours))
  const minutes = limit(input.substring(2, 4), String(MAX_TIME.minutes))
  return `${hours}${'H'.repeat(2 - hours.length)}:${minutes}${'m'.repeat(
    2 - minutes.length
  )}`
}

type Props = {
  value: string //ISO string
  handleSubmit: (focusLogger?: boolean, timestampOverride?: Date) => void
}

export const LoggerTimeInput = ({ value, handleSubmit }: Props) => {
  const {
    handleRef,
    handleBlur: focusListenerBlur,
    ensureFocus,
    hasFocus,
  } = useFocusListener(Focus.TIME)
  const dispatch = useDispatch()
  const displayNow = useSelector(displayNowSelector)

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (HOURS_REG_EXP.test(e.target.value)) {
      const [hours] = e.target.value.split(':')
      const formattedDate = setMinutes(
        setHours(parseISO(value), parseInt(hours)),
        0
      ).toISOString()
      dispatch(modifyLogger(Input.TIMESTAMP, formattedDate))
      dispatch(setDisplayedDate(formattedDate))
    } else if (!TIME_REG_EXP.test(e.target.value)) {
      const newDate = new Date().toISOString()
      dispatch(modifyLogger(Input.TIMESTAMP, newDate))
      dispatch(setDisplayedDate(newDate))
      dispatch(setModifiedFlag(Input.TIMESTAMP, false))
    }
  }

  const handleKeySubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return
    const input = (e.target as HTMLInputElement).value
    if (!TIME_REG_EXP.test(input) && !HOURS_REG_EXP.test(input)) return

    //this handles submitting incomplete values (ending with :mm)
    const momentaryValue = HOURS_REG_EXP.test(input)
      ? setMinutes(setHours(parseISO(value), parseInt(input.split(':')[0])), 0)
      : undefined

    handleSubmit(e.ctrlKey, momentaryValue)
  }

  const handleChange = (input: NumberFormatValues) => {
    if (input.value.length < 4) return
    const [hours, minutes] = input.formattedValue
      .split(':')
      .map((value: string) => parseInt(value, 10))

    const inputValue = setMinutes(
      setHours(parseISO(value), hours),
      minutes
    ).toISOString()
    if (inputValue !== value) {
      dispatch(modifyLogger(Input.TIMESTAMP, inputValue))
    }
  }

  const classes = useTextFieldStyles()

  return (
    <div className={classes.inputWrapper} data-cy="logger-time">
      <Tooltip open={hasFocus} title="We're rounding to nearest 15 min!">
        <NumberFormat
          variant="outlined"
          label="Time"
          placeholder={DateTimeFormat.TIME}
          customInput={CustomTextField}
          format={displayNow && !hasFocus ? 'NOW' : timeFormat}
          value={
            displayNow && !hasFocus ? 'NOW' : format(parseISO(value), 'HHmm')
          }
          isNumericString={displayNow && !hasFocus}
          onFocus={(e) => {
            ensureFocus()
            setTimeout(() => e.target.select(), 0) //to update NOW value before focusing
          }}
          onBlur={(e) => {
            handleBlur(e)
            focusListenerBlur(e)
          }}
          onKeyDown={(e) => {
            handleKeySubmit(e)
            const newTime = adjustTime(e, value)
            if (newTime) {
              e.preventDefault()
              dispatch(modifyLogger(Input.TIMESTAMP, newTime))
              dispatch(setDisplayedDate(newTime))
            }
          }}
          onValueChange={handleChange}
          inputRef={handleRef}
        />
      </Tooltip>
    </div>
  )
}
