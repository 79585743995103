import React from 'react'

import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Spinner from 'react-spinkit'
//TODO: add types for react-spinkit

const useStyles = makeStyles((theme) => ({
  loader: {
    height: '22px',
    '& > div': {
      height: '22px',
    },
    color: `${theme.palette.primary.main}`,
  },
}))

const Loading = () => {
  const classes = useStyles()

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Spinner
          fadeIn="quarter"
          name="line-scale-pulse-out"
          className={classes.loader}
        />
      </Grid>
    </Grid>
  )
}
export default Loading
