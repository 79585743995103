import React from 'react'

import Dialog from '@material-ui/core/Dialog'
import { useDispatch } from 'react-redux'

import { setLogger } from '../../actions/loggerActions'
import {
  joinSelectedIntervals,
  closeDialog,
} from '../../actions/trackerActions'
import { Focus, Input } from '../../consts'
import { useSelector } from '../../hooks/useSelector'
import { firstSelectedPointSelector } from '../firstSelectedPointSelector'
import { LoggerInput } from '../Logger/LoggerInput'

export const JoinIntervalsDialog = () => {
  const dispatch = useDispatch()
  const firstPoint = useSelector(firstSelectedPointSelector)
  const descriptionInputValue = useSelector(
    (state) => state.logger.inputs[Input.DESCRIPTION].value
  )

  const onClose = () => {
    dispatch(closeDialog())
    dispatch(
      setLogger({
        description: '',
        timestamp: new Date().toISOString(),
      })
    )
  }
  const handleSubmit = () => {
    dispatch(joinSelectedIntervals(descriptionInputValue))
    onClose()
  }

  return (
    <Dialog
      open
      onFocus={() => firstPoint && dispatch(setLogger(firstPoint))}
      onClose={onClose}
      fullWidth
    >
      <LoggerInput
        value={descriptionInputValue}
        handleSubmit={handleSubmit}
        focusedAs={Focus.JOIN_DIALOG}
      />
    </Dialog>
  )
}
