import React, { Ref } from 'react'

import { TextField, InputAdornment, Tooltip } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import { setMinutes, setHours, parseISO } from 'date-fns'
import moment from 'moment'
import NumberFormat from 'react-number-format'

import { DateTimeFormat } from '../../consts'
import { adjustTime } from '../utils'

interface EditProps {
  value: string
  setValue: (val: string) => void
  timestamp: string
  inputRef: Ref<HTMLElement | undefined>
}

// TODO: move next 3 vars to utils (used also in LoggerTimeInput), or create custom presentional component
const MAX_TIME = {
  hours: 23,
  minutes: 59,
}

const limit = (input: string, max: string) => {
  if (input.length === 2 && input > max) return max
  if (input.length === 1 && input[0] > max[0]) return `0${input}`
  return input
}

const timeFormat = (input: string) => {
  const hours = limit(input.substring(0, 2), String(MAX_TIME.hours))
  const minutes = limit(input.substring(2, 4), String(MAX_TIME.minutes))
  return `${hours}${'H'.repeat(2 - hours.length)}:${minutes}${'m'.repeat(
    2 - minutes.length
  )}`
}

export const EditableTimeInput = ({
  value,
  setValue,
  inputRef,
  timestamp,
}: EditProps) => {
  return (
    <Tooltip title={"We're rounding to nearest 15 min!"}>
      <NumberFormat
        variant="outlined"
        label="Time"
        placeholder={DateTimeFormat.TIME}
        customInput={TextField}
        margin="dense"
        format={timeFormat}
        value={moment(value).format(DateTimeFormat.TIME)}
        isNumericString={false}
        onValueChange={({ value, formattedValue }) => {
          if (value.length < 4) return
          const [hours, minutes] = formattedValue
            .split(':')
            .map((value) => parseInt(value, 10))
          const inputValue = setMinutes(
            setHours(parseISO(timestamp), hours),
            minutes
          )
          setValue(inputValue.toISOString())
        }}
        onFocus={(e) => e.target.select()}
        onKeyDown={(e) => {
          const newValue = adjustTime(e, value)
          if (newValue) {
            e.preventDefault()
            setValue(newValue)
          }
        }}
        style={{ width: 190, marginRight: 16 }}
        inputRef={inputRef}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccessTime />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{<kbd>Enter</kbd>}</InputAdornment>
          ),
        }}
      />
    </Tooltip>
  )
}
