import ReactGA from 'react-ga'
import { Middleware } from 'redux'

import { Action } from '../types/reduxTypes'

import { State } from './state'

const logActionToGA: Middleware<{}, State> = ({ getState }) => (next) => (
  action: Action
) => {
  const { user } = getState()

  if (!action.disableAnalytics) {
    ReactGA.event({
      category: 'Redux',
      action: action.type,
      label: user?.name || 'No user name',
    })
  }

  return next(action)
}

export default logActionToGA
