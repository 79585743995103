import { get } from '@siegrift/tsfunct'
import ReactGA from 'react-ga'

import { getMetaKeyPath, keyBindings } from '../tracker/keyBindingsUtils'
import { Thunk } from '../types/reduxTypes'

export const handleKeyboardPress = (e: KeyboardEvent): Thunk<void> => (
  dispatch,
  getState,
  { logger }
) => {
  if (getState().tutorial.enabled) return

  const target = e.target as HTMLElement
  if (target.parentElement?.id === 'popover') return

  const metaKeyPath = getMetaKeyPath(e.ctrlKey, e.metaKey, e.shiftKey, e.altKey)
  const keyBinding = get(keyBindings, [
    getState().mode,
    metaKeyPath,
    e.key.toLowerCase(),
  ])

  if (!keyBinding) return

  if (
    keyBinding.handler &&
    (target?.tagName !== 'INPUT' || keyBinding.executableFromInput)
  ) {
    // only log if the key press is an executable shortcut
    logger.log(
      `Shortcut press: ${e.key.toUpperCase()} ${metaKeyPath}`,
      keyBinding.description
    )

    ReactGA.event({
      category: 'Shortcut',
      action: `${e.key.toUpperCase()} ${metaKeyPath}: ${
        keyBinding.description
      }`,
      label: getState().user?.name || 'No user name',
    })

    if (!keyBinding.isDisabled?.(getState())) {
      keyBinding.handler(dispatch, getState)
    }
    e.preventDefault()
  }
}
